<template>
  <div class="c-profile-billing">
    <WorkspaceBilling></WorkspaceBilling>
  </div>
</template>

<script>
import WorkspaceBilling from "@swipekit/components/workspace/billing.vue";

export default {
  components: {
    WorkspaceBilling,
  },
  data: function () {
    return {};
  },

  computed: {},

  methods: {},

  mounted: async function () {},
};
</script>

<style lang="scss"></style>
