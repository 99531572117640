<template>
  <form class="c-form-share" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Share"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  name: {
    component: "InputMultiple",
    label: "Your name",
    validations: {},
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: schema,
      processing: false,
      form: {
        name: "",
      },
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    getUser: function () {
      return this.$store.getters["users/user"];
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      let response = null;
      try {
        response = await this.$store.dispatch("users/update", form);
      } catch (err) {}
      this.processing = false;
      this.$emit("onSubmit");
    },
  },

  created: function () {
    this.form = this.getUser();
  },
};
</script>

<style lang="scss">
.c-form-settings {
  margin-bottom: var(--spacer);
}
</style>
