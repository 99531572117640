<template>
  <div class="c-styleguide">
    <Header></Header>
    <PageHeader byline="Multiplayer">
      <template v-slot:title>
        Browse over 70,000 Ads
      </template>
    </PageHeader>
    <PageHeader byline="Board">
      <template v-slot:title>
        Sold out Angle
      </template>
    </PageHeader>
    <PageHeader byline="Library">
      <template v-slot:title>
        All your saved ads in one place
      </template>
    </PageHeader>
    <PageHeader  byline="Profile & Settings">
      <template v-slot:title>
        Personal profile settings
      </template>
    </PageHeader>
    <div class="c-styleguide__inner">
      <div class="container-fluid">
        <h1>H1 text</h1>
        <h2>H2 text</h2>
        <h3>H3 text</h3>
        <h3 class="h3-fancy">H3 fancy text</h3>
        <h4>H4 text</h4>
        <h1 class="h1-fancy">fancy h1 text</h1>
        <p class="article-text">Article text. Replace this with some long fancy marketing spiel</p>
        <p>Normal text. For normies only</p>
        <input class="form-control" type="text" />
        <button class="btn">A button</button>
        <button class="btn btn-primary">A sexy button</button>
        <button class="btn btn-primary btn-outline">Outline button</button>
        <button class="btn btn-primary btn-outline btn-box">Outline button with box</button>
        <InputButton :touched="false" :processing="true" :label="`A spinner button`"></InputButton>
        <button class="btn btn-secondary"><span class="c-spinner"></span>A spinner secondary button</button>

        <img
          src="https://images.unsplash.com/photo-1504548840739-580b10ae7715?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
        />

        <div class="constrain">
          <h2>Save time gathering tweets</h2>
          <p class="article-text">We have the best Nuzzle alternative in town.</p>
        </div>

        <div class="constrain">
          <h1>How to save Tiktok ads</h1>
          <p class="article-text">We have the best Nuzzle alternative in town.</p>
        </div>

        <div class="constrain">
          <h1>
            Save your Facebook ads collection
            <h1 class="h1-fancy">Permanently</h1>
          </h1>
        </div>

        <div class="constrain">
          <p class="article-text">Lorem ipsum dolor and other spicy words</p>
        </div>

        <div class="constrain">
          <h6>A nice heading here</h6>
        </div>

        <p>
          Some text
        </p>
        <p class="article-text">
          <ul>
            <li>
              li 1
            </li>
            <li>
              li 2
            </li>
          </ul>
        </p>

        <Item :ad="exampleAd" :shared="true"></Item>
      </div>
    </div>
  </div>
</template>

<script>
import InputButton from "@swipekit/components/form/input-button.vue";
import Item from "@swipekit/components/item";
import Header from "@swipekit/components/app/header";
import PageHeader from "@swipekit/components/utils/page-header";

export default {
  name: "Home", 

  components: {
    InputButton,
    Item,
    Header,
    PageHeader
  },

  data : function() {
    return {
      exampleAd : {
			"id": "0007a0d5-4ad8-459a-ac95-288c73681250",
			"workspaceId": 1803,
			"blockId": "0007a0d5-4ad8-459a-ac95-288c73681250",
			"tags": "[\"Real Estate\"]",
			"userId": 1659,
			"createdAt": "2023-04-26 20:21:29.333",
			"updatedAt": "2023-04-26 20:21:29.333",
      block : {

		
			"id": "0007a0d5-4ad8-459a-ac95-288c73681250",
			"metaId": "1405278310236590",
			"type": "facebook",
			"meta": "{\"cta\": \"WHATSAPP_MESSAGE\", \"pageId\": \"102537405471573\", \"ctaLink\": \"https://api.whatsapp.com/send\", \"advertiser\": {\"name\": \"Waqar Jillani\", \"avatar\": {\"name\": \"b1d0174610-advertiseravatar.jpeg.jpeg\", \"width\": 60, \"height\": 60}}}",
			"description": null,
			"slug": "fb-1405278310236590-jofOzLr4qKYb4uzUj2xo1",
			"createdAt": "2023-04-26 20:21:29.210",
			"updatedAt": "2023-05-09 18:00:04.251",
			"tags": [`Real Estate`],
			"public": 0,
			"assets": "[{\"type\": \"video\", \"cover\": {\"type\": \"image\", \"assetPath\": \"b1d0174610-cover-0.jpeg\"}, \"assetPath\": \"b1d0174610-video.mp4\"}]",
			"source": "\"https://facebook.com/waqarjillanirealestate\"",
			"author": null,
			"searchable": "Waqar Jillani ",
			"title": "Waqar Jillani",
			"assetRatio": 0.562700964630225,
			"adCopy": `Are you looking to book a luxurious apartment in Twin Cities? Look no further than RAJA WAQAR JILLANI! We offer a wide range of 1, 2, & 3-Bedroom apartments🏘️ in prime locations in Bahria Town. Our apartments are equipped with all the modern amenities you need to live in comfort and style. 🥳Whether you're a small family or a young professional, we have the perfect apartment for you.
Contact us today to book your dream apartment and experience the best in luxury living!
Raja Waqar Jillani 📲03335955515
Mail Us:📧 waqarjillani33@gmail.com
Prime Location:📍Bahria Paradise Commercial, Bahria Town
Raja Waqar Jillani
.
.
.
.
.
#RosettaPrime #LuxuryApartment #Apartment #LuxuryLiving #PrimeLocation #RajaWaqarJillani #BahriaTown #DHA #Rawalpindi #Islamabad`,
			"assetType": "video",
			"endDate": "2023-04-23 07:00:00.000",
			"startDate": "2023-04-23 07:00:00.000",
			"adTitle": "Book Your Luxurious Apartment",
			"categories": "[\"Real Estate\"]",
			"networks": "[\"facebook\", \"instagram\"]",
			"comments": null,
			"likes": null,
			"shares": null,
			"status": "ACTIVE",
			"screenshotId": "dab81133-d901-4181-b79f-c255c439fefd",
			"statusCheckAt": "2023-05-09 18:00:04.249",
			"vision": null
      }
		  }
    }
  },

  
};
</script>

<style lang="scss">
.c-styleguide {
  .constrain {
    margin: var(--spacer) 0;
    padding: var(--spacer);
    max-width: 500px;
  }

  .container-fluid > img {
    max-width: 30%;
    width: 100%;
    height: auto;
    border-radius: 20px;
  }

  &__inner {

  }
}
</style>
