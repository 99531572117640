<template>
  <div class="c-landing-pages-item-header">
    <Avatar :block="block" size="small">
      <ActiveStatus v-if="block.type === 'facebook'" :block="block"></ActiveStatus>
    </Avatar>

    <article>
      <p>{{ title }}</p>

      <span v-if="computedTags"
        ><i v-for="tag in computedTags" :key="tag.text">{{ tag.text }}</i></span
      >
    </article>

    <div :class="['c-landing-pages-item-header__buttons', { shared: shared === true }]">
      <a class="open-share-ad" href="#" v-if="!multiplayer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-link"
        >
          <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
          <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
        </svg>
      </a>

      <a href="#" v-if="!shared && !multiplayer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-maximize-2"
        >
          <polyline points="15 3 21 3 21 9"></polyline>
          <polyline points="9 21 3 21 3 15"></polyline>
          <line x1="21" x2="14" y1="3" y2="10"></line>
          <line x1="3" x2="10" y1="21" y2="14"></line>
        </svg>
      </a>
    </div>

    <!-- <svg
      @click="onBoardClick"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5303 11.5303C17.8232 11.2374 17.8232 10.7626 17.5303 10.4697C17.2374 10.1768 16.7626 10.1768 16.4697 10.4697L12 14.9393L7.53033 10.4697C7.23744 10.1768 6.76256 10.1768 6.46967 10.4697C6.17678 10.7626 6.17678 11.2374 6.46967 11.5303L11.4697 16.5303C11.7626 16.8232 12.2374 16.8232 12.5303 16.5303L17.5303 11.5303Z"
        fill="white"
      />
    </svg> -->
  </div>
</template>

<script>
import Avatar from "@swipekit/components/utils/avatar";
import ActiveStatus from "@swipekit/components/utils/active-status";

export default {
  components: {
    Avatar,
    ActiveStatus,
  },

  props: {
    block: {},
    shared: {
      type: Boolean,
      default: false,
    },
    multiplayer: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title: function () {
      let block = this.block;
      if (block.title) {
        return block.title;
      }

      if (block.brand) {
        return block.brand.brand_name;
      }

      if (block.meta && block.meta.advertiser) {
        return block.meta.advertiser.name;
      }

      if (block.type === "webpageSelection") {
        return "Webpage clip";
      }

      return block.type;
    },
    computedTags: function () {
      let tags = this.block.tags || this.block.categories || [];

      let newTags = [];

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].text) {
          newTags.push({
            text: tags[i].text,
          });
        } else {
          newTags.push({
            text: tags[i],
          });
        }
      }

      return newTags;
    },
    text: function () {
      let type = this.block.type;

      if (type === "email") {
        return "Email";
      }
      if (type === "webpage") {
        return "Webpage";
      }
      if (type === "webpageSelection") {
        return "Webpage section";
      }
      if (type === "facebook") {
        return "Facebook Ad library";
      }

      return "";
    },
  },

  methods: {
    onBoardClick: function (e) {
      this.$emit("boardClick", e.target);
    },
  },
};
</script>

<style lang="scss">
.c-landing-pages-item-header {
  //position: absolute;
  z-index: var(--z-2);
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: var(--margin-lg);
  padding-left: 0;
  padding-top: 0;

  display: flex;
  align-items: center;

  user-select: none;

  margin-bottom: var(--margin);

  > article {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    margin-left: var(--margin-lg);
    margin-right: var(--margin);

    p {
      margin: 0;
      font-size: var(--font-size);
    }

    span {
      opacity: 0.85;
    }
    p {
      opacity: 0.8;
      color: var(--color-font);
      font-size: var(--font-size-sm);
      line-height: 1;
    }

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      i {
        display: inline-block;
        margin-right: var(--margin-sm);
        line-height: 1;
        font-style: normal;
        opacity: 0.8;
        color: var(--color-font);
        font-size: var(--font-size-xs);

        &:not(:last-child) {
          &:after {
            content: ",";
          }
        }
      }
    }
  }

  &__buttons {
    position: relative;
    margin-left: auto;
    min-width: 58px;

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--border-radius-full);
      color: var(--color-font);

      > svg {
        display: inline-block;
      }

      &:hover,
      &:active {
        color: var(--color-primary);
      }

      &:focus {
        outline: none;
        border-color: hsl(var(--p), 100%, 55%);
        box-shadow: 0 0 0 3px hsla(var(--p), 100%, 55%, 0.45);
      }

      &:last-child {
        margin-left: var(--margin-lg);
      }
    }

    &.shared {
      display: none;
    }
  }
}
</style>
