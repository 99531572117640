<template>
  <div class="c-trial-expiry">
    <h4>Your trial has expired.</h4>

    <article>
      <p>
        Please upgrade to the Pro version from the <router-link to="/profile/billing">Billing portal</router-link> to
        continue saving Ads.
      </p>
      <p>Here's what you get with the PRO version:</p>
      <ul class="c-list-fancy">
        <li>Save unlimited ads from the Meta Ad Library</li>
        <li>Save unlimited ads from Tiktok Creative Center</li>
        <li>Save unlimited ads from Linkedin</li>
        <li>Get full access to Swipekit Multiplayer</li>
      </ul>

      <p>
        Lastly, if you run into any issues, we have a <router-link to="/help">help section</router-link> for potential
        fixes.
      </p>
    </article>
  </div>
</template>

<script>
export default {
  computed: {
    is3DaysAfterTrialStarted: function () {
      return this.$store.getters["users/is3DaysAfterTrialStarted"];
    },
  },

  methods: {
    openRewardModal: function () {
      this.$store.dispatch("app/setRewardTrialExtension", true);
    },
  },
};
</script>

<style lang="scss">
.c-trial-expiry {
  padding: var(--spacer-sm);
  border: var(--color-primary) solid 1px;
  border-radius: 5px;
  margin-bottom: var(--spacer);

  hr {
    padding: var(--margin-lg) 0;
  }
}
</style>
