<template>
  <div class="c-history">
    <strong>Ad History</strong>
    <ul>
      <li class="ad-start" v-if="adStartDate">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-plus"
          >
            <line x1="12" x2="12" y1="5" y2="19"></line>
            <line x1="5" x2="19" y1="12" y2="12"></line>
          </svg>
        </span>
        <p>{{ adText }} started on {{ adStartDate }}</p>
      </li>
      <li v-if="adStartDate" class="divider">
        <p>{{ adStartSaveDiff }} days later</p>
      </li>
      <li class="ad-save">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-save"
          >
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
            <polyline points="17 21 17 13 7 13 7 21"></polyline>
            <polyline points="7 3 7 8 15 8"></polyline>
          </svg>
        </span>
        <p>{{ adText }} saved on {{ adSavedDate }}</p>
      </li>
      <li class="divider">
        <p>{{ adSaveStatusDiff }} days later</p>
      </li>
      <li v-if="status === 'PENDING'" class="ad-status-pending">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" fill="currentColor">
            <path
              d="M431 726q1-72 16.5-105t58.5-72q42-38 64.5-70.5T593 409q0-45-30-75t-84-30q-52 0-80 29.5T358 395l-84-37q22-59 74.5-100.5T479 216q100 0 154 55.5T687 405q0 48-20.5 87T601 574q-49 47-59 72t-11 80H431Zm48 250q-29 0-49.5-20.5T409 906q0-29 20.5-49.5T479 836q29 0 49.5 20.5T549 906q0 29-20.5 49.5T479 976Z"
            />
          </svg>
        </span>
        <p>As of today, this {{ adText }}s's status is unknown.</p>
      </li>

      <li v-if="status === 'ACTIVE'" class="ad-status-active">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-check"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
        <p>This {{ adText }} is currently active</p>
      </li>
      <li v-if="status === 'INACTIVE'" class="ad-status-inactive">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-x"
          >
            <line x1="18" x2="6" y1="6" y2="18"></line>
            <line x1="6" x2="18" y1="6" y2="18"></line>
          </svg>
        </span>
        <p>This {{ adText }} is currently inactive</p>
      </li>
    </ul>
    <p class="c-history__outcome" v-if="totalActiveDiff">This Ad has been active for {{ totalActiveDiff }} days.</p>
  </div>
</template>

<script>
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";

export default {
  props: {
    block: {},
  },

  computed: {
    adText: function () {
      if (this.block.type === "tiktok") {
        return "video";
      }

      return "Ad";
    },
    status: function () {
      if (this.block.type !== "facebook") {
        return "PENDING";
      }
      return this.block.status;
    },
    adStartDate: function () {
      let date = this.block.startDate;

      if (!date) {
        return;
      }

      return this.renderDate(date);
    },
    adSavedDate: function () {
      let date = this.block.createdAt;

      return this.renderDate(date);
    },
    adStartSaveDiff: function () {
      if (!this.block.startDate) {
        return;
      }

      let startDate = parseISO(this.block.startDate);

      let saveDate = parseISO(this.block.createdAt);

      let diff = differenceInDays(saveDate, startDate);

      return diff;
    },
    adSaveStatusDiff: function () {
      let statusCheckAt = this.block.statusCheckAt;
      if (!statusCheckAt) {
        statusCheckAt = new Date();
      } else {
        statusCheckAt = parseISO(statusCheckAt);
      }

      let saveDate = parseISO(this.block.createdAt);

      let diff = differenceInDays(statusCheckAt, saveDate);

      return diff;
    },
    totalActiveDiff: function () {
      if (this.block.type !== "facebook") {
        return;
      }
      if (this.block.status !== "ACTIVE") {
        return;
      }
      if (!this.block.statusCheckAt) {
        return;
      }
      if (!this.block.startDate) {
        return;
      }

      let startDate = parseISO(this.block.startDate);

      let statusCheckAt = parseISO(this.block.statusCheckAt);

      let diff = differenceInDays(statusCheckAt, startDate);

      return diff;
    },
  },

  methods: {
    renderDate: function (date) {
      let token = "do MMMM, yyy";

      date = format(parseISO(date), token);

      return date;
    },
  },
};
</script>

<style lang="scss">
.c-history {
  > strong {
    display: inline-block;
    opacity: 0.8;
    font-size: var(--font-size-sm);
    padding-right: var(--margin);
    margin-bottom: var(--margin-lg);
  }

  &__outcome {
    margin-top: var(--margin-lg);
    font-size: var(--font-size-sm);
    padding: var(--margin-lg);
    display: block;
    background-color: hsl(var(--p), 60%, 95%);
    color: var(--color-primary);
    border-radius: 5px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: var(--margin);

    li {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 32px 1fr;
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        padding: var(--margin);
        border-radius: 99px;
        background-color: var(--color-bg-5);
      }

      p {
        display: block;
        padding-left: var(--margin);
        line-height: 1;
        margin: 0;
        font-size: var(--font-size-sm);

        opacity: 0.9;
      }

      &.divider {
        position: relative;
        padding: var(--margin-lg) var(--spacer);
        margin: var(--margin-lg) 0;
        display: flex;
        align-items: center;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 14px;
          width: 4px;
          height: 100%;
          border-radius: 2px;
          background-color: var(--color-bg-5);
        }

        p {
          opacity: 0.75;
        }
      }

      &.ad-start {
        span {
          background-color: hsl(var(--p), 50%, 92%);
          color: var(--color-primary);
        }
      }

      &.ad-save {
        span {
          background-color: hsl(var(--hue-success), 50%, 88%);
          color: var(--color-success);
        }
      }

      &.ad-status-pending {
        span {
          background-color: hsl(var(--hue-warning), 70%, 88%);
          color: var(--color-warning);
        }
      }

      &.ad-status-active {
        span {
          background-color: hsl(var(--hue-success), 70%, 88%);
          color: var(--color-success);
        }
      }

      &.ad-status-inactive {
        span {
          background-color: hsl(var(--hue-success), 0%, 90%);
          color: hsl(0, 0%, 50%);
        }
      }
    }
  }
}
</style>
