<template>
  <div class="c-item-share">
    Saved with <a class="click-prevent" href="https://swipekit.app" target="_blank">swipekit.app</a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.c-item-share {
  padding-top: var(--margin);
  padding: var(--margin-lg);
  background-color: var(--color-bg-3);
  text-align: center;
  font-size: var(--font-size-sm);
}
</style>
