import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home-page.vue";
import Profile from "../views/profile.vue";
import Styleguide from "../views/styleguide.vue";
import Board from "../views/board-page.vue";
import Help from "../views/help.vue";
import ProfileBilling from "../views/profile-billing.vue";
import ProfileWorkspace from "../views/profile-workspace.vue";
import Multiplayer from "../views/multiplayer-page";
import MultiplayerLandingPages from "../views/multiplayer-landing-pages.vue";
import Uploads from "../views/uploads";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    children: [
      {
        path: "billing",
        name: "Billing Portal",
        component: ProfileBilling,
      },
      {
        path: "workspace",
        name: "Workspace Settings",
        component: ProfileWorkspace,
      },
    ],
  },
  {
    path: "/multiplayer",
    name: "Multiplayer",
    component: Multiplayer,
    children: [
      {
        path: "landing-pages",
        name: "Landing pages",
        component: MultiplayerLandingPages,
      },
      {
        path: "workspace",
        name: "Workspace Settings",
        component: ProfileWorkspace,
      },
    ],
  },
  {
    path: "/uploads",
    name: "Uploads",
    component: Uploads,
  },
  {
    path: "/styleguide",
    name: "Styleguide",
    component: Styleguide,
  },
  {
    path: "/boards/:slug",
    name: "Board",
    component: Board,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
