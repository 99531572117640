<template>
  <div class="c-library-page" v-if="isAuth" @scroll="onScroll">
    <Library ref="library"></Library>
  </div>
</template>

<script>
import Library from "@swipekit/components/library";

export default {
  components: {
    Library,
  },

  computed: {
    isAuth: function () {
      return this.$store.getters["users/isAuth"];
    },
    standalone: function () {
      return this.$store.getters["standalone"];
    },
  },

  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let buffer = 100;
      if (scrollTop + clientHeight >= scrollHeight - buffer) {
        //this.$refs.library.onEnd();
      } else {
      }
    },
  },
};
</script>

<style lang="scss">
.c-library-page {
  // height: 100vh;
  // position: relative;
  // overflow-y: auto;
}
</style>
