<template>
  <div class="c-item-image">
    <ImageCarousel
      :block="block"
      v-if="block.type === 'facebook' && block.meta.images && block.meta.images.length > 0"
    ></ImageCarousel>
    <img
      v-if="block.type === 'webpage' || block.type === 'webpageSelection' || block.type === 'email'"
      :src="`${assetUrl}/${block.screenshot}`"
    />
    <img
      v-if="block.type === 'facebook' && block.meta.singleImage"
      :src="`${assetUrl}/${block.meta.singleImage.name}`"
    />
    <video v-if="block.type === 'facebook' && block.meta.video" controls>
      <source :src="`${assetUrl}/${block.meta.video.name}`" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
import ImageCarousel from "./image-carousel.vue";
export default {
  components: {
    ImageCarousel,
  },

  props: {
    block: {},
  },

  computed: {
    style: function () {
      let height = Math.random() * 200 + 100;
      let str = `height : ${height}px`;

      return str;
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
  },
};
</script>

<style lang="scss">
.c-item-image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(hsl(var(--p), 20%, 95%) 0%, hsl(var(--p), 20%, 91%) 100%);

  > img {
    display: block;
    width: 100%;
    height: auto;
  }

  > video {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
