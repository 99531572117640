var text = {
  save: "Save",
  saving: "Saving..",
  error: "Error",
  errorAuth: "Please login",
  errorPay: "Please upgrade your plan",
  errorEmailVerificationPending: "Please verify your email",
  errorServer: "Server error, please try later",
  saved: "Saved!",
  alreadySaved: "Already Saved",
  selectDefault: "---Save in Swipekit board---",
};

export default text;

export function getText(type) {
  let str = text[type];

  if (!str) {
    return "ERROR";
  }

  return str;
}
