<template>
  <div class="c-header-avatar">
    <router-link to="/profile">
      <span v-if="user.avatar">
        <img :title="`${user.name}'s profile`" :src="`${assetUrl}/${user.avatar.name}`" />
        <!-- <img v-if="workspaceLogo" :src="`${assetUrl}/${workspaceLogo}`" /> -->
      </span>
      <span v-else>
        {{ user.name }}
      </span>
      <span class="badge" v-if="!isPaid && init"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    init: function () {
      return this.$store.getters["app/init"];
    },
    workspaceLogo: function () {
      const workspace = this.workspace;
      if (!workspace) {
        return;
      }
      if (!workspace.logo) {
        return;
      }
      if (typeof workspace.logo === "string") {
        return workspace.logo;
      }

      return workspace.logo.name;
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    isPaid: function () {
      return this.$store.getters["users/isPaid"];
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    workspace: function () {
      return this.$store.getters["workspaces/all"];
    },
  },
};
</script>

<style lang="scss">
.c-header-avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  > a {
    display: inline-block;
    width: var(--item-size);
    height: var(--item-size);
    position: relative;
    padding: 0;
    margin-left: 8px;

    text-decoration: none !important;

    transition: border-color 120ms linear;

    border: transparent solid 2px;
    border-radius: 99px;

    &:hover,
    &:active,
    &.active {
      border-color: #fff;
    }

    > span {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius-full);
      background-color: #000;

      img {
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius-full);
        object-fit: cover;
        object-position: center center;

        &:last-child:not(:first-child) {
          position: absolute;
          top: -3px;
          right: -3px;
          width: 50%;
          height: 50%;
          border: var(--color-button-font) solid 1px;
          background-color: #fff;
        }
      }
    }

    .badge {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 10px;
      height: 10px;
      border-radius: var(--border-radius-full);
      background-color: var(--color-primary);
      box-shadow: 0 0 0 2px var(--color-bg-2);
    }
  }
}
</style>
