<template>
  <div class="c-block-view-links">
    <a v-if="adLibraryLink" class="btn btn-primary btn-box btn-icon" :href="adLibraryLink" target="_blank">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 4H10C7.19974 4 5.79961 4 4.73005 4.54497C3.78924 5.02433 3.02433 5.78924 2.54497 6.73005C2 7.79961 2 9.19974 2 12V14C2 16.8003 2 18.2004 2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455C5.79961 22 7.19974 22 10 22H12C14.8003 22 16.2004 22 17.27 21.455C18.2108 20.9757 18.9757 20.2108 19.455 19.27C20 18.2004 20 16.8003 20 14V11M12 12L22 2M22 2H17M22 2V7"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span> Ad Library </span>
    </a>
    <a v-if="landingPage" class="btn btn-primary btn-box btn-icon" :href="landingPage" target="_blank">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 4H10C7.19974 4 5.79961 4 4.73005 4.54497C3.78924 5.02433 3.02433 5.78924 2.54497 6.73005C2 7.79961 2 9.19974 2 12V14C2 16.8003 2 18.2004 2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455C5.79961 22 7.19974 22 10 22H12C14.8003 22 16.2004 22 17.27 21.455C18.2108 20.9757 18.9757 20.2108 19.455 19.27C20 18.2004 20 16.8003 20 14V11M12 12L22 2M22 2H17M22 2V7"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span> Landing page </span>
    </a>

    <a v-if="brandPage" class="btn btn-primary btn-box btn-icon" :href="brandPage" target="_blank">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 4H10C7.19974 4 5.79961 4 4.73005 4.54497C3.78924 5.02433 3.02433 5.78924 2.54497 6.73005C2 7.79961 2 9.19974 2 12V14C2 16.8003 2 18.2004 2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455C5.79961 22 7.19974 22 10 22H12C14.8003 22 16.2004 22 17.27 21.455C18.2108 20.9757 18.9757 20.2108 19.455 19.27C20 18.2004 20 16.8003 20 14V11M12 12L22 2M22 2H17M22 2V7"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span> Facebook brand page </span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
  },

  computed: {
    adLibraryLink: function () {
      if (this.block.type === "facebook") {
        let str = `https://www.facebook.com/ads/library/?id=${this.block.metaId}`;
        let meta = this.block.meta;
        if (meta.pageId) {
          str = `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${meta.pageId}&search_type=page&media_type=all`;
        }

        return str;
      }
      if (this.block.type === "tiktok-library") {
        return this.block.source;
      }
      if (this.block.type === "tiktok-top-ads") {
        return this.block.source;
      }
    },
    landingPage: function () {
      let ctaLink = this.block.ctaLink;
      if (ctaLink) {
        return ctaLink;
      }

      if (this.block.meta && this.block.meta.ctaLink) {
        return this.block.meta.ctaLink;
      }

      return false;
    },
    brandPage: function () {
      if (this.block.type === "facebook" && this.block.source) {
        return this.block.source;
      }
    },
  },
};
</script>

<style lang="scss">
.c-block-view-links {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--spacer-sm);
  margin-bottom: var(--spacer-sm);

  a {
    margin-right: var(--margin);
    margin-bottom: var(--margin);
  }
}
</style>
