<template>
  <div
    :class="['c-accordion', { active: actuallyActive === true }, { 'min-height': minHeight === true }]"
    itemscope
    itemprop="mainEntity"
    itemtype="https://schema.org/Question"
  >
    <a :href="`#${slug}`" class="c-accordion__header" @click="onToggle">
      <h5 itemprop="name">{{ title }}</h5>
      <!-- <Tooltip v-if="description" :description="description"></Tooltip> -->
    </a>
    <div :id="slug" class="c-accordion__body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
      <div class="c-accordion__body__inner" itemprop="text">
        <article v-if="text" v-html="text"></article>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from "@swipekit/lib/slugify";

export default {
  components: {},
  watch: {
    active: {
      handler: function () {
        if (this.active) {
          this.actuallyActive = true;
        }
      },
      immediate: true,
    },
  },
  data: function () {
    return {
      actuallyActive: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Accordion",
    },
    description: {
      type: String,
      default: null,
    },
    minHeight: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },
  methods: {
    onToggle: function () {
      this.actuallyActive = !this.actuallyActive;
    },
  },
  computed: {
    slug: function () {
      return slugify(this.title);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.c-accordion {
  &__header {
    padding: 12px 0;
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    border-bottom: var(--g5) solid 1px;
    text-decoration: none !important;

    > h4 {
      margin: 0;
    }
    svg {
      display: inline-block;
      position: absolute;
      top: calc(50% - 12px);
      right: 0;
      cursor: pointer;
      transform: rotate(0);
      transition: all var(--transition-time-lg) ease-out;
      opacity: 0.5;
    }
  }
  &__body {
    height: 0;
    overflow: hidden;
    border-bottom: transparent solid 1px;
    &__inner {
      padding: var(--margin-lg) 0;
    }
  }
  &.active {
    .c-accordion__header {
      svg {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
    .c-accordion__body {
      height: auto;
    }
  }
  &.min-height {
    &.active {
      .c-accordion__body {
        min-height: 220px;
      }
    }
  }
  @media screen and (max-width: 428px) {
    &__header {
      padding: var(--margin-lg) 0;
    }
  }
}
</style>
