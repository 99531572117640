<template>
  <div class="c-comment-item">
    <CommentHeader :comment="comment"></CommentHeader>

    <div class="c-comment-item__body">
      <article>
        {{ comment.text }}
      </article>
    </div>
  </div>
</template>

<script>
import parseISO from "date-fns/parseISO";
import formatDistance from "date-fns/formatDistance";

import CommentHeader from "./header";

export default {
  components: {
    CommentHeader,
  },

  props: {
    comment: {},
  },

  computed: {
    user: function () {
      return this.comment.user;
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    createdAt: function () {
      let comment = this.comment;
      let date = comment.createdAt;

      date = parseISO(date);

      date = formatDistance(date, new Date());

      return date;
    },
  },
};
</script>

<style lang="scss">
.c-comment-item {
  &__body {
    padding: var(--margin-lg);
    padding-top: 0;
    font-size: var(--font-size-sm);

    article {
      p {
        margin-bottom: var(--margin);

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
