<template>
  <div id="app">
    <div ref="glitterContainer" class="glitter-container"></div>
    <Header></Header>

    <template v-if="init">
      <transition name="fade">
        <span class="c-spinner" v-if="processing"></span>
      </transition>
    </template>

    <div v-if="init" :class="['c-layout', { sidebar: sidebar === true }]">
      <transition name="fade">
        <router-view />
      </transition>
    </div>

    <BulkEdit></BulkEdit>
    <Sidebar></Sidebar>

    <ModalLogin v-if="init"></ModalLogin>
    <ModalBoardCreate v-if="init"></ModalBoardCreate>
    <ModalBoardUpdate v-if="init"></ModalBoardUpdate>
    <ModalSettings v-if="init"></ModalSettings>
    <ModalOnboarding v-if="init"></ModalOnboarding>
    <ModalBlockView :standalone="standalone" v-if="init"></ModalBlockView>
    <ModalBlockCreate v-if="init"></ModalBlockCreate>
    <ModalExtensionHelper v-if="init"></ModalExtensionHelper>
    <ModalShare v-if="init"></ModalShare>
    <ModalShareAd v-if="init"></ModalShareAd>
    <ModalAfterUpgrade ref="modalafterupgrade"></ModalAfterUpgrade>
    <ModalMultiplayerTutorial v-if="init"></ModalMultiplayerTutorial>
    <ModalReward v-if="init"></ModalReward>
    <ModalBoardSave v-if="init"></ModalBoardSave>

    <Message></Message>

    <TrialLine v-if="showTrialLine"></TrialLine>

    <!-- <Crop :cropActive="false"></Crop> -->
  </div>
</template>

<script>
import ModalLogin from "@swipekit/components/modal/modal-login";
import ModalBoardCreate from "@swipekit/components/modal/modal-board-create";
import ModalBoardUpdate from "@swipekit/components/modal/modal-board-update";
import ModalSettings from "@swipekit/components/modal/modal-settings";
import ModalOnboarding from "@swipekit/components/modal/modal-onboarding";
import ModalBlockView from "@swipekit/components/modal/modal-block-view";
import ModalBlockCreate from "@swipekit/components/modal/modal-block-create";
import ModalExtensionHelper from "@swipekit/components/modal/modal-extension-helper";
import ModalShare from "@swipekit/components/modal/modal-share";
import ModalShareAd from "@swipekit/components/modal/modal-share-ad";
import ModalReward from "@swipekit/components/modal/modal-reward";
import ModalBoardSave from "@swipekit/components/modal/modal-board-save";
import ModalAfterUpgrade from "@swipekit/components/modal/modal-after-upgrade";

import ModalMultiplayerTutorial from "@swipekit/components/multiplayer/modal-tutorial";

import Message from "@swipekit/components/message";

import AppHeader from "@swipekit/components/app/header";

import api from "@swipekit/lib/api";

import BulkEdit from "@swipekit/components/bulk-edit";
import Sidebar from "@swipekit/components/sidebar";

import Header from "@swipekit/components/app/header";

import hotkeys from "hotkeys-js";

import TrialLine from "@swipekit/components/utils/trial-line";

import trackAffiliate from "@swipekit/lib/affiliate";

export default {
  components: {
    ModalLogin,
    ModalBoardCreate,
    ModalBoardUpdate,
    ModalSettings,
    ModalOnboarding,
    ModalBlockView,
    ModalBlockCreate,
    ModalExtensionHelper,
    ModalShare,
    ModalShareAd,
    ModalReward,
    ModalBoardSave,
    ModalAfterUpgrade,
    ModalMultiplayerTutorial,
    AppHeader,
    Message,
    BulkEdit,
    Sidebar,
    Header,
    TrialLine,
  },

  computed: {
    showTrialLine: function () {
      if (!this.init) {
        return false;
      }

      if (!this.isAuth) {
        return false;
      }

      if (this.workspace && this.workspace.plan === "TESTER") {
        return false;
      }

      if (this.isPaid) {
        return false;
      }

      return true;
    },
    appOptions: function () {
      return this.$store.getters["app/options"];
    },
    init: function () {
      return this.$store.getters["app/init"];
    },
    onboarded: function () {
      return this.$store.getters["app/onboarded"];
    },
    isAuth: function () {
      return this.$store.getters["users/isAuth"];
    },
    processing: function () {
      return this.$store.getters["app/processing"];
    },
    standalone: function () {
      return this.$store.getters["standalone"];
    },
    sidebar: function () {
      if (this.standalone) {
        return false;
      }
      return true;
    },
    auth: function () {
      let isAuth = this.$store.getters["users/isAuth"];

      return isAuth;
    },
    extensionId: function () {
      return this.$store.getters["extensionId"];
    },
    isDeactivated: function () {
      return this.$store.getters["users/isTrialOver"];
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    workspace: function () {
      return this.$store.getters["workspaces/all"];
    },
    isPaid: function () {
      return this.$store.getters["users/isPaid"];
    },
  },

  watch: {
    isDeactivated: function () {
      if (this.isDeactivated) {
        this.$router.push({ path: "/profile/billing" });
      }
    },
    auth: function () {
      this.sendTokenToChromeExtension();

      if (this.workspace) {
        console.log(this.workspace, this.workspace.activated);
      }

      // if(this.workspace && !this.workspace.activated) {
      //   this.$store.dispatch('app/toggleOnboarding', true);
      // }
    },
    init: function () {},
    appOptions: {
      deep: true,
      handler: function () {
        return;
        let gridSize = this.appOptions.gridSize;
        if (typeof this.$el !== undefined) {
          let root = document.documentElement;
          let width = 16.6;
          if (gridSize === 1) {
            width = 14.28;
          }
          if (gridSize === 3) {
            width = 20;
          }
          if (gridSize === 4) {
            width = 25;
          }
          if (gridSize === 5) {
            width = 33.3;
          }
          if (this.$el && this.$el.style) {
            this.$el.style.setProperty("--item-width", width + "%");
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    async load() {
      await this.$store.dispatch("init").catch((err) => {
        console.log(err);
      });
    },

    async sendTokenToChromeExtension() {
      let auth = this.auth;
      let token = (await api.users.getToken()) || "";
      if (!token) {
        return null;
      }
      let jwt = token;
      let extensionId = this.extensionId;
      if (typeof chrome === "undefined") {
        return;
      }
      if (!chrome) {
        return;
      }
      if (!chrome.runtime) {
        return;
      }
      if (!chrome.runtime.sendMessage) {
        return;
      }
      chrome.runtime.sendMessage(extensionId, { type: `auth`, jwt }, (response) => {
        console.log("Handshake performed");
        this.$store.dispatch("setExtensionState", true);

        if (response && response.hasAuth && response.hasAuth === true) {
          console.log("handshake was warm");
          this.$store.dispatch("message/onExtensionAuth");
        }
      });
    },

    // Detects whether extension is installed or not
    async pingExtension() {
      let extensionId = this.extensionId;
      if (typeof chrome === "undefined") {
        return;
      }
      if (!chrome.runtime) {
        return;
      }
      chrome.runtime.sendMessage(extensionId, { type: `ping` }, (response) => {
        console.log("PINGED");
        // If u get this response, extension is installed
        this.$store.dispatch("setExtensionState", true);
      });
    },

    registerHotkeys: function () {
      hotkeys("esc", (event, handler) => {
        if (!this.isAuth) {
          return;
        }

        // hide sidebar
        this.$store.dispatch("app/setSidebar", false);

        // hide bulk-edit modal
        this.$store.dispatch("ads/clearSelection");
      });
      hotkeys("alt+a", (event, handler) => {
        if (!this.isAuth) {
          return;
        }
        this.$router.push("/");
      });
      hotkeys("alt+s", (event, handler) => {
        if (!this.isAuth) {
          return;
        }
        this.$router.push("/multiplayer");
      });
      hotkeys("alt+d", (event, handler) => {
        if (!this.isAuth) {
          return;
        }
        this.$store.dispatch("app/toggleSidebar");
      });
    },
    checkIfUpgrade() {
      if (this.$route && this.$route.query && this.$route.query.billing && this.$route.query.billing === "successful") {
        setTimeout(() => {
          this.$refs.modalafterupgrade.open();
        }, 4000);
      }
    },
    checkIfReferral() {
      if (this.$route && this.$route.query && this.$route.query.via) {
        const referral = this.$route.query.via;

        this.$store.dispatch("app/setReferral", referral);
      }
    },
    checkIfBlockId() {
      if (this.$route && this.$route.query && this.$route.query.blockid) {
        let blockid = this.$route.query.blockid;

        // Don't show the ad details page if they aren't onboarded
        if (!this.user.onboarded) {
          return;
        }

        if (blockid.length !== 36) {
          return;
        }

        setTimeout(() => {
          this.$store.dispatch("ads/setView", blockid);
        }, 4000);
      }
    },
    checkIfBoardSave() {
      if (this.$route && this.$route.query && this.$route.query["share-board"]) {
        let boardId = this.$route.query["share-board"];

        boardId = parseInt(boardId);

        if (isNaN(boardId)) {
        } else {
          // trigger board save
          this.$store.dispatch("app/boardSave", boardId);
        }
      }
    },
  },

  mounted: function () {
    trackAffiliate();
    this.pingExtension();
    this.load().then(() => {
      // perform handshake everytime
      this.sendTokenToChromeExtension();

      this.checkIfBlockId();
    });
    //crop:active
    window.addEventListener("message", (e) => {
      //console.log(e);
    });

    this.registerHotkeys();

    this.checkIfUpgrade();

    this.checkIfReferral();

    this.checkIfBoardSave();
  },
};
</script>

<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "assets/styles/main.scss";
#app {
  > .c-spinner {
    position: absolute;
    z-index: 999;
    top: 22px;
    left: 22px;
    width: 32px;
    height: 32px;
    border-width: 5px;
    border-color: var(--color-primary);
    border-bottom-color: transparent;
    background-color: hsl(var(--p), 40%, 99%);
    box-shadow: 0 0 0 8px hsl(var(--p), 40%, 99%);
    animation: rotation 750ms linear infinite;
  }
  .c-layout {
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 240ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .glitter {
    position: absolute;
    z-index: 999;
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
    animation: glitter-animation 1s infinite;
  }

  @keyframes glitter-animation {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(300px) rotate(720deg);
      opacity: 0;
    }
  }

  @include media-breakpoint-down(sm) {
  }
}
</style>
