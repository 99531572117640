<
<template>
  <form class="c-form-verification-email" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Resend Verification Email"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

let schema = {};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      currentForm: {},
      status: null,
    };
  },

  props: {
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  computed: {
    computedSchema: function () {
      let s = schema;
      let user = this.user;
      if (user.type === "ADMIN") {
      }

      return s;
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    schema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      form = {
        id: form.id,
        name: form.name,
        logo: form.logo,
      };

      let formData = new FormData();

      formData.append("id", form.id);
      formData.append("name", form.name);
      formData.append("logo", form.logo);

      let response = await this.$store.dispatch("workspaces/resendVerification", formData);
      this.$emit("onSubmit");
      this.processing = false;
    },
  },

  created: function () {
    this.currentForm = {};
  },
};
</script>

<style></style>
