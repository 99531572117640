<template>
  <div class="c-page-multiplayer">
    <Page :i="5">
      <span class="c-byline"> Tutorial #4: Swipekit Multiplayer </span>
      <h1>Save Ads from the Swipekit Multiplayer</h1>
      <article class="article-text text-center">
        <p>We have our own Ad Library with over 100,000 Ads.</p>
        <p>When you want to quickly gather inspo, use the Swipekit Multiplayer to find winning Ads.</p>
      </article>
      <div class="c-showcase">
        <img src="/images/multiplayer.png" />
      </div>
      <ButtonCta :timer="3" @onClick="onClick">
        <span> Got it </span>
      </ButtonCta>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
export default {
  components: {
    Page,
    ButtonCta,
  },

  methods: {
    onClick: function () {
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss"></style>
