<template>
  <form class="c-form-board-update" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Update board"
      :processing="processing"
    ></Form>
    <hr />
    <button class="btn btn-danger" @click="deleteResource">Delete board</button>
  </form>
</template>

<script>
import Form from "./index.vue";

let schema = {
  name: {
    component: "InputText",
    label: "Board name",
    description: "Eg: Bold graphics, Good Typesetting, Blurred videos, etc.",
    validations: {
      required: {
        params: null,
        message: "A board name is required",
      },
      minLength: {
        params: 4,
        message: "Give a descriptive name to your board. Minimum 4 characters",
      },
      maxLength: {
        params: 54,
        message: `That's a insanely long board name. Maximum 54 characters`,
      },
    },
  },
  description: {
    component: "InputEditor",
    label: "Board description",
    placeholder: "What is this board about",
  },
  public: {
    component: "InputPublicLink",
    label: "Make this public?",
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      currentForm: {
        name: "",
        type: "",
      },
      status: null,
    };
  },

  props: {
    form: {
      type: Object,
      default: function () {
        return {
          name: "",
          type: "",
        };
      },
    },
  },

  computed: {
    computedSchema: function () {
      let s = schema;
      let user = this.user;
      if (user.type === "ADMIN") {
        s.slug = {
          component: "InputText",
          label: "Slug",
        };
      }

      s.public.slug = this.form.slug;

      return s;
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    schema: function () {
      let s = schema;

      s.public.slug = this.form.slug;

      return s;
    },
  },

  methods: {
    async deleteResource() {
      await this.$store.dispatch("boards/remove", this.form.id);
      this.$emit("onSubmit");
      this.$router.replace({ path: "/" });
    },
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      form = {
        ...form,
      };

      delete form.createdAt;
      delete form.slug;
      delete form.userId;
      delete form.workspaceId;
      delete form._count;

      console.log(form);

      if (form.blocks) {
        delete form.blocks;
      }

      let response = await this.$store.dispatch("boards/update", form);
      this.$emit("onSubmit");
      this.processing = false;
    },
  },

  created: function () {
    this.currentForm = this.form;
  },
};
</script>

<style></style>
