<template>
  <div class="c-comment-edit">
    <CommentHeader :comment="computedForm"></CommentHeader>
    <div class="c-comment-edit__body">
      <FormCommentCreate :form="computedForm"></FormCommentCreate>
    </div>
  </div>
</template>

<script>
import FormCommentCreate from "@swipekit/components/form/form-comment-create";

import CommentHeader from "./header";

export default {
  components: {
    FormCommentCreate,
    CommentHeader,
  },

  props: {
    adId: {},
  },

  computed: {
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    workspace: function () {
      return this.$store.getters["workspaces/all"];
    },
    computedForm: function () {
      let user = this.user;

      let workspace = this.workspace;

      let form = {
        text: "",
        userId: user.id,
        workspaceId: workspace.id,
        adId: this.adId,
      };

      return form;
    },
  },
};
</script>

<style lang="scss">
.c-comment-edit {
  &__header {
    padding: var(--margin-lg);
    font-size: var(--font-size-sm);
    display: flex;
    align-items: center;

    img {
      width: var(--spacer);
      height: var(--spacer);
      border-radius: var(--border-radius-full);
      margin-right: var(--margin-lg);
    }
  }

  &__body {
    padding: var(--margin-lg);
    padding-top: 0;
    font-size: var(--font-size-xs);

    article {
      p {
        margin-bottom: var(--margin);

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
