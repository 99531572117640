<template>
  <div class="c-modal-after-upgrade">
    <Modal v-model="options.active" @input="closeModal" :closable="true">
      <template v-slot:title>
        <h2>Thank you for upgrading</h2>
        <article class="article-text">
          <p>You now have access to Swipekit Pro subscription.</p>
          <ul>
            <li><span></span>Save Unlimited Ads</li>
            <li><span></span>Save from Facebook™ Ads Library</li>
            <li><span></span>Save from Tiktok™ Creative Center</li>
            <li><span></span>Save from Tiktok™ Ad Library</li>
            <li><span></span>Save from Linkedin™ Ad Library</li>
            <li><span></span>Save from Pinterest™ Ads Repository</li>
            <li><span></span>Browse and save Ads from Swipekit Multiplayer</li>
            <li><span></span>Create and share Unlimited boards</li>
            <li><span></span>Add an extra team member for free</li>
          </ul>
        </article>
      </template>
    </Modal>
  </div>
</template>

<script>
import confetti from "canvas-confetti";
import Modal from "./index.vue";
import FormBoardUpdate from "@swipekit/components/form/form-board-update.vue";

export default {
  components: {
    Modal,
    FormBoardUpdate,
  },

  data: function () {
    return {
      options: {
        name: "modal-board-update",
        active: false,
      },
    };
  },

  watch: {
    "options.active": {
      handler: function () {
        if (this.options.active) {
          this.fireConfetti();

          if (window.rewardful) {
            rewardful("convert", { email: this.user.email });
          }
        }
      },
    },
  },

  computed: {
    user: function () {
      return this.$store.getters["users/user"];
    },
  },

  methods: {
    fireConfetti: function () {
      let duration = 6 * 1000;
      let animationEnd = Date.now() + duration;
      let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      let interval = setInterval(function () {
        let timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        let particleCount = 60 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
      }, 250);
    },
    open: function () {
      this.options.active = true;
    },
    closeModal: function () {
      this.options.active = false;
    },
  },

  mounted: function () {
    //this.options.active = true;
  },
};
</script>

<style lang="scss">
.c-modal-after-upgrade {
  .vfm {
    .vfm__overlay {
      opacity: 0.8;
    }
  }

  ul {
    text-align: left;
    list-style: none;

    li {
      display: flex;
      margin-bottom: var(--spacer-sm);
    }

    span {
      display: inline-block;
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-top: var(--margin-sm);
      margin-right: var(--margin-lg);
      background-size: cover;
      background-color: var(--color-primary);
      mask-image: url("/icons/confirm.fill.svg");
    }
  }
}
</style>
