import axios from "axios";
import api from "./api";

let http = axios.create();

let context = null;

http.defaults.timeout = 22000; // 18 seconds

http.interceptors.response.use(
  (config) => {
    setHeaderFromConfig(config);
    return config;
  },
  (err) => {
    parseError(err);
    if (
      err &&
      err.response &&
      err.response.data &&
      err.response.data[0] &&
      typeof err.response.data[0].message === "string"
    ) {
      if (typeof err.response.data !== "object") {
        try {
          err.response.data[0] = JSON.parse(err.response.data[0].message);
        } catch (err) {
          // throw err?
        }
      }
    }
    return Promise.reject(err);
  }
);

function setHeaderFromConfig(config) {
  if (config && config.headers && config.headers["x-token"]) {
    http.defaults.headers.common["Authorization"] = `Bearer ${config.headers["x-token"]}`;
    api.users.saveToken(config.headers["x-token"]);
  }
}

function parseError(err) {
  if (err.response) {
    serverError(err);
  } else if (err.request) {
    clientError(err);
  } else {
    genericError(err);
  }
}

function clientError(err) {
  context.dispatch("events/onClientError", err, { root: true });
}

function serverError(err) {
  context.dispatch("events/onServerError", err, { root: true });
}

function genericError(err) {
  context.dispatch("events/onGenericError", err, { root: true });
}

function setHeader(key, value) {
  var type = "common";
  http.defaults.headers[type][key] = value;
}

function setUrl(url) {
  http.defaults.baseURL = url;
}

function setContext(ctx) {
  context = ctx;
}

export default {
  setContext: setContext,
  setUrl: setUrl,
  setHeader: setHeader,
};

export { http };
