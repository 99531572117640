<template>
  <div class="c-modal-edit-boards">
    <Modal v-model="options.active" @input="closeModal" :closable="true">
      <template v-slot:title>
        <h3>Bulk update boards</h3>
      </template>
      <template>
        <article class="article-text">
          <p>Add {{ titleText }} inside one or more boards.</p>
        </article>
        <FormEditBoards :selection="selection" @onSubmit="onSubmit"></FormEditBoards>
        <!-- <hr />
        <article class="article-text">
          <p>Or create a board and add {{ titleText }} into said board.</p>
        </article>
        <FormBoardCreate :buttonText="`Create board and add selected Ads`" @submit="onBoardCreated"></FormBoardCreate> -->
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "./index.vue";

import { parseISO, format } from "date-fns";

import FormEditBoards from "@swipekit/components/form/form-edit-boards";
import FormBoardCreate from "../form/form-board-create";

export default {
  components: {
    Modal,
    FormEditBoards,
    FormBoardCreate,
  },

  data: function () {
    return {
      options: {
        name: "modal-edit-boards",
        active: false,
      },
      forceRender: true,
    };
  },

  props: {
    selection: {},
  },

  watch: {
    "options.active": {
      handler: function () {},
    },
    viewId: function () {
      if (this.viewId) {
        this.options.active = true;
      } else {
        this.options.active = false;
      }
    },
    block: function () {
      this.forceRender = false;
      this.$nextTick(() => {
        this.forceRender = true;
      });
    },
  },

  computed: {
    titleText: function () {
      let len = this.selection.length || 0;

      if (len === 1) {
        return `${len} Ad`;
      } else {
        return `${len} Ads`;
      }
    },
    sourceUrl: function () {
      let block = this.ad.block;

      if (block.source && typeof block.source === "string") {
        return block.source;
      }

      return null;
    },
    viewCache: function () {
      return this.$store.getters["ads/viewCache"];
    },
    createdAt: function () {
      let createdAt = this.block.createdAt;
      let date = parseISO(createdAt);
      let str = format(date, `do MMM yy`);
      return str;
    },
    ad: function () {
      return this.$store.getters["ads/one"](this.viewId);
    },
    viewId: function () {
      return this.$store.getters["ads/viewId"];
    },
    text: function () {
      return this.block.title;
      let type = this.block.type;

      if (type === "email") {
        return "Email";
      }
      if (type === "webpage") {
        return "Webpage";
      }
      if (type === "webpageSelection") {
        return "Webpage section";
      }
      if (type === "facebook") {
        return "Facebook Ad library";
      }

      return "";
    },
    standalone: function () {
      return this.$store.getters["standalone"];
    },
  },

  methods: {
    onBoardCreated: function (e) {
      console.log(e);
    },
    onSubmit: function () {
      this.$emit("onSubmit");
    },
    navigate: function (direction) {
      let viewCache = this.viewCache;
      let ids = viewCache.map((block) => {
        return block.id;
      });

      if (ids.length < 1) {
        return;
      }

      let i = ids.indexOf(this.block.id);

      if (i === -1) {
        return;
      }

      if (direction === "prev") {
        if (i === 0) {
          i = ids.length - 1;
        } else {
          i = i - 1;
        }
      }
      if (direction === "next") {
        if (i === ids.length - 1) {
          i = 0;
        } else {
          i = i + 1;
        }
      }

      let id = ids[i];

      this.$store.dispatch("ads/setView", id);
    },
    onSubmit: function () {
      this.options.active = false;
    },
    activate: function () {
      this.options.active = true;
    },
    closeModal: function () {
      this.options.active = false;
    },
    afterDelete: function () {
      this.closeModal();
    },
  },

  mounted: function () {
    //this.options.active = true;
  },
};
</script>

<style lang="scss">
.c-modal-edit-boards {
  .vfm__overlay {
    &.non-closable {
    }
  }
  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    padding: 0;
  }

  .modal-content {
    max-height: 100%;
    margin: 0;

    //overflow: hidden;
  }

  .modal__content {
    padding: var(--spacer);
    padding-top: var(--spacer-sm);
    height: 100%;
  }

  .form-edit-boards {
    align-self: baseline;
  }
}
</style>
