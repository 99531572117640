const schema = {
  name: {},
  email: {
    component: "InputEmail",
    label: "Your email",
    placeholder: "email@gmail.com",
    properties: {
      columnSize: 6,
    },
    validations: {
      // required: {
      //   params: null,
      //   message: 'This field is required'
      // }
    },
  },
  password: {
    component: "InputPassword",
    label: "Password",
    placeholder: "********",
    properties: {
      columnSize: 6,
    },
    validations: {
      // required: {
      //   params: null,
      //   message: 'This field is required'
      // }
    },
  },
};

export default schema;
