<template>
  <div class="c-item-footer" v-if="block.type !== 'tiktok-ad-library'">
    <div class="c-item-footer__meta" v-if="showMeta">
      <p v-if="adTitle">{{ adTitle }}</p>
      <article v-if="adCopy" v-html="adCopy"></article>
    </div>
    <div class="c-item-footer__body" v-if="showCta">
      <Cta v-if="block && block.meta.cta" :block="block"> </Cta>
      <span title="Ad start date" v-if="block.startDate">{{ computedDates.startDate }}({{ dateDifference }} days)</span>
    </div>
    <div class="c-item-footer__network" v-if="showNetworks">
      <UtilNetworks :block="block"></UtilNetworks>
    </div>
    <div class="c-item-footer__stats" v-if="ad.block.type === 'facebook'">
      <UtilAdLink :block="ad.block"></UtilAdLink>
    </div>
    <div class="c-item-footer__stats" v-if="block.type === 'tiktok' || block.type === 'tiktok-top-ads'">
      <UtilsStats :block="block"></UtilsStats>
    </div>
    <div class="c-item-footer__stats" v-if="ad.tags && ad.tags.length > 0">
      <UtilTags :ad="ad"></UtilTags>
    </div>
    <a v-if="shared" class="c-grid-item__share" href="https://swipekit.app">
      <span> Ad saved by swipekit.app </span>
    </a>

    <div class="c-item-footer__save" v-if="multiplayer && ad.block">
      <Picker :fbsaved="ad.block.isSaved" :blockId="ad.block.id"></Picker>
    </div>
  </div>
</template>

<script>
import Avatar from "@swipekit/components/utils/avatar";
import Cta from "@swipekit/components/utils/cta";

import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";
import UtilsStats from "@swipekit/components/utils/stats";
import UtilNetworks from "@swipekit/components/utils/networks";
import UtilTags from "@swipekit/components/utils/tags";
import UtilAdLink from "@swipekit/components/utils/ad-link";

import Picker from "@swipekit/components/picker";

export default {
  components: {
    Avatar,
    Cta,
    UtilsStats,
    UtilNetworks,
    UtilTags,
    UtilAdLink,
    Picker,
  },

  props: {
    ad: {},
    view: {},
    multiplayer: {
      type: Boolean,
      default: false,
    },
    shared: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showMeta: function () {
      if (!this.adTitle && !this.adCopy) {
        return false;
      }

      if (this.view.includes("description")) {
        return false;
      }

      return true;
    },
    showCta: function () {
      if (this.block.type !== "facebook" && this.block.type !== "linkedin") {
        return false;
      }

      if (this.view.includes("metadata")) {
        return false;
      }

      return true;
    },
    showNetworks: function () {
      if (this.block.type !== "facebook") {
        return false;
      }

      if (!this.block.networks) {
        return false;
      }

      if (this.view.includes("metadata")) {
        return false;
      }

      return true;
    },
    block: function () {
      return this.ad.block;
    },
    adCopy: function () {
      let adCopy = this.block.adCopy;

      if (!adCopy) {
        return null;
      }

      if (adCopy.includes("&#") && adCopy.length < 200) {
        return null;
      }

      return adCopy;
    },
    adTitle: function () {
      return this.block.adTitle;
    },
    computedDates: function () {
      let startDate = this.block.startDate;

      let token = "dd/MM";

      if (startDate) {
        startDate = format(parseISO(startDate), token);
      }

      return {
        startDate,
      };
    },
    dateDifference: function () {
      let startDate = this.block.startDate;

      let endDate = new Date().toISOString();
      if (startDate && endDate) {
        return differenceInDays(parseISO(endDate), parseISO(startDate));
      }
    },
    title: function () {
      let block = this.block;

      if (block.title) {
        return block.title;
      }

      if (block.brand) {
        return block.brand.name;
      }

      if (block.meta && block.meta.advertiser) {
        return block.meta.advertiser.name;
      }

      if (block.type === "webpageSelection") {
        return "Webpage clip";
      }

      return block.type;
    },
    computedTags: function () {
      let tags = this.block.tags;

      let newTags = [];

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].text) {
          newTags.push({
            text: tags[i].text,
          });
        }
      }

      return newTags;
    },
    computedProperties: function () {
      let block = this.block;
      let arr = [];

      for (const property in block.meta) {
        if (typeof block.meta[property] === "string") {
          arr.push({
            key: property,
            value: block.meta[property],
          });
        }
      }

      return arr;
    },

    user: function () {
      return this.$store.getters["users/user"];
    },
  },

  methods: {
    onComment: function () {
      this.$emit("onComment");
    },
  },
};
</script>

<style lang="scss">
.c-item-footer {
  display: block;
  padding-top: var(--margin);
  padding-left: var(--margin-lg);
  padding-bottom: var(--margin);

  label {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: var(--font-size-xs);
    opacity: 0.75;
    margin-bottom: var(--margin);

    svg {
      width: 16px;
      height: 16px;
      margin-right: var(--margin);
    }
  }

  &__meta {
    padding: var(--margin) 0;
    > p {
      margin-bottom: var(--margin);
      color: var(--color-font-heading);
      font-weight: 600;
      font-size: var(--font-size-xs);
    }
    article {
      color: var(--color-font);
      font-size: var(--font-size-xs);
      white-space: pre-wrap;
      padding-right: var(--margin-lg);
    }
  }

  &__header {
    display: flex;
    align-items: center;

    article {
      display: flex;
      max-width: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      margin-left: var(--margin-lg);
      margin-right: var(--margin);

      p {
        margin: 0;
        font-size: var(--font-size);
      }

      span {
        opacity: 0.85;
      }
    }
  }

  &__body {
    margin-top: var(--margin);
    margin-right: var(--margin-lg);
    font-size: var(--font-size-xs);
    color: var(--color-font);
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: space-between;

    article {
      display: flex;
      max-width: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      margin-left: var(--margin-lg);
      margin-right: var(--margin);

      p {
        margin: 0;
        font-size: var(--font-size);
      }

      span {
        opacity: 0.85;
      }
    }
  }

  &__network {
    display: flex;
    flex-wrap: wrap;
    font-size: var(--font-size-xs);
    color: var(--color-font) !important;

    span {
      width: 50%;
      min-width: 50%;
    }
  }

  article {
    p {
      opacity: 0.8;
      color: var(--color-font);
      font-size: var(--font-size-sm);
      line-height: 1;
    }

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      i {
        display: inline-block;
        margin-right: var(--margin-sm);
        line-height: 1;
        font-style: normal;
        opacity: 0.8;
        color: var(--color-font);
        font-size: var(--font-size-xs);

        &:not(:last-child) {
          &:after {
            content: ",";
          }
        }
      }
    }
  }

  &__save {
    padding-top: var(--margin);
    padding-right: var(--margin-lg);
    padding-bottom: var(--margin);
  }

  &__comments {
    margin-top: var(--margin-lg);
    border-top: var(--color-bg-3) solid 1px;
    padding-top: var(--margin-lg);
    margin-right: var(--margin-lg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-font);

    > span {
      font-size: var(--font-size-xs);
    }

    > svg {
      width: 20px;
      height: 20px;
    }
  }
  &:hover {
    color: var(--color-font) !important;
    p {
      color: var(--color-font) !important;
    }
    .c-item-avatar__hover {
      opacity: 1;
    }
  }
}
</style>
