<template>
  <div class="c-multiplayer-landing-pages">
    <LandingPages></LandingPages>
  </div>
</template>

<script>
import LandingPages from "@swipekit/components/landing-pages";

export default {
  components: {
    LandingPages,
  },
};
</script>

<style lang="scss">
.c-multiplayer-landing-pages {
}
</style>
