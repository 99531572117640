<template>
  <form class="c-form-invite-users" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Invite"
      :processing="processing"
      :errors="errors"
    ></Form>
    <small> If an invitee doesn't accept your Workspace invite, their invite will expire in 7 days. </small>
  </form>
</template>

<script>
import Form from "./index.vue";

let schema = {
  email: {
    component: "InputEmail",
    label: "Email",
    required: true,
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      form: {
        email: [],
      },
      status: null,
      errors: [],
    };
  },

  computed: {
    computedSchema: function () {
      let s = schema;
      let user = this.user;

      let companyUrl = user.email.split("@")[1];

      let genericEmails = ["yahoo.com", "gmail.com"];

      if (genericEmails.includes(companyUrl)) {
        companyUrl = "companyemail.com";
      }

      let email = `acme@${companyUrl}`;

      s.email.placeholder = email;

      return s;
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    schema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      form.emails = [form.email];

      delete form.email;

      let response = await this.$store.dispatch("workspaces/invite", form).catch((err) => {
        if (typeof err === "object") {
          this.errors = this.errors.concat(err);
        } else {
          this.errors.push(err);
        }
      });

      this.processing = false;

      if (!response) {
        return;
      }

      this.$store.dispatch("message/onUserInvited");

      this.$emit("onSubmit");
    },
  },

  created: function () {
    this.currentForm = this.form;
  },
};
</script>

<style lang="scss">
.c-form-invite-users {
  .c-form {
    margin-bottom: var(--margin-lg);
  }
}
</style>
