<template>
  <div class="form-group c-input-brand">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <v-select placeholder="Search for a brand" v-model="text" multiple @search="fetchOptions" :options="options">
      <template v-slot:option="option">
        <span>
          <img v-if="option.logo" :src="`${assetUrl}/${option.logo}`" />
        </span>
        {{ option.name }}
      </template>
      <template #open-indicator="{ attributes }">
        <svg
          v-bind="attributes"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9L12 15L18 9"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>
    </v-select>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription v-if="description" :message="description"></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

import filter from "@swipekit/lib/filter";

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
const debounce = function (func, wait = 300, immediate = false) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
};

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      text: [],

      search: "",
      offset: 0,

      options: [],
    };
  },

  watch: {
    text: function () {
      let ids = text.map((t) => {
        return t.id;
      });

      this.$emit("input", ids);
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  watch: {
    text: function () {
      let temp = this.text;

      temp = temp.map((t) => {
        return t.id;
      });
      this.$emit("input", this.text);
    },
    value: function () {
      let currentValue = this.text;

      let value = this.value;

      if (value !== currentValue) {
        this.text = value;
      }
    },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
  },

  methods: {
    dropdownShouldOpen: function (vSelect) {
      return true;
    },
    fetchOptions: async function (search, loading) {
      if (search === "") {
        return;
      }

      loading(true);

      await this.loadOptions(search);

      loading(false);
    },
    loadOptions: debounce(async function (search) {
      const params = {
        skip: this.offset,
        take: 20,
        query: search,
      };

      let data = await filter(params, "filter-brand");

      data = data || [];

      data = data.map((d) => {
        return {
          ...d,

          label: d.name,
          code: d.id,
        };
      });
      this.options = data;
    }),
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.c-input-brand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding-bottom: var(--margin-lg);

  --vs-controls-color: var(--color-font);

  .v-select {
    width: 100%;
  }

  .vs__dropdown-toggle {
    display: inline-block;
    width: calc(100% - 32px);
    border-color: transparent;
    font-weight: 400;
    font-size: var(--font-size);
    min-height: 32px;
    appearance: none;
    color: var(--color-font);

    padding: var(--margin);
    border-radius: var(--border-radius);
    border: var(--color-input-background) solid 1px;
    background-color: var(--color-bg-1);
    margin: 0px;
    transition: all 50ms ease-out;

    &:active,
    &:focus {
      border-color: var(--color-input-background-light);
      @include focus();
    }
    &:focus {
      outline: none;
      @include focus();
    }
  }

  .vs__actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    padding-right: var(--margin-sm);

    svg {
      width: 24px;
      height: 24px;
      margin-top: 6px;
    }
  }

  .vs__selected {
    display: inline-flex;
    align-items: center;
    padding: var(--margin-lg);
    font-size: var(--font-size-xs);
    font-weight: 500;
    padding: var(--margin-sm) var(--margin-lg);
    border-radius: 99px;
    margin: var(--margin-sm) 0;
    margin-right: var(--margin-sm);
    background-color: var(--color-primary);
    color: var(--color-button-font);
    border: none;
    padding-right: 2px;
    --vs-controls-color: var(--color-button-font);

    cursor: pointer;

    user-select: none;

    input {
      display: none;
    }

    &:hover {
      label svg {
        opacity: 1;
      }
    }

    label {
      margin: 0;
      display: flex;
      align-items: center;
      font-size: var(--font-size-sm);

      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
        opacity: 0.5;
        margin-right: var(--margin);
      }
    }

    button {
      display: inline-flex;
      margin-left: 4px;
      width: 16px;
      height: 16px;
      background-color: var(--color-bg-2);
      border: none;
      padding: 0;
      border-radius: 99px;
      justify-content: center;
      align-items: center;
    }

    &.active {
      svg {
        color: var(--color-primary);
        opacity: 1;
      }
    }
  }

  .vs__search {
    margin-top: 0;
    appearance: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .vs__dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
    border-radius: 5px;
    padding: var(--margin-lg);
    background: var(--color-bg-3);
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.4), inset 0 4px 6px 1px rgba(255, 255, 255, 0.2),
      0 4px 10px 1px rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.05);
    li {
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  .vs2__listbox {
    list-style: none;
  }

  .vs__dropdown-option {
    display: flex;
    align-items: center;
    padding: var(--margin) var(--margin-lg);
    font-size: var(--font-size-sm);
    border-radius: 5px;

    &--highlight {
      background-color: var(--color-bg-5);
      color: var(--color-font);
    }

    > span {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: var(--margin-lg);
      border-radius: 99px;
      position: relative;
      background-color: var(--color-bg-5);

      > img {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 99px;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}
</style>
