import Vue from "vue";
import Vuex from "vuex";

import { store } from "@swipekit/lib/store";
import { Loader } from "@googlemaps/js-api-loader";
import posthog from "posthog-js";

if (window) {
  window.posthog = posthog;
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    extensionId: "gojmmkhaiojimnnjhhilmhjmhdbdagod",
    extensionActive: false,
    google: null,

    affiliateId: null,
  },
  getters: {
    extensionId: function (state) {
      return state.extensionId;
    },
    standalone: function (state) {
      return false;
    },
    extensionState: function (state) {
      return state.extensionActive;
    },
    google: function (state) {
      return state.google;
    },

    affiliateId: function (state) {
      return state.affiliateId;
    },
  },
  mutations: {
    SET_GOOGLE: function (state, google) {
      state.google = google;
    },
  },
  actions: {
    async init(store) {
      let pie = null;

      store.dispatch("app/setProcessing", true);

      store.dispatch("loadGoogleMaps");

      await store.dispatch("users/hideCrisp");

      await store.dispatch("config/init");

      await store.dispatch("app/init");

      await store.dispatch("users/init");

      await store.dispatch("workspaces/init");

      await store.dispatch("boards/init");

      await store.dispatch("ads/init");

      await store.dispatch("comments/init");

      await store.dispatch("events/init");

      await store.dispatch("message/init");

      // ---

      if (store.rootGetters["users/isAuth"]) {
        pie = await store.dispatch("users/getPie");

        await store.dispatch("afterLogin", pie);

        posthog.init("phc_i5JrVN3GGrKMmJ1CnvfgGEtcZVq2uUonJu8MpiLiaE4", {
          api_host: "https://app.posthog.com",
          capture_pageview: false,
          enable_recording_console_log: false,
          autocapture: false,
          loaded: () => posthog.identify(store.getters["users/user"].id), // If you can already identify your user
        });
      }

      // let data = await store.dispatch("boards/getBoardData", {
      //   id: "client_jdstride-df50bdd1-board",
      //   action: "get-board-data",
      // });
      // console.log(data);

      if (store.getters.standalone) {
        await store.dispatch("boards/getBoardData");
      }

      await store.dispatch("app/setInit"); // <!-- Very Important, app literally loads after this dispatch

      await store.dispatch("triggerExtension", { type: "boards:update" });

      let workspace = store.getters["workspaces/all"];

      if (workspace && !workspace.activated) {
        await store.dispatch("app/promptEmailActivation");
      }

      store.dispatch("app/setProcessing", false);

      const affiliateId = localStorage.getItem("affiliateID");

      if (affiliateId) {
        store.state.affiliateId = affiliateId;
      }

      return true;
    },

    async loadGoogleMaps(store) {
      const loader = new Loader({
        apiKey: `AIzaSyBJyXeUG9HIvZYKnyfURODGyZ2Mw0rfp2I`,
        libraries: ["places"],
      });

      let google = await loader.load().catch((err) => {
        console.log("GOOGLE MAPS ERROR");
        console.log(err);
      });

      store.commit("SET_GOOGLE", google);
    },

    async afterLogin(store, pie) {
      await store.dispatch("app/afterLogin", pie);

      await store.dispatch("workspaces/afterLogin", pie);

      await store.dispatch("boards/afterLogin", pie);

      await store.dispatch("ads/afterLogin", pie);

      await store.dispatch("comments/afterLogin", pie);

      return true;
    },

    async afterLogout(store) {
      await store.dispatch("workspaces/afterLogout");

      await store.dispatch("boards/afterLogout");

      await store.dispatch("ads/afterLogout");

      await store.dispatch("comments/afterLogout");

      await store.dispatch("users/afterLogout");

      return true;
    },

    async triggerExtension(store, obj) {
      if (typeof chrome === "undefined") {
        return;
      }
      if (!chrome) {
        return;
      }
      if (!chrome.runtime) {
        return;
      }

      chrome.runtime.sendMessage(store.state.extensionId, obj, function (response) {
        resolve(response);
      });

      return;
    },

    async setExtensionState(store, condition) {
      store.state.extensionActive = condition;
    },
  },
  modules: {
    config: store.config,
    app: store.app,
    ads: store.ads,
    boards: store.boards,
    users: store.users,
    workspaces: store.workspaces,
    comments: store.comments,
    events: store.events,
    message: store.message,
  },
});
