<template>
  <div class="c-modal-settings">
    <Modal v-model="options.active" :closable="true" @input="onClose">
      <h1>Your settings</h1>
      <FormSettings @onSubmit="onSubmit"></FormSettings>
      <button class="btn btn-outline-primary" @click="onLogout">Logout</button>
    </Modal>
  </div>
</template>

<script>
import Modal from "./index.vue";
import FormSettings from "@swipekit/components/form/form-settings.vue";
export default {
  components: {
    Modal,
    FormSettings,
  },

  data: function () {
    return {
      options: {
        name: "modal-settings",
        active: false,
      },
    };
  },

  watch: {
    "options.active": {
      handler: function () {},
    },
    showSettings: {
      handler: function () {
        if (this.showSettings) {
          this.options.active = true;
        } else {
          this.options.active = false;
        }
      },
      immediate: false,
    },
  },

  computed: {
    showSettings: function () {
      return this.$store.getters["app/showSettings"];
    },
  },

  methods: {
    onSubmit: function () {
      this.$store.dispatch("app/toggleSettings", false);
    },
    onClose: function () {
      this.$store.dispatch("app/toggleSettings", false);
    },
    onLogout: function () {
      this.$store.dispatch("users/logout");
    },
  },

  mounted: function () {
    //this.options.active = true;
  },
};
</script>

<style lang="scss"></style>
