<template>
  <form class="c-form-email-update" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Update Email"
      :processing="processing"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  email: {
    component: "InputEmail",
    label: "Email",
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
      minLength: {
        params: 4,
        message: "Use a proper email",
      },
      maxLength: {
        params: 48,
        message: `Whoa, that's a loong email. Use another one?`,
      },
    },
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: schema,
      processing: false,
      form: {
        email: "",
      },
      status: null,
      errors: [],
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    getUser: function () {
      return this.$store.getters["users/user"];
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      form = {
        id: form.id,
        email: form.email,
      };

      let response = null;
      try {
        response = await this.$store.dispatch("users/update", form);
      } catch (err) {
        this.errors = err;
      }
      this.processing = false;
      if (!response) {
        return;
      }
      this.errors = [];
      await this.$store.dispatch("message/onUserUpdate");
      this.$emit("onSubmit");
    },
  },

  created: function () {
    this.form = this.getUser();
  },
};
</script>

<style lang="scss">
.c-form-email-update {
  margin-bottom: var(--spacer);
}
</style>
