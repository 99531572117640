<template>
  <div class="c-ad-link">
    <a class="c-ad-link__link" :href="computedLink" target="_blank"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9 17H7A5 5 0 0 1 7 7h2"></path>
        <path d="M15 7h2a5 5 0 1 1 0 10h-2"></path>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
      View in the Ad Library
    </a>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
  },

  computed: {
    computedLink: function () {
      let str = `https://www.facebook.com/ads/library/?id=${this.block.metaId}`;
      let meta = this.block.meta;
      if (meta.pageId) {
        str = `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${meta.pageId}&search_type=page&media_type=all`;
      }
      return str;
    },
  },
};
</script>

<style lang="scss">
.c-ad-link {
  margin-right: var(--margin-lg);
  margin-top: var(--margin);
  font-size: var(--font-size-xs);
  font-weight: 500;

  a {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--color-font);
    padding: var(--margin-sm) var(--margin);
    border: var(--color-bg-4) solid 1px;
    border-radius: var(--border-radius);

    &:hover,
    &:active {
      color: var(--color-primary);
    }
  }

  svg {
    margin-right: var(--margin);
  }
}
</style>
