<template>
  <div class="c-utils-tags">
    <span> Tags </span>
    <div class="c-utils-tags__container">
      <span class="c-utils-tags__label" v-for="cat in tags" :key="cat">
        {{ cat }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ad: {},
  },

  computed: {
    tags: function () {
      let tags = [];

      tags = this.ad.tags || [];

      if (!tags.map) {
        tags = [];
      }

      tags = tags.map((cat) => {
        if (typeof cat !== "string") {
          return cat.text || "";
        }
        return cat;
      });

      return tags;
    },
  },
};
</script>

<style lang="scss">
.c-utils-tags {
  display: grid;
  grid-template-columns: 40px 1.25fr;
  margin-top: var(--margin);

  > span {
    margin-top: var(--margin-sm);
    display: inline-block;
    font-size: var(--font-size-xs);
    font-weight: 500;
    color: var(--color-font);
  }

  &__container {
  }

  &__label {
    display: inline-block;
    margin-bottom: var(--margin-sm);
    padding: var(--margin-sm) var(--margin);
    border: var(--color-bg-4) solid 1px;
    border-radius: var(--border-radius);
    font-size: var(--font-size-xs);
    color: var(--color-font);
    margin-right: var(--margin-sm);
  }
}
</style>
