<template>
  <div class="c-link-explainer">
    <strong>Ad CTA link flow</strong>

    <!-- Stage 1 -->
    <h6>
      <label>1</label>
      Source
    </h6>
    <article class="article-text-sm">
      {{ sourceText }}
      <br />
      {{ ad.block.meta.cta }} CTA button
    </article>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <polyline points="19 12 12 19 5 12"></polyline>
    </svg>

    <!-- Stage 2 -->
    <template>
      <h6><label>2</label>Destination</h6>

      <article class="article-text-sm">
        {{ ctaLink }}
      </article>

      <a class="btn btn-box btn-icon" v-if="ctaLink" :href="ctaLink" target="_blank">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.2893 18.7484L11.5786 19.4816C9.61616 21.5061 6.43435 21.5061 4.47186 19.4816C2.50938 17.457 2.50938 14.1745 4.47186 12.1499L6.16656 10.4016C7.97808 8.53279 10.9151 8.53279 12.7267 10.4016L13 10.6836M11.7107 5.25159L12.4214 4.51843C14.3838 2.49386 17.5657 2.49386 19.5281 4.51843C21.4906 6.543 21.4906 9.82549 19.5281 11.8501L17.8334 13.5984C16.0219 15.4672 13.0849 15.4672 11.2733 13.5984L11 13.3164"
            stroke="#2F384C"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span>Visit landing page</span>
      </a>

      <!-- <div class="c-link-explainer__screenshots">
        <a :href="`${assetUrl}/${ad.block.screenshotBlock.assets[0].assetPath}`" target="_blank">
          <span>Desktop Screenshot</span>
          <img v-if="ad.block.screenshotBlock" :src="`${assetUrl}/${ad.block.screenshotBlock.assets[0].assetPath}`" />
        </a>
        <a :href="`${assetUrl}/${ad.block.screenshotBlock.assets[1].assetPath}`" target="_blank">
          <span> Mobile Screenshot </span>
          <img v-if="ad.block.screenshotBlock" :src="`${assetUrl}/${ad.block.screenshotBlock.assets[1].assetPath}`" />
        </a>
      </div> -->
    </template>
  </div>
</template>

<script>
export default {
  props: {
    ad: {},
  },

  computed: {
    ctaLink: function () {
      return this.ad.block.ctaLink;
    },
    hasScreenshots: function () {
      if (!this.ad.block.screenshotBlock) {
        return false;
      }
      return true;
    },
    sourceText: function () {
      let type = this.ad.block.type;
      let suffix = ``;

      if (type === "facebook") {
        type = "Meta";
        let networks = this.ad.block.networks;
        networks = networks.join(", ");

        suffix = `, shown on ${networks}.`;
      }

      if (type === "linkedin") {
        type = "Linkedin";
      }

      let str = `${type} Ad${suffix}`;

      return str;
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
  },
};
</script>

<style lang="scss">
.c-link-explainer {
  > strong {
    display: inline-block;
    opacity: 0.8;
    font-size: var(--font-size-sm);
    padding-right: var(--margin);
    margin-bottom: var(--margin-lg);
  }

  > svg {
    margin-top: var(--margin);
    margin-bottom: var(--margin);
  }

  h6 {
    margin-top: 0 !important;
    margin-bottom: 0;

    svg {
      margin: 0 !important;
    }
  }

  .btn {
    margin-top: var(--margin-sm);
    > svg {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  label {
    margin-right: var(--margin);
    margin-bottom: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: hsla(var(--p), 70%, 70%, 0.15);
    border-radius: var(--border-radius-full);
  }

  &__screenshots {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    margin-top: var(--spacer-sm);

    img {
      width: 180px;
      height: auto;
      border-radius: var(--border-radius);
      border: var(--color-bg-1) solid 3px;
      box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.02), 0px 12px 12px rgba(0, 0, 0, 0.04);
    }

    > a {
      position: relative;

      span {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        white-space: nowrap;
        padding: var(--margin-sm) var(--margin-lg);
        background-color: var(--color-bg-1);
        font-weight: 500;
        font-size: var(--font-size-xs);
        border-radius: var(--border-radius-full);
        color: var(--color-font);
      }

      &:last-child {
        img {
          width: 140px;
        }
      }
    }
  }
}
</style>
