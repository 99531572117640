<template>
  <div :class="['r-google']">
    <!-- <video
      v-if="firstAsset && assetType === 'youtube'"
      mediatype="video"
      controls
      :src="`${assetUrl}/${firstAsset.assetPath}`"
    ></video> -->

    <img v-if="firstAsset && firstAsset.type === 'image'" :src="`${assetUrl}/${firstAsset.assetPath}`" />

    <lite-youtube
      v-if="firstAsset && firstAsset.id && firstAsset.type === 'youtube'"
      :videoid="firstAsset.id"
    ></lite-youtube>

    <!-- <iframe
      
      :src="`https://www.youtube.com/embed/${firstAsset.id}`"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe> -->
  </div>
</template>

<script>
export default {
  props: {
    block: {},
    hideDownload: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    baseUrl: function () {
      return this.$store.getters["config/baseUrl"];
    },
    firstAsset: function () {
      let block = this.block;

      let asset = block.assets[0];

      return asset;
    },
    // Guesses what kind of asset overall this block is about. Does a good enough job for now
    assetType: function () {
      if (this.block.type === "google") {
        if (this.block.assetType) {
          return this.block.assetType;
        } else {
          return this.firstAsset.type;
        }
      }
      return this.firstAsset.type;
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
  },

  methods: {
    downloadUrl: function (fileName) {
      let baseUrl = this.baseUrl;

      let str = `${baseUrl}/api/download/${fileName}`;
    },
    onDownload: function (fileName) {
      let baseUrl = this.baseUrl;

      let str = `${baseUrl}/api/download/${fileName}`;

      this.$emit("onDownload", str, fileName);
    },
  },
};
</script>

<style lang="scss">
.r-google {
  width: 100%;
  height: 100%;
  position: relative;

  img,
  video,
  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }

  .VueCarousel {
    width: 100%;
    height: 100%;
    display: block;

    &-wrapper {
      height: 100%;
      visibility: visible !important;
    }

    &-inner {
      height: 100% !important;
    }

    &-slide {
      width: 100%;
      position: relative;

      img {
        display: initial;
        width: 100%;
        height: auto;
      }
    }

    .VueCarousel-navigation button {
      position: absolute;
      top: calc(50% - 24px);
      left: 24px;
      right: auto;
      width: 24px;
      height: 24px;
      font-size: var(--font-size-xs);
      padding: var(--margin) !important;
      background-color: var(--color-bg-3);
      background-color: rgba(255, 255, 255, 0.5);
      transition: all var(--transition-time) linear;

      &:hover,
      &:active {
        background-color: var(--color-bg-4);
      }

      &--disabled {
        opacity: 0;
      }

      &.VueCarousel-navigation-next {
        left: auto;
        right: 24px;
      }
    }
  }
}
</style>
