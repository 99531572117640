<template>
  <div class="r-webpage">
    <img :src="`${assetUrl}/${screenshot.name || screenshot.assetPath}`" />
  </div>
</template>

<script>
export default {
  props: {
    block: {},
    single: {},
  },

  computed: {
    screenshot: function () {
      if (!this.block.assets) {
        return {};
      }
      return this.block.assets[0] || {};
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
  },

  methods: {},
};
</script>

<style lang="scss">
.r-webpage {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
