import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import { VueMasonryPlugin } from "vue-masonry";
import store from "@/store";
import VueCarousel from "vue-carousel";
import * as Sentry from "@sentry/vue";
import VTooltip from "v-tooltip";

import VueTagsInput from "@johmun/vue-tags-input";
import VueFinalModal from "vue-final-modal/lib";
import VCalendar from "v-calendar";
import vSelect from "vue-select";

// Make sure that pageviews are captured with each route change
router.afterEach((to) => {
  Vue.nextTick(() => {
    /* Note: this might also be a good place to call posthog.register(...) in order to update your properties
    on each page view
    */
    if (window.posthog) {
      posthog.capture("$pageview", {
        $current_url: to.fullPath,
      });
    }
  });
});

Vue.component("v-select", vSelect);
Vue.use(VCalendar, {
  componentPrefix: "vc",
});
Vue.use(VueFinalModal);
Vue.use(VueTagsInput);
Vue.use(VTooltip);
Vue.use(VueCarousel);

Vue.config.productionTip = false;

Vue.use(VueMasonryPlugin);

router.beforeEach(async (to, from, next) => {
  let isDeactivated = store.getters["users/isTrialOver"];

  let validPages = ["Billing Portal", "Profile", "Workspace Settings", "Help"];

  let toPageName = to.name;

  /**
   * If user's status is deactivated and they are trying to access a page
   * Then check if the page name matched one of the above. If yes, then give access. Otherwise don't
   * We have this in place to force users to pay up by redirecting to billing when they are in a deactivated state
   */
  if (isDeactivated && !validPages.includes(toPageName)) {
    next("/profile/billing");
  } else {
    next();
  }
});

// if (process.env.NODE_ENV !== "development") {
//   Sentry.init({
//     Vue,
//     dsn: "https://89873dd065634647a78c95f4db272c9a@o212710.ingest.sentry.io/4503973317902336",
//     integrations: [],
//     logErrors: true,
//   });
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
