<template>
  <div class="c-utils-categories">
    <span class="c-utils-categories__label" v-for="cat in categories" :key="cat">
      {{ cat }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
  },

  computed: {
    categories: function () {
      let categories = [];

      categories = this.block.categories || [];

      categories = categories.map((cat) => {
        if (typeof cat !== "string") {
          return cat.text || "";
        }
        return cat;
      });

      return categories;
    },
  },
};
</script>

<style lang="scss">
.c-utils-categories {
  &__label {
    display: inline-block;
    padding: var(--margin) var(--margin-lg);
    background-color: var(--color-bg-3);
    border-radius: var(--border-radius);
    font-size: var(--font-size-xs);
    margin-right: var(--margin-sm);
  }
}
</style>
