<template>
  <form class="c-form-login" @submit.prevent="onSubmit">
    <Form
      :processing="processing"
      ref="form"
      :schema="schema"
      v-model="form"
      buttonText="Login"
      @status="saveStatus"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import schema from "./schema-login.js";
import Form from "./index.vue";
export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: schema,
      processing: false,
      form: {
        email: "",
        password: "",
      },
      errors: [],
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      let form = this.form;

      this.processing = true;

      let res = await this.$store.dispatch("users/login", form).catch((err) => {
        if (err.length === 0) {
          err.push({
            message: "User not found",
          });
        }
        this.errors = err;
      });

      this.processing = false;

      if (res) {
        await this.$store.dispatch("message/onLogin");
        let pie = await this.$store.dispatch("users/getPie");
        await this.$store.dispatch("afterLogin", pie);
        this.$emit("onSubmit");
      }
    },
  },
};
</script>

<style></style>
