<template>
  <form class="c-form-edit-tags" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Bulk update"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  tags: {
    component: "InputTags",
    label: "Tags",
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      schema: schema,
      form: {
        tags: [],
        boards: [],
      },
      status: null,
    };
  },

  computed: {
    selection: function () {
      return this.$store.getters["ads/selection"];
    },
  },

  methods: {
    reset: function () {
      this.form = {
        tags: [],
        boards: [],
        url: [],
      };
    },
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = {
        ...this.form,
        selection: this.selection,
      };

      let obj = {
        id: 1,
        action: "bulk_update",
        form: form,
      };

      let res = await this.$store.dispatch("ads/doAction", obj);

      setTimeout(() => {
        this.reset();
      }, 500);

      await this.$store.dispatch("message/onBulkUpdate");

      this.$emit("onSubmit");

      await this.$store.dispatch("ads/clearSelection");

      this.processing = false;
    },
  },
};
</script>

<style lang="scss">
.c-form-edit-tags {
  min-width: 100%;

  .c-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__item {
      width: 100%;
    }

    .c-input-tags {
      .vue-tags-input {
        max-width: initial;
      }
    }
  }
}
</style>
