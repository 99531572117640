<template>
  <div class="c-onboarding2-ad-library">
    <Page :i="6">
      <!-- <span class="c-byline"> Tutorial #5: Boards </span> -->
      <h1>Welcome to your Ad library</h1>

      <div clcass="c-onboarding2-ad-library__grid"></div>
      <Showcase img="/images/onboarding/swipekit-library.jpg"></Showcase>
      <article class="article-text">
        <p>The library is where all your saved Ads will appear.</p>
        <p>It is very common to have thousands of Ads. Use the filters to:</p>
      </article>
      <ul class="fancy-list">
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
              fill="currentColor"
            ></path>
          </svg>
          <p>Use the sorting options to find winning Ads.</p>
        </li>
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
              fill="currentColor"
            ></path>
          </svg>
          <p>
            Use the asset type, platforms, and niche filters to drill down in your Ad type and find creative inspo
            quickly.
          </p>
        </li>
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
              fill="currentColor"
            ></path>
          </svg>
          <p>Change display density of the Ad card.</p>
        </li>
      </ul>
      <ButtonCta @onClick="$emit('onNext')">
        <span> Continue </span>
      </ButtonCta>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
import Item from "@swipekit/components/item";
import SearchBar from "@swipekit/components/utils/search-bar.vue";
import Showcase from "./showcase";

export default {
  components: {
    Page,
    ButtonCta,
    Item,
    SearchBar,
    Showcase,
  },

  props: {
    tempAd: {},
  },

  computed: {
    computedAd: function () {
      if (!this.tempAd) {
        return;
      }
      return this.tempAd;
    },
  },

  methods: {
    onClick: function (e) {
      if (e && !e.target) {
        this.$store.dispatch("message/sendMessage", {
          message: "Board created",
          status: "success",
        });
      }
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss">
.c-onboarding2-ad-library {
  .article-text {
    margin-top: var(--margin-lg);
  }
}
</style>
