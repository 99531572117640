<template>
  <div class="c-onboarding2-cta-button">
    <a :class="['btn btn-lg btn-primary', { disabled: disabled === true }]" @click.prevent="onNext">
      <div class="c-onboarding2-cta-button__screen" ref="screen"></div>
      <slot> <span>Next</span></slot>
    </a>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  data: function () {
    return {
      interval: null,
      disabled: false,
    };
  },

  props: {
    timer: {
      type: Number,
      default: 0,
    },
  },

  computed: {},

  methods: {
    onNext: function (e) {
      if (this.disabled) {
        return;
      }
      this.$emit("onClick", e);
    },
    startTimer: function () {
      if (!this.timer) {
        this.disabled = false;
        return;
      } else {
        this.disabled = true;
      }

      const el = this.$refs.screen;

      anime({
        targets: el,
        width: [`0%`, `100%`],
        duration: this.timer * 1000,
        easing: "linear",
        complete: () => {
          this.disabled = false;
          anime({
            targets: el,
            opacity: 0,
            duration: 120,
            easing: "linear",
          });
        },
      });
    },
  },

  mounted: function () {
    this.startTimer();
  },
};
</script>

<style lang="scss">
.c-onboarding2-cta-button {
  padding: var(--spacer-sm) var(--spacer);

  > .btn {
    position: relative;
    width: 100%;
    border-radius: 8px;
    margin: 0;
    overflow: hidden;

    &.disabled {
      span {
        opacity: 0.75;
      }
    }
  }

  &__screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);

    transition: all 60ms ease-out;
  }
}
</style>
