<template>
  <component
    :is="tag"
    rel="noreferrer"
    :href="block.meta.ctaLink"
    target="_blank"
    class="c-cta"
    :title="block.meta.ctaLink"
  >
    {{ text }}
  </component>
</template>

<script>
export default {
  props: {
    block: {},
    link: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tag: function () {
      if (this.link) {
        return "a";
      } else {
        return "span";
      }
    },
    text: function () {
      let cta = this.block.meta.cta;

      return cta;

      let map = [
        {
          label: "Send message",
          value: "MESSAGE_PAGE",
        },
        {
          label: "Visit Instagram profile",
          value: "VIEW_INSTAGRAM_PROFILE",
        },
        {
          label: "Shop Now",
          value: "SHOP_NOW",
        },
        {
          label: "Learn more",
          value: "LEARN_MORE",
        },
        {
          label: "Install Now",
          value: "INSTALL_MOBILE_APP",
        },
        {
          label: "Book Now",
          value: "BOOK_TRAVEL",
        },
      ];

      for (let i = 0; i < map.length; i++) {
        if (map[i].value === cta) {
          cta = map[i].label;
          break;
        }
      }

      return cta;
    },
  },
};
</script>

<style lang="scss">
.c-cta {
  display: inline-block;
  padding: var(--margin) var(--margin-lg);
  font-size: var(--font-size-xs);
  background-color: var(--color-bg-4);
  border-radius: 5px;
  color: var(--color-font);
  text-align: left;
}
</style>
