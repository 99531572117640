<template>
  <div class="c-page-intro">
    <Page :i="1">
      <h1>
        Welcome to<br />
        Swipekit, {{ user.name }}
      </h1>
      <article>
        <ul class="fancy-list">
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              ></path>
            </svg>
            <p>Swipekit helps manage your creative worksflow</p>
            <p>Store Ads from one place. Share Ads with creators, editors and others.</p>
            <p>Use boards to organize Ads. Embed Boards and Ads inside Notion, Clickup, etc.</p>
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              ></path>
            </svg>
            <p>Use Swipekit for uncovering your competitor's strategy.</p>
            <p>Figure our</p>
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              ></path>
            </svg>
            <p>Build a collection of winning Ads. Use boards to share these Ads publicly.</p>
          </li>
        </ul>
      </article>
      <ButtonCta :timer="3" @onClick="onClick"></ButtonCta>
      <p class="small center">We'll show you how to use Swipekit over the next few pages.</p>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
export default {
  components: {
    Page,
    ButtonCta,
  },

  computed: {
    user: function () {
      return this.$store.getters["users/user"];
    },
  },

  methods: {
    onClick: function () {
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss"></style>
