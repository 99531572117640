<template>
  <div class="c-item-date" v-if="block.startDate">
    <div class="c-item-date__inner">
      <span title="Ad start date">{{ computedDates.startDate }}</span>
      <span title="Difference in days">({{ dateDifference }} days)</span>
    </div>
  </div>
</template>

<script>
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";

export default {
  props: {
    block: {},
  },

  computed: {
    computedDates: function () {
      let startDate = this.block.startDate;

      let endDate = new Date().toISOString();

      let token = "dd/MM/yy";

      if (startDate) {
        startDate = format(parseISO(startDate), token);
      }

      if (endDate) {
        endDate = format(parseISO(endDate), token);
      }

      return {
        startDate,
        endDate,
      };
    },
    dateDifference: function () {
      let startDate = this.block.startDate;

      let endDate = new Date().toISOString();
      if (startDate && endDate) {
        return differenceInDays(parseISO(endDate), parseISO(startDate));
      }
    },
  },
};
</script>

<style lang="scss">
.c-item-date {
  padding-top: var(--margin-lg);
  &__inner {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-sm);
  }
}
</style>
