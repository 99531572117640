<template>
  <div class="c-uploads-page" @scroll="onScroll">
    <h1>Upload Media</h1>
  </div>
</template>

<script>
export default {
  components: {},

  computed: {
    standalone: function () {
      return this.$store.getters["standalone"];
    },
  },

  methods: {},
};
</script>

<style lang="scss">
.c-uploads-page {
  height: 100vh;
  position: relative;
  overflow-y: auto;
}
</style>
