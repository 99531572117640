<template>
  <div
    ref="item"
    :class="[
      'c-landing-pages-item',
      { selected: isSelected === true, selectable: selection.length > 0, multiplayer: multiplayer === true },
    ]"
  >
    <div class="c-landing-pages-item__wrapper">
      <div class="c-landing-pages-item__inner">
        <div class="c-landing-pages-item__clickable">
          <span
            v-if="showBulkSelect"
            :class="[
              'c-landing-pages-item__icon c-landing-pages-item__checkbox',
              { visible: selection.length > 0, active: isSelected === true },
            ]"
            v-tooltip="`Select Ad for bulk edit`"
          >
            <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              />
            </svg> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </span>

          <Renderer :multiplayer="multiplayer" :standalone="standalone" :block="ad.block" :single="true"></Renderer>
        </div>
        <div class="c-landing-pages-item__sidebar">
          <HeaderComponent :multiplayer="multiplayer" :block="ad.block" :shared="shared"></HeaderComponent>

          <div class="c-landing-pages-item__buttons">
            <a v-if="ctaLink" class="btn btn-icon" :href="ctaLink" target="_blank">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.7557 11.5739L18.1421 11.1876C19.6358 9.69387 19.6358 7.27214 18.1421 5.77846C16.6484 4.28478 14.2267 4.28478 12.733 5.77846L9.8316 8.67987C8.66003 9.85144 8.66003 11.7509 9.8316 12.9225L10.8012 13.8921M6.16483 12.3467L5.77846 12.7331C4.28478 14.2267 4.28478 16.6485 5.77846 18.1421C7.27214 19.6358 9.69387 19.6358 11.1876 18.1421L14.089 15.2407C15.2605 14.0692 15.2605 12.1697 14.089 10.9981L13.1194 10.0285"
                  stroke="currentColor"
                  stroke-linecap="round"
                />
              </svg>
              Visit
            </a>
            <a v-if="viewInternetArchiveLink" class="btn btn-icon" :href="viewInternetArchiveLink" target="_blank">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.7557 11.5739L18.1421 11.1876C19.6358 9.69387 19.6358 7.27214 18.1421 5.77846C16.6484 4.28478 14.2267 4.28478 12.733 5.77846L9.8316 8.67987C8.66003 9.85144 8.66003 11.7509 9.8316 12.9225L10.8012 13.8921M6.16483 12.3467L5.77846 12.7331C4.28478 14.2267 4.28478 16.6485 5.77846 18.1421C7.27214 19.6358 9.69387 19.6358 11.1876 18.1421L14.089 15.2407C15.2605 14.0692 15.2605 12.1697 14.089 10.9981L13.1194 10.0285"
                  stroke="currentColor"
                  stroke-linecap="round"
                />
              </svg>
              Open in Internet Archive
            </a>
          </div>
          <div class="c-landing-pages-item__data">
            <strong> Saved at </strong>
            <p>{{ savedDate }} ({{ savedDateDistance }} ago)</p>
          </div>
          <div class="c-landing-pages-item__data" v-if="ad.block.brand">
            <strong> Brand </strong>
            <p>{{ ad.block.brand.brand_name }}</p>
          </div>
          <div class="c-landing-pages-item__data" v-if="ctaLink">
            <strong> Landing page link </strong>
            <p>{{ ctaLink }}</p>
          </div>
          <!-- <div class="c-landing-pages-item__data" v-if="ctaLink">
            <strong> Color </strong>
            <p>{{ style["background-color"] }}</p>
          </div> -->
        </div>

        <a v-if="shared" class="c-landing-pages-item__share" href="https://swipekit.app">
          <span> Ad saved by swipekit.app </span>
        </a>

        <!-- {{ block.source }} -->
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./item-header";
// import FooterComponent from "./footer";
// import ImageComponent from "./image";
// import Metadata from "./metadata";

import Renderer from "./renderer";

import parseISO from "date-fns/parseISO";
import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";

// import FormSaveBlock from "@swipekit/components/form/form-save-block.vue";

export default {
  components: {
    HeaderComponent,
    // FooterComponent,
    // ImageComponent,
    // Metadata,
    Renderer,
    // FormSaveBlock,
  },

  props: {
    ad: {},
    standalone: {
      type: Boolean,
      default: false,
    },
    shared: {
      type: Boolean,
      default: false,
    },
    multiplayer: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  computed: {
    style: function () {
      let primaryColor = this.ad.block.screenshotBlock.primaryColor || "#DDD";

      return {
        "background-color": primaryColor,
      };
    },
    savedDate: function () {
      const ad = this.ad;

      const createdAt = this.ad.block.screenshotBlock.createdAt;

      if (!createdAt) {
        return;
      }

      const date = parseISO(createdAt);

      const token = "dd/MM/yy";

      const distance = format(date, token);

      return distance;
    },
    savedDateDistance: function () {
      const ad = this.ad;

      const createdAt = this.ad.block.screenshotBlock.createdAt;

      if (!createdAt) {
        return;
      }

      const date = parseISO(createdAt);

      const distance = formatDistance(date, new Date());

      return distance;
    },
    ctaLink: function () {
      let ctaLink = this.ad.block.ctaLink;

      if (!ctaLink) {
        ctaLink = this.ad.block.meta.ctaLink;
      }
      if (!ctaLink) {
        return;
      }

      if (!ctaLink.includes("http")) {
        ctaLink = `https://${ctaLink}`;
      }
      return ctaLink;
    },
    viewInternetArchiveLink: function () {
      let link = this.ctaLink;
      if (!link) {
        return;
      }
      return `https://web.archive.org/web/${link}`;
    },
    showBulkSelect: function () {
      if (this.multiplayer) {
        return false;
      }

      if (this.shared) {
        return false;
      }

      if (this.standalone) {
        return false;
      }

      return true;
    },
    isAdmin: function () {
      return this.$store.getters["users/isAdmin"];
    },
    options: function () {
      return this.$store.getters["app/options"];
    },

    selection: function () {
      return this.$store.getters["ads/selection"];
    },
    isSelected: function () {
      let selection = this.selection;

      if (!this.ad) {
        return;
      }

      if (!this.ad.id) {
        return;
      }

      if (selection.includes(this.ad.id)) {
        return true;
      }
      return false;
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    workspace: function () {
      return this.$store.getters["workspaces/all"];
    },
  },

  methods: {
    onSubmit: function (e) {
      this.$emit("onSubmit", e);
    },
    onGotoComment: function () {
      this.$emit("commentClick", this.ad.id, this.$refs.item);
    },
    downloadVideo: function (url) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement("a");
        tag.href = imageUrl;
        tag.target = "_blank";
        tag.download = "sample.mp4";
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.onerror = (err) => {
        alert("Failed to download picture");
      };
      xhr.send();
    },
    onGotoSource: function () {
      if (this.ad.block.source && this.ad.block.source.url) {
        window.open(this.ad.block.source.url, "_newtab");
      }
    },
    onDownload: function (url, fileName) {
      this.downloadVideo(url);
      this.$store.dispatch("events/sendEvent", {
        name: "download",
      });
    },
    onClick: function (e) {
      let shared = this.shared;
      if (this.selection.length > 0) {
        this.toggleSelection();
        return;
      }

      let download = `.c-renderer__download`;

      let condition = e.target.closest(download);
      if (condition) {
        return;
      }

      let formSave = `.c-form-save-block .btn-primary`;

      condition = e.target.closest(formSave);
      if (condition) {
        return;
      }

      let cta = ".c-cta";

      condition = e.target.closest(cta);
      if (condition) {
        if (this.ad.block.meta.ctaLink) {
          window.open(this.ad.block.meta.ctaLink, "_blank").focus();
          return;
        }
      }

      let carousel = `.VueCarousel`;

      condition = e.target.closest(carousel);

      if (condition) {
        return;
      }

      let checkbox = `.c-landing-pages-item__checkbox`;

      condition = e.target.closest(checkbox);
      if (condition) {
        this.toggleSelection();

        return;
      }

      let remove = `.c-landing-pages-item__remove`;

      condition = e.target.closest(remove);
      if (condition) {
        this.removeAd();

        return;
      }

      let link = `.c-ad-link__link`;
      condition = e.target.closest(link);
      if (condition) {
        window.open(e.target.href, "_blank").focus();
        return;
      }

      let comment = `.c-item-footer__comments`;

      condition = e.target.closest(comment);
      if (condition) {
        return;
      }

      let share = `.c-landing-pages-item__share`;

      condition = e.target.closest(share);
      if (condition) {
        window.open("https://swipekit.app", "_blank").focus();
        return;
      }

      let shareAd = `.open-share-ad`;

      condition = e.target.closest(shareAd);
      if (condition) {
        let temp = JSON.parse(JSON.stringify(this.ad));
        this.$store.dispatch("app/setAdShare", temp);
        return;
      }

      e.preventDefault();

      // if (e.target.closest(".c-renderer")) {
      //   return;
      // }
      if (!shared) {
        this.$store.dispatch("ads/setView", this.ad.id);
      }
    },
    toggleSelection: function () {
      let selection = this.selection;
      if (selection.includes(this.ad.id)) {
        this.$store.dispatch("ads/removeSelection", this.ad.id);
      } else {
        this.$store.dispatch("ads/addSelection", this.ad.id);
      }
    },
    removeAd: function () {
      this.$emit("onRemoveAd", this.ad.id);
    },
  },
};
</script>

<style lang="scss">
.c-landing-pages-item {
  width: 100%;
  padding: var(--spacer-sm);
  height: auto;

  &__wrapper {
    position: relative;
  }

  &__buttons {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: var(--margin);
    margin-bottom: var(--spacer-sm);
  }

  &__data {
    font-size: var(--font-size-sm);
    word-wrap: break-word;
    strong {
      font-weight: 500;
    }
  }

  &__sidebar {
    padding-left: var(--margin-lg);

    .btn {
      width: 100%;
      border-radius: 5px;
      justify-content: flex-start;
      font-size: var(--font-size-sm);
    }
  }

  &__inner {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 260px;

    transition: box-shadow var(--transition-time) linear;

    > article {
      border-top: var(--color-bg-3) solid 1px;

      margin-top: var(--margin-lg);
      padding: 0 var(--margin-lg);
      padding-top: var(--margin-lg);
      font-size: var(--font-size-sm);

      white-space: break-spaces;
    }

    // &:hover,
    // &:active {
    //   box-shadow: 0 0 0 3px hsla(var(--p), 100%, 50%, 0.45), 0px 0.3px 0.8px rgba(0, 0, 0, 0.085),
    //     0px 2px 6px rgba(0, 0, 0, 0.09);
    // }
  }

  &.selectable {
    .c-landing-pages-item__inner {
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: hsla(var(--p), 5%, 60%, 0.15);
        transition: background-color var(--transition-time) linear;
      }

      &:hover {
        &:after {
          background-color: hsla(var(--p), 60%, 60%, 0.35);
        }
      }
    }
  }

  &.selected {
    .c-landing-pages-item__inner {
      box-shadow: 0 0 0 3px var(--color-primary), 0px 0.3px 0.8px rgba(0, 0, 0, 0.085), 0px 2px 6px rgba(0, 0, 0, 0.09);

      &:after {
        background-color: hsla(var(--p), 60%, 60%, 0.35);
      }
    }
  }

  &.multiplayer {
    .c-landing-pages-item__inner {
      cursor: initial;
    }
  }

  &__share {
    padding: var(--margin) var(--margin-sm);
    color: var(--color-font);
    opacity: 0.75;
    text-align: center;
    font-weight: 400;
    font-size: var(--font-size-sm);
    text-decoration: none;
  }

  &__save {
    padding: var(--margin-lg);
    padding-top: var(--margin);
  }

  &__clickable {
    position: relative;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(238, 238, 241, 1) 0%, rgba(235, 235, 235, 1) 100%);
    border-radius: 10px;
    height: 75vh;

    transition: box-shadow var(--transition-time) linear;

    .c-renderer {
      width: 100%;
      height: 100%;
      position: absolute;

      .r-facebook .VueCarousel {
        &-slide {
          img {
            display: block;
            width: 100%;
            height: initial;
          }
        }
      }

      &__download {
        opacity: 0;
      }
    }

    .c-item-footer {
      min-height: 48px;
    }

    &:focus,
    &:hover {
      outline: none;

      .c-landing-pages-item__checkbox {
        opacity: 1;
      }

      .c-landing-pages-item__remove {
        opacity: 1;
      }

      .c-renderer {
        &__download {
          opacity: 1;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: var(--margin-lg);
    left: var(--margin-lg);
    width: 32px;
    height: 32px;
    user-select: none;

    border-radius: var(--border-radius-full);
    opacity: 0;
    color: var(--color-button-font);

    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background-color: var(--color-primary);
      border-radius: var(--border-radius-full);
      border: #fff solid 2px;
    }

    svg {
      position: relative;
      z-index: 1;
      width: 60%;
      height: 60%;
      //opacity: 1;
    }

    &.visible {
      opacity: 1;
    }

    &.active {
      color: var(--color-primary);

      &:before {
        background-color: var(--color-button-font);
      }
    }
  }

  &__checkbox {
  }

  &__remove {
    top: calc(var(--margin-lg) * 2 + 32px);
    left: var(--margin-lg);

    &:before {
      background-color: var(--color-danger);
    }

    &.active {
      color: var(--color-danger);
    }
  }

  @include media-breakpoint-down(xs) {
    &__inner {
      display: flex;
      flex-direction: column-reverse;
    }

    &__sidebar {
      padding-left: 0;
    }
  }
}
</style>
