<template>
  <div class="c-sidebar__container">
    <div :class="['c-sidebar', { active: showSidebar }]">
      <svg @click="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967Z"
          fill="currentColor"
        />
      </svg>
      <div class="c-sidebar__header">
        <p>Boards</p>
      </div>
      <div class="c-sidebar__body">
        <BoardSelect></BoardSelect>
      </div>
    </div>
  </div>
</template>

<script>
import FormBulkEdit from "@swipekit/components/form/form-bulk-edit";
import InputButtonChoice from "@swipekit/components/form/input-button-choice";
import BoardSelect from "@swipekit/components/board-select";

export default {
  components: {
    FormBulkEdit,
    InputButtonChoice,
    BoardSelect,
  },

  computed: {
    showSidebar: function () {
      return this.$store.getters["app/showSidebar"];
    },
    selection: function () {
      return this.$store.getters["ads/selection"];
    },
    countString: function () {
      let selection = this.selection;

      let str = `${selection.length} ads`;

      if (selection.length === 1) {
        str = `${selection.length} ad`;
      }

      return str;
    },
    slug: function () {
      return this.$route.params.slug;
    },
    board: function () {
      if (!this.slug) {
        return;
      }
      return this.$store.getters["boards/boardBySlug"](this.slug);
    },
  },

  methods: {
    close: function () {
      this.$store.dispatch("app/toggleSidebar");
    },
  },
};
</script>

<style lang="scss">
.c-sidebar {
  --sidebar-gap: 64px;
  position: fixed;
  top: var(--sidebar-gap);
  right: 0;
  width: 300px;
  height: calc(100% - var(--sidebar-gap) * 2);
  max-height: 800px;
  z-index: 9;

  border-radius: var(--border-radius-lg);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: var(--color-bg-4) solid 1px;
  backdrop-filter: blur(8px);

  box-shadow: 0px 2.2px 5.3px rgba(0, 0, 0, 0.065), 0px 7.4px 17.9px rgba(0, 0, 0, 0.095),
    0px 33px 80px rgba(0, 0, 0, 0.16);

  transition: all var(--transition-time) cubic-bezier(0.215, 0.61, 0.355, 1);

  transform: translateX(4px);

  opacity: 0;
  pointer-events: none;

  &.active {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }

  > svg {
    position: absolute;
    top: var(--margin-lg);
    right: var(--margin-lg);
    cursor: pointer;
  }

  &__header {
    padding: var(--margin-lg) var(--spacer-sm);

    p {
      margin: 0;
    }
  }

  &__body {
    padding: var(--margin-lg);
    padding-top: 0;
    height: calc(100% - 44px);
    overflow: auto;
  }

  @include media-breakpoint-down(sm) {
    right: var(--spacer);
    width: calc(100% - var(--spacer) * 2);
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);
  }
}
</style>
