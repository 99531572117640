<
<template>
  <div class="c-popup-filter">
    <Popup :buttonSelector="buttonSelector" :active="active" :target="target" @onClose="onClose">
      <div class="c-popup-filter__inner">
        <slot></slot>
      </div>
    </Popup>
  </div>
</template>

<script>
import Popup from "./index";

export default {
  components: {
    Popup,
  },

  data: function () {
    return {
      tags: [
        {
          name: "All Ads",
          slug: 0,
        },
        {
          name: "Active",
          slug: 1,
        },
        {
          name: "Inactive",
          slug: 2,
        },
      ],
      selectedActiveAds: 0,
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    target: {},
    buttonSelector: {},
  },

  watch: {
    selectedActiveAds: function () {
      this.$emit("input", this.selectedActiveAds);
    },
  },

  computed: {},

  methods: {
    onClose: function () {
      this.$emit("onClose");
    },
  },

  created: function () {},
};
</script>

<style lang="scss">
.c-popup-filter {
  .c-popup {
    //width: 600px;
    width: 320px;

    background: var(--color-bg-3); //linear-gradient(181deg, var(--color-bg-4) 50%, var(--color-bg-5) 100%);
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.4), inset 0 4px 6px 1px rgba(255, 255, 255, 0.2),
      0 4px 10px 1px rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.05);

    &__arrow {
      display: none;
    }
  }
  article {
    padding: var(--margin) var(--margin-lg);
  }

  &__inner {
    padding: var(--margin);
  }
}
</style>
