<template>
  <form class="c-form-password-update" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Change password"
      :processing="processing"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

let schema = {
  oldPassword: {
    component: "InputPassword",
    label: "Your old password",
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
      minLength: {
        params: 8,
        message: "Password should be at least 8 characters",
      },
      maxLength: {
        params: 40,
        message: `That's a looong password. Make it short ya'll.`,
      },
    },
  },

  newPassword: {
    component: "InputPassword",
    label: "Enter your new password",
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
      minLength: {
        params: 8,
        message: "Password should be at least 8 characters",
      },
      maxLength: {
        params: 40,
        message: `That's a looong password. Make it short ya'll.`,
      },
    },
  },

  newPassword2: {
    component: "InputPassword",
    label: "Enter your new password again",
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
      minLength: {
        params: 8,
        message: "Password should be at least 8 characters",
      },
      maxLength: {
        params: 40,
        message: `That's a looong password. Make it short ya'll.`,
      },
    },
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      errors: [],
      currentForm: {
        id: null,
        oldPassword: "",
        newPassword: "",
        newPassword2: "",
      },
      status: null,
    };
  },

  props: {
    form: {
      type: Object,
      default: function () {
        return {
          id: null,
          oldPassword: "",
          newPassword: "",
          newPassword2: "",
        };
      },
    },
  },

  computed: {
    computedSchema: function () {
      let s = schema;
      let user = this.user;
      if (user.type === "ADMIN") {
      }

      return s;
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    schema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      // form = {
      //   id: form.id,
      //   companyName: form.companyName,
      //   address: form.address,
      //   taxId: form.taxId,
      //   taxType: form.taxType,
      // };

      let response = await this.$store.dispatch("users/updateUserPassword", form).catch((err) => {
        if (err) {
          this.errors = [err];
        } else {
          this.errors = [
            {
              message: "Something went wrong, please try again later.",
            },
          ];
        }
      });

      this.processing = false;

      if (!response) {
        return;
      }

      await this.$store.dispatch("message/onUserPasswordUpdate");

      this.errors = [];

      this.currentForm.oldPassword = "";
      this.currentForm.newPassword = "";
      this.currentForm.newPassword2 = "";

      this.$emit("onSubmit");
    },
  },

  created: function () {
    if (!this.form) {
      return;
    }
    this.currentForm = {
      id: this.form.id,
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
    };
  },
};
</script>

<style></style>
