<template>
  <form class="c-form-workspace-update" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Update billing details"
      :processing="processing"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

let schema = {
  companyName: {
    component: "InputText",
    label: "Your company name",
    description: "Eg: your company name",
    placeholder: "Acme inc",
    validations: {
      minLength: {
        params: 1,
        message: "Give a descriptive name. Minimum 1 characters",
      },
      maxLength: {
        params: 80,
        message: `That's a insanely long company name. Maximum 80 characters`,
      },
    },
  },

  address: {
    component: "InputAddress",
    label: "Billing Address",
  },

  taxType: {
    component: "InputText",
    label: "Tax type, eg: VAT, ABN, etc",
    validations: {
      minLength: {
        params: 1,
        message: "Give a descriptive tax type. Minimum 1 characters",
      },
      maxLength: {
        params: 40,
        message: `That's a insanely long tax type. Maximum 40 characters`,
      },
    },
  },

  taxId: {
    component: "InputText",
    label: "Tax id",
    validations: {
      minLength: {
        params: 1,
        message: "Give a descriptive tax id. Minimum 1 characters",
      },
      maxLength: {
        params: 40,
        message: `That's a insanely long tax id. Maximum 40 characters`,
      },
    },
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      errors: [],
      currentForm: {
        id: null,
        companyName: "",
        address: null,
        taxType: "",
        taxId: "",
      },
      status: null,
    };
  },

  props: {
    form: {
      type: Object,
      default: function () {
        return {
          id: null,
          companyName: "",
          address: null,
          taxType: "",
          taxId: "",
        };
      },
    },
  },

  computed: {
    computedSchema: function () {
      let s = schema;
      let user = this.user;
      if (user.type === "ADMIN") {
      }

      return s;
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    schema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      form = {
        id: form.id,
        companyName: form.companyName,
        address: form.address,
        taxId: form.taxId,
        taxType: form.taxType,
      };

      // let formData = new FormData();

      // formData.append("id", form.id);
      // formData.append("companyName", form.companyName);

      // formData.append("address", JSON.sform.address);

      // formData.forEach((gg) => {
      //   console.log(gg);
      // });

      let response = await this.$store.dispatch("workspaces/update", form).catch((err) => {
        console.log(err.response.data);

        this.errors = err.response.data || [];
      });

      this.processing = false;

      if (!response) {
        return;
      }

      await this.$store.dispatch("message/onUserUpdate");

      this.$emit("onSubmit");
    },
  },

  created: function () {
    if (!this.form) {
      return;
    }
    this.currentForm = {
      id: this.form.id,
      companyName: this.form.companyName,
      address: this.form.address,
      taxType: this.form.taxType,
      taxId: this.form.taxId,
    };
  },
};
</script>

<style></style>
