<template>
  <form class="c-form-workspace-update" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Update workspace"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

let schema = {
  name: {
    component: "InputText",
    label: "Your workspace name. If not sure, put your company name in",
    description: "Eg: your company name",
    validations: {
      required: {
        params: null,
        message: "A name is required",
      },
      minLength: {
        params: 4,
        message: "Give a descriptive name. Minimum 4 characters",
      },
      maxLength: {
        params: 54,
        message: `That's a insanely long name. Maximum 54 characters`,
      },
    },
  },
  logo: {
    component: "InputUpload",
    label: "Logo",
    validations: {},
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      currentForm: {
        id: null,
        name: "",
        logo: "",
      },
      status: null,
    };
  },

  props: {
    form: {
      type: Object,
      default: function () {
        return {
          id: null,
          name: "",
          logo: "",
        };
      },
    },
  },

  computed: {
    computedSchema: function () {
      let s = schema;
      let user = this.user;
      if (user.type === "ADMIN") {
      }

      return s;
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    schema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      form = {
        id: form.id,
        name: form.name,
        logo: form.logo,
      };

      let formData = new FormData();

      formData.append("id", form.id);
      formData.append("name", form.name);
      if (form.logo) {
        formData.append("logo", form.logo);
      }

      formData.forEach((gg) => {
        //console.log(gg);
      });

      let response = await this.$store.dispatch("workspaces/update", formData).catch((err) => {
        console.log(err);
      });

      this.processing = false;

      if (!response) {
        return;
      }

      await this.$store.dispatch("message/onUserUpdate");

      this.$emit("onSubmit");
    },
  },

  created: function () {
    if (!this.form) {
      return;
    }
    this.currentForm = {
      id: this.form.id,
      name: this.form.name,
    };
    if (this.form.logo && this.form.logo.name) {
      this.currentForm.logo = this.form.logo.name;
    }
  },
};
</script>

<style></style>
