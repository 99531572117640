<template>
  <form class="c-form-settings" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :errors="errors"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Update Settings"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  name: {
    component: "InputText",
    label: "First name",
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
      minLength: {
        params: 2,
        message: "Your name can't be that short, surely?",
      },
      maxLength: {
        params: 40,
        message: `Whoa, that's a long name. Use the name your friends call you by?`,
      },
    },
  },
  // lastName: {
  //   component: "InputText",
  //   label: "Last name",
  //   validations: {
  //     required: {
  //       params: null,
  //       message: "This field is required",
  //     },
  //     minLength: {
  //       params: 1,
  //       message: "Your last name can't be that short, surely?",
  //     },
  //     maxLength: {
  //       params: 40,
  //       message: `Whoa, that's a long name. Use the name your friends call you by?`,
  //     },
  //   },
  // },
  avatar: {
    component: "InputUpload",
    label: "Avatar",
    validations: {},
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      errors: [],
      schema: schema,
      processing: false,
      form: {
        id: null,
        name: "",
        lastName: "",
      },
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    getUser: function () {
      return this.$store.getters["users/user"];
    },
    async onSubmit() {
      this.errors = [];

      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      form = {
        id: form.id,
        name: form.name,
        lastName: form.lastName,
        avatar: form.avatar,
      };

      let formData = new FormData();

      formData.append("id", form.id);
      formData.append("name", form.name);
      //formData.append("lastName", form.lastName);
      if (form.avatar) {
        formData.append("avatar", form.avatar);
      }

      let response = null;
      try {
        response = await this.$store.dispatch("users/update", formData);
      } catch (err) {
        if (typeof err === "object" && err.length) {
          this.errors = err;
        }
        this.processing = false;
        return;
      }
      this.processing = false;
      await this.$store.dispatch("message/onUserUpdate");
      this.$emit("onSubmit");
    },
  },

  created: function () {
    let user = this.getUser();

    let meta = user.meta || {};

    this.form = {
      id: user.id,
      name: user.name,
      lastName: meta.lastName || "",
    };

    if (user.avatar && user.avatar.name) {
      this.form.avatar = user.avatar.name;
    }
  },
};
</script>

<style lang="scss">
.c-form-settings {
  margin-bottom: var(--spacer);
}
</style>
