<template>
  <div class="c-multiplayer">
    <PageHeader :byline="byline" :showMenu="true">
      <template v-slot:title> {{ tagline }} </template>
      <template v-slot:menu>
        <router-link :class="[{ active: fullCleanPath === '/multiplayer' }]" to="/multiplayer">
          <h6>Multiplayer</h6>
        </router-link>
        <router-link
          :class="[{ active: fullCleanPath === '/multiplayer/landing-pages' }]"
          to="/multiplayer/landing-pages"
        >
          <h6>Landing pages</h6>
        </router-link>
        <!-- <a href="#">
          <h6>Brands info</h6>
          <p>View brand data</p>
        </a> -->
      </template>
    </PageHeader>
    <router-view> </router-view>
    <LibraryList v-if="init && showMultiplayer" ref="list" :adIds="adIds" @onSubmit="onSave"></LibraryList>
  </div>
</template>

<script>
import PageHeader from "@swipekit/components/utils/page-header";
import LibraryList from "./list";
import api from "@swipekit/lib/api";

export default {
  name: "Multiplayer",

  components: {
    PageHeader,
    LibraryList,
  },

  data: function () {
    return {
      init: false,
      adIds: [],
    };
  },

  props: {
    standalone: {
      type: Boolean,
      default: false,
    },
  },

  // watch: {
  //   $route: function () {
  //     console.log(this.$route.fullPath);
  //   },
  // },

  computed: {
    byline: function () {
      let currentPage = this.currentPage;

      if (currentPage === "multiplayer") {
        return `Multiplayer`;
      }
      if (currentPage === "landing-pages") {
        return `Multiplayer / Landing Pages`;
      }
    },
    tagline: function () {
      let currentPage = this.currentPage;

      if (currentPage === "multiplayer") {
        return `Browse over 300,000 Ads`;
      }
      if (currentPage === "landing-pages") {
        return `Browse Landing page inspo`;
      }
    },
    fullCleanPath: function () {
      let path = this.$route.fullPath;

      path = path.split("?")[0];

      return path;
    },
    showMultiplayer: function () {
      let route = this.$route;

      if (route.path === "/multiplayer") {
        return true;
      }

      return false;
    },
    currentPage: function () {
      let route = this.$route;

      if (route.path === "/multiplayer") {
        return "multiplayer";
      }
      if (route.path === "/multiplayer/landing-pages") {
        return "landing-pages";
      }
      return "";
    },
  },

  methods: {
    onSave: function (e) {
      const blockId = e.blockId;

      //this.$store.dispatch("ads/refreshAll");

      this.$store.dispatch("boards/refreshAll");

      this.adIds.push(blockId);
    },
    onEnd: function () {
      console.log("onEnd");
      if (!this.$refs.list) {
      } else {
        this.$refs.list.onEnd();
      }
    },
    async getIds() {
      let ids = await api.ads.getAdIds();

      this.adIds = ids;

      this.init = true;
    },
  },

  mounted: function () {
    this.getIds();
  },
};
</script>

<style lang="scss">
.c-multiplayer {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 320px;
  }
}
</style>
