<template>
  <form class="c-form-board-create" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Create Board"
      buttonClass="btn-lg"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  name: {
    component: "InputText",
    label: "Board name",
    description: "Eg: Best hooks, Best of brand, client inspo, etc, client-name x campaign-name, etc.",
    placeholder: "Give a descriptive board name",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "A board name is required",
      },
      minLength: {
        params: 4,
        message: "Give a descriptive name to your board. Minimum 4 characters",
      },
      maxLength: {
        params: 54,
        message: `That's a insanely long board name. Maximum 54 characters`,
      },
    },
  },
};

export default {
  components: {
    Form,
  },

  props: {
    name: {},
  },

  data: function () {
    return {
      processing: false,
      schema: schema,
      form: {
        name: "",
      },
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      let res = await this.$store.dispatch("boards/add", form);
      this.$emit("onSubmit", res);

      this.processing = false;

      this.$nextTick(() => {
        this.reset();
      });
    },
    reset: function () {
      this.form = {};
    },
  },

  mounted: function () {
    if (this.name) {
      this.form.name = this.name;
    }
  },
};
</script>

<style></style>
