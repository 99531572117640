<template>
  <div :class="['c-item-avatar', { small: size === 'small' }]">
    <img class="c-item-avatar__logo" :src="primaryImage" v-if="primaryImage" />
    <img class="c-item-avatar__secondary-logo" v-if="showSecondary && secondaryImage" :src="secondaryImage" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
    size: {
      type: String,
      default: "",
    },
    showSecondary: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    primaryImage: function () {
      let block = this.block;
      let assetUrl = this.assetUrl;
      if (block.brand && block.brand.logo) {
        return `${assetUrl}/${block.brand.logo}`;
      }
      if (block.type === "facebook" && block.meta.advertiser && block.meta.advertiser.avatar) {
        return `${assetUrl}/${block.meta.advertiser.avatar.name}`;
      }
      if (block.type === "tiktok-top-ads") {
        return "/brands/tiktok.png";
      }
      if (block.type === "tiktok" && block.meta.advertiser && block.meta.advertiser.avatar) {
        return `${assetUrl}/${block.meta.advertiser.avatar.name}`;
      }
      if (block.type === "linkedin" && block.meta.advertiser && block.meta.advertiser.avatar) {
        return `${assetUrl}/${block.meta.advertiser.avatar.name}`;
      }
      if (block.author && block.author.assetPath) {
        return `${assetUrl}/${block.author.assetPath}`;
      }
      if (block.source && block.source.assetPath) {
        return `${assetUrl}/${block.source.assetPath}`;
      }

      return null;
    },
    secondaryImage: function () {
      if (this.block.type === "facebook") {
        return "/brands/facebook.png";
      } else if (this.block.type === "linkedin") {
        return "/brands/linkedin.png";
      } else if (this.block.type === "tiktok") {
        return "/brands/tiktok.png";
      } else if (this.block.type === "pinterest") {
        return "/brands/pinterest.svg";
      }
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
  },

  methods: {},
};
</script>

<style lang="scss">
.c-item-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 44px;
  width: 44px;
  height: 44px;
  border-radius: var(--border-radius-full);
  color: var(--color-font);

  &:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: var(--color-font);
    border-radius: var(--border-radius-full);
  }

  &.small {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }

  &__logo {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: contain;
    border-radius: 50%;
  }

  &__secondary-logo {
    position: absolute;
    z-index: 1;
    top: -3px;
    right: -3px;

    width: 50%;
    height: 50%;
    object-position: center center;
    object-fit: contain;
    border-radius: 50%;
    background-color: var(--color-button-font);
    border: var(--color-button-font) solid 1px;
  }
}
</style>
