<template>
  <div class="c-modal-board-update">
    <Modal v-model="options.active" @input="closeModal" :closable="true">
      <h2>Share Board</h2>
      <p>You can share boards publicly.</p>
      <p v-if="board">Share {{ board.name }} board</p>
      <FormShare v-if="shareId" :id="shareId" @onSubmit="onSubmit"></FormShare>
      <hr />
      <p>Copy publicly shareable link</p>
      <InputCopy></InputCopy>
    </Modal>
  </div>
</template>

<script>
import Modal from "./index.vue";
import FormShare from "@swipekit/components/form/form-share.vue";
import InputCopy from "@swipekit/components/form/input-copy";

export default {
  components: {
    Modal,
    FormShare,
    InputCopy,
  },

  data: function () {
    return {
      options: {
        name: "modal-share",
        active: false,
      },
    };
  },

  watch: {
    "options.active": {
      handler: function () {},
    },
    shareId: {
      handler: function () {
        if (this.shareId) {
          this.options.active = true;
        } else {
          this.options.active = false;
        }
      },
      immediate: true,
    },
  },

  computed: {
    board: function () {
      if (this.shareId) {
        return this.$store.getters["boards/one"](this.shareId);
      }
    },
    shareId: function () {
      return this.$store.getters["app/shareId"];
    },
  },

  methods: {
    closeModal: function () {
      this.$store.dispatch("app/setShare", null);
    },
    onSubmit: function () {
      this.$store.dispatch("triggerExtension", { type: "boards:update" });
      this.options.active = false;
    },
    activate: function () {
      this.options.active = true;
    },
  },

  mounted: function () {
    //this.options.active = true;
  },
};
</script>

<style lang="scss"></style>
