<template>
  <form class="c-form-generate-transcript" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Generate Transcript"
      buttonClass="btn-box btn-primary"
      :processing="processing"
      :errors="errors"
    ></Form>
    <small> This might take upto a minute. </small>
  </form>
</template>

<script>
import Form from "./index.vue";

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: {},
      processing: false,
      form: {
        email: "",
      },
      status: null,
      errors: [],
    };
  },

  props: {
    blockId: {},
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      form = {
        blockId: this.blockId,
      };

      let response = null;
      try {
        response = await this.$store.dispatch("ads/generateTranscript", form);
      } catch (err) {
        this.errors = err;
      }
      this.processing = false;
      if (!response) {
        return;
      }
      this.errors = [];
      await this.$store.dispatch("message/onTranscriptCreated");
      this.$emit("onSubmit", response);
    },
  },

  created: function () {},
};
</script>

<style lang="scss">
.c-form-generate-transcript {
  small {
    display: block;
    margin-top: -4px;
  }
}
</style>
