<template>
  <div class="c-utils-copy">
    <div class="c-utils-copy__text">
      {{ text }}
    </div>
    <div class="c-utils-copy__controls">
      <div class="btn btn-box btn-sm" @click="onCopy">{{ copyText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      processing: false,
    };
  },

  props: {
    text: {},
  },

  computed: {
    copyText: function () {
      if (this.processing) {
        return "Copied!";
      }
      return "Copy";
    },
  },

  methods: {
    onCopy: function () {
      this.processing = true;
      let text = this.text;

      // Copy the text inside the text field
      navigator.clipboard.writeText(text);

      setTimeout(() => {
        this.processing = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
.c-utils-copy {
  display: grid;
  grid-template-columns: 1fr 60px;
  border: var(--color-bg-5) solid 1px;
  border-radius: var(--border-radius);
  padding: var(--margin);

  &__text {
    font-family: var(--font-family-monospace);
    font-size: var(--font-size-xs);
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 2px;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
