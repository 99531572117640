<template>
  <div class="c-onboarding2-ad-card-customize">
    <Page :i="3">
      <!-- <span class="c-byline"> Tutorial #5: Boards </span> -->
      <h1>Choose your fighter</h1>
      <article class="article-text text-center">
        <p>Select what display works for you.</p>
        <p>Don't worry, you can change this setting later.</p>
      </article>
      <div class="c-onboarding2-ad-card-customize__grid">
        <label for="in1" :class="[{ active: currentSelection === 'all' }]">
          <p>Default<br /><em>Best for competitor analysis</em></p>

          <Item :hideAdModal="true" :labels="false" view="all" v-if="computedAd" :ad="computedAd"></Item>

          <div class="c-onboarding2-ad-card-customize__selector">
            <input v-model="currentSelection" id="in1" type="radio" name="select" value="all" />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </label>

        <label for="in2" :class="[{ active: currentSelection === 'md' }]">
          <p>
            Preview
            <br />
            <em>Best for checking Ad placements.</em>
          </p>

          <Item :hideAdModal="true" :labels="false" view="md" v-if="computedAd" :ad="computedAd"></Item>
          <div class="c-onboarding2-ad-card-customize__selector">
            <input v-model="currentSelection" id="in2" type="radio" name="select" value="md" />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </label>

        <label for="in3" :class="[{ active: currentSelection === 'sm' }]">
          <p>
            Compact<br />
            <em>Best for browsing Ad inspo.</em>
          </p>

          <Item :hideAdModal="true" :labels="false" view="sm" v-if="computedAd" :ad="computedAd"></Item>
          <div class="c-onboarding2-ad-card-customize__selector">
            <input v-model="currentSelection" id="in3" type="radio" name="select" value="sm" />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </label>
      </div>

      <ButtonCta v-if="currentSelection" @onClick="onClick"> </ButtonCta>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
import Item from "@swipekit/components/item";
import api from "@swipekit/lib/api";

export default {
  components: {
    Page,
    ButtonCta,
    Item,
  },

  props: {
    tempAd: {},
  },

  data: function () {
    return {
      currentSelection: null,
    };
  },

  computed: {
    computedAd: function () {
      if (!this.tempAd) {
        return;
      }
      return this.tempAd;
    },
  },

  methods: {
    onClick: function (e) {
      if (e && !e.target) {
        this.$store.dispatch("message/sendMessage", {
          message: "Board created",
          status: "success",
        });
      }
      this.$emit("onNext");
    },
    onClick: function () {
      // save the selection here
      let view = this.currentSelection;
      api.api.setOption("view", view);

      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss">
.c-onboarding2-ad-card-customize {
  &__grid {
    margin-left: -120px;
    width: calc(100% + 240px);
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-column-gap: 8px;

    label {
      display: flex;
      flex-direction: column;
      transition: background-color 120ms linear;
      background-color: var(--color-bg-3);
      border: var(--color-bg-4) solid 2px;
      cursor: pointer;

      > p {
        display: block;
        min-height: 40px;
        margin: 0;
        padding: var(--margin-lg);
        line-height: 1.2;

        > em {
          font-size: var(--font-size-sm);
          font-style: normal;
          opacity: 0.75;
        }
      }

      &.active {
        border-color: var(--color-primary);
        background-color: hsl(var(--p), 75%, 96%);
      }
    }

    .c-grid-item {
      width: 100%;
      pointer-events: none;
    }
  }

  &__selector {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--margin-lg);
    border-radius: 10px;
    margin-bottom: var(--margin-lg);
    background-color: transparent;

    cursor: pointer;

    user-select: none;

    svg {
      width: 48px;
      height: 48px;
      border: var(--color-bg-6) solid 3px;
      color: var(--color-bg-4);
      border-radius: 50%;
      margin-right: var(--margin);
      transition: color 120ms linear;
    }

    span {
      font-weight: 500;
    }

    input {
      display: none;

      &:checked + svg {
        border-color: var(--color-primary);
        color: var(--color-primary);
      }
    }
  }
}
</style>
