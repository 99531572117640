<template>
  <div class="c-header-extension-status" v-tooltip="tooltip(description)" @click="onClick">
    <span :class="['extension-status', { inactive: !extensionState }]"> </span>
  </div>
</template>

<script>
export default {
  computed: {
    extensionState: function () {
      return this.$store.getters["extensionState"];
    },
    description: function () {
      let extensionState = this.extensionState;

      if (extensionState) {
        return `Swipekit extension is connected.`;
      }
      return `Swipekit extension isn't connected. You won't be able to save ads`;
    },
  },

  methods: {
    tooltip: function (content) {
      return { content: content, offset: 10, delay: 200 };
    },
    onClick: function () {
      this.$store.dispatch("app/showExtensionHelper", true);
    },
  },
};
</script>

<style lang="scss">
.c-header-extension-status {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > .extension-status {
    margin-right: 14px;
    width: 10px;
    height: 10px;
    background-color: var(--color-success);
    border-radius: 10px;
    box-shadow: 0 0 0 2px #fff;

    &.inactive {
      background-color: var(--color-warning);
    }
  }
}
</style>
