<template>
  <a class="c-trial-line" @click="onClick" href="#">
    <div class="c-trial-line__info">Trial period. {{ trialStartedDifference }} left.</div>
  </a>
</template>

<script>
export default {
  computed: {
    trialStartedDifference: function () {
      let day = `days`;
      let days = this.$store.getters["users/trialStartedDifference"];

      if (days <= 0) {
        days = 0;
      }

      if (days === 1) {
        day = `day`;
      }

      return `${days} ${day}`;
    },
  },
  methods: {
    onClick: function () {
      this.$router.push("/profile/billing");
    },
  },
};
</script>

<style lang="scss">
.c-trial-line {
  display: block;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: var(--color-primary);
  cursor: pointer;

  &__info {
    position: absolute;
    bottom: calc(100% - 6px);
    padding: var(--margin);
    font-size: var(--font-size-sm);
    color: #fff;
    background-color: var(--color-primary);
    border-top-right-radius: 6px;
  }
}
</style>
