<template>
  <form class="c-form-invite-user-setting" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="form"
      @status="saveStatus"
      buttonText="Join Workspace"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

let schema = {
  name: {
    component: "InputText",
    label: "Your name",
    placeholder: "name",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
      minLength: {
        params: 2,
        message: "Your name can't be that short, surely?",
      },
      maxLength: {
        params: 40,
        message: `Whoa, that's a long name. Use the name your friends call you by?`,
      },
    },
  },
  password: {
    component: "InputPassword",
    label: "Password",
    placeholder: "********",
    description: "Make sure your password is at least 8 letters. Better if more.",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
      minLength: {
        params: 8,
        message: "Password should be at least 8 characters",
      },
      maxLength: {
        params: 40,
        message: `That's a looong password. Make it short ya'll.`,
      },
    },
  },
};

export default {
  components: {
    Form,
  },

  props: {
    inviteCode: {},
  },

  data: function () {
    return {
      processing: false,
      form: {
        name: "",
        password: "",
      },
      status: null,
    };
  },

  computed: {
    computedSchema: function () {
      let s = schema;

      return s;
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    schema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      form.inviteCode = this.inviteCode;

      let response = await this.$store.dispatch("users/acceptInvite", form);

      if (response) {
        await this.$store.dispatch("afterLogin");

        await this.$store.dispatch("message/onAcceptInvite");
      }

      setTimeout(() => {
        location.reload();
      }, 1500);

      this.$emit("onSubmit");
      this.processing = false;
    },
  },

  created: function () {},
};
</script>

<style></style>
