<template>
  <div class="c-modal-board-update">
    <Modal v-model="options.active" @input="closeModal" :closable="true">
      <template v-slot:title>
        <h2>Update Board</h2>
      </template>
      <template>
        <FormBoardUpdate v-if="editId" :id="editId" :form="tracker" @onSubmit="onSubmit"></FormBoardUpdate>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "./index.vue";
import FormBoardUpdate from "@swipekit/components/form/form-board-update.vue";

export default {
  components: {
    Modal,
    FormBoardUpdate,
  },

  data: function () {
    return {
      options: {
        name: "modal-board-update",
        active: false,
      },
    };
  },

  watch: {
    "options.active": {
      handler: function () {},
    },
    editId: {
      handler: function () {
        if (this.editId) {
          this.options.active = true;
        } else {
          this.options.active = false;
        }
      },
      immediate: true,
    },
  },

  computed: {
    editId: function () {
      return this.$store.getters["boards/editId"];
    },
    tracker: function () {
      if (!this.editId) {
        return null;
      }
      return this.$store.getters["boards/one"](this.editId);
    },
  },

  methods: {
    closeModal: function () {
      this.$store.dispatch("boards/setEdit", null);
    },
    onSubmit: function () {
      this.$store.dispatch("triggerExtension", { type: "boards:update" });
      this.options.active = false;
    },
    activate: function () {
      this.options.active = true;
    },
  },

  mounted: function () {
    //this.options.active = true;
  },
};
</script>

<style lang="scss"></style>
