<template>
  <form class="c-form-board-create" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      :buttonText="buttonText"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  name: {
    component: "InputText",
    label: "Board name",
    description: "Eg: Bold graphics, #hookname, #clientname, etc.",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "A board name is required",
      },
      minLength: {
        params: 4,
        message: "Give a descriptive name to your board. Minimum 4 characters",
      },
      maxLength: {
        params: 54,
        message: `That's a insanely long board name. Maximum 54 characters`,
      },
    },
  },
  description: {
    component: "InputEditor",
    label: "Board description",
    placeholder: "What is this board about. Add some context for your clients, VAs, colleagues, etc",
  },
  // public: {
  //   component: "InputPublicLink",
  //   label: "Make this public?",
  // },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      schema: schema,
      form: {
        name: "",
        description: "",
      },
      status: null,
    };
  },

  props: {
    buttonText: {
      type: String,
      default: `Create Board`,
    },
  },

  methods: {
    reset: function () {
      this.form.name = "";
      this.form.description = "";
    },
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      let res = await this.$store.dispatch("boards/add", form);

      this.reset();

      this.$emit("onSubmit", res.data);

      this.processing = false;
    },
  },
};
</script>

<style></style>
