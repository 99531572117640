<template>
  <div class="form-group c-input-number">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-number__inner">
      <input
        :class="[{ 'form-control': noStyle === false }]"
        :id="slug"
        type="number"
        :value="currentValue"
        @change="onChange"
        :placeholder="placeholder"
        ref="input"
      />
      <span class="btn" tabindex="0" @click="onDecrement">-</span>
      <span class="btn" tabindex="0" @click="onIncrement">+</span>
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription v-if="description" :message="description"></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      currentValue: 0,
    };
  },

  watch: {
    value: {
      handler: function () {
        if (this.value) {
          this.currentValue = this.value;
        }
      },
      immediate: true,
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  computed: {
    slug: function () {
      return slugify(this.label);
    },
  },

  methods: {
    onChange: function (e) {
      let value = e.target.value;
      this.$emit("input", value);
    },
    onIncrement: function () {
      this.currentValue += 1;
      this.$emit("input", this.currentValue);
    },
    onDecrement: function () {
      this.currentValue -= 1;
      this.$emit("input", this.currentValue);
    },
  },

  mounted: function () {
    this.$refs.input.onpaste = (e) => e.preventDefault();
  },

  beforeDestroy: function () {
    this.$refs.input = null;
  },
};
</script>

<style lang="scss">
.c-input-number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__inner {
    position: relative;

    .btn {
      position: absolute;
      top: 2px;
      right: 34px;
      width: 30px;
      height: calc(100% - 4px);
      justify-content: center;
      align-items: center;
      font-weight: 600;
      &:last-of-type {
        right: 2px;
      }
    }
  }
}
</style>
