<template>
  <form class="c-form-seats" @submit.prevent="onSubmit">
    <Form
      :processing="processing"
      ref="form"
      :schema="schema"
      v-model="form"
      buttonText="Buy seats"
      @status="saveStatus"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  seats: {
    component: "InputSeats",
    label: "Seats",
    placeholder: "0",
    properties: {
      columnSize: 6,
    },
    validations: {},
  },
};
export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: schema,
      processing: false,
      form: {
        seats: "",
      },
      errors: [],
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      let res = null;

      await this.$store.dispatch("users/buy", {
        plan: "monthly-seat",
        quantity: parseInt(form.seats),
      });
      return;

      try {
        res = await this.$store.dispatch("users/updatePassword", form);
      } catch (err) {
        console.log(err);
        if (err.length === 0) {
          err.push({
            message: "Something went wrong, please send another reset password request.",
          });
        }
        this.errors = err;
        this.processing = false;
        return;
      }

      await this.$store.dispatch("afterLogin");

      await this.$store.dispatch("message/onPasswordReset");

      this.$emit("onSubmit");

      this.processing = false;
    },
  },

  mounted: function () {},
};
</script>

<style></style>
