<template>
  <div class="c-page-fb">
    <Page :i="7">
      <!-- <span class="c-byline"> Tutorial #6: Team & Workspace </span> -->
      <h1>Get your whole team onboarded</h1>
      <article class="article-text">
        <p>Add a teammate to your Workspace.</p>
        <p>They will be able to access all saved Ads and boards.</p>
      </article>
      <FormUserAdd @onSubmit="onClick"></FormUserAdd>
      <br />
      <p class="small">You can add additional teammates for an extra charge.</p>
      <hr />
      <a href="#" @click="onClick">Skip, I'll add them later.</a>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
import FormUserAdd from "@swipekit/components/form/form-user-add";

export default {
  components: {
    Page,
    ButtonCta,
    FormUserAdd,
  },

  methods: {
    onClick: function (e) {
      if (e && !e.target) {
        this.$store.dispatch("message/sendMessage", {
          message: "Teammate added",
          status: "success",
        });
      }
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss"></style>
