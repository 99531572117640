import api from "@swipekit/lib/api.js";

import CrudStore from "@swipekit/lib/crud-store";

let crud = new CrudStore({
  name: "ads",
  singular: "ad",
});

const state = {
  ...crud.state,

  viewCache: [],

  selection: [],

  removeSelectionTrigger: false,
};
const getters = {
  ...crud.getters,

  removeSelectionTrigger: function (state) {
    return state.removeSelectionTrigger;
  },

  byBoard: function (state) {
    let ads = state.resources;
    return function (boardId) {
      let boardBlocks = [];

      for (let i = 0; i < ads.length; i++) {
        if (!ads[i].boards) {
          continue;
        }
        if (ads[i].boards.length === 0) {
          continue;
        }

        for (let j = 0; j < ads[i].boards.length; j++) {
          if (ads[i].boards && ads[i].boards[j].boardId === boardId) {
            boardBlocks.push(ads[i]);
            break;
          }
        }
      }

      return boardBlocks;
    };
  },

  viewCache: function (state) {
    return state.viewCache;
  },

  selection: function (state) {
    return state.selection;
  },
};
const actions = {
  ...crud.actions,

  async generateTranscript(store, obj) {
    let response = await crud.api.ads.generateTranscript(obj).catch((err) => {
      console.log(err);
    });

    return response;
  },

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let form = obj.form || null;
    let response = await crud.api.ads.doAction(id, action, form);

    if (response) {
      if (Array.isArray(response)) {
        for (let i = 0; i < response.length; i++) {
          store.commit("UPDATE", response[i]);
        }
      } else {
        store.commit("UPDATE", response);
      }
    }

    return response;
  },

  async removeBoard(store, boardId) {
    let resources = [];

    let blocks = store.state.resources;
    for (let i = 0; i < blocks.length; i++) {
      let change = false;
      blocks[i].boards = blocks[i].boards.filter((b) => {
        if (b.boardId === boardId) {
          change = true;
          return false;
        } else {
          return true;
        }
      });
      if (change) {
        resources.push(blocks[i]);
      }
    }

    store.commit("BULK_UPDATE", resources);
  },

  async addSelection(store, id) {
    store.state.selection.push(id);
  },

  async removeSelection(store, id) {
    let selection = store.state.selection;

    let i = selection.indexOf(id);
    if (i > -1) {
      selection.splice(i, 1);

      store.state.selection = selection;
    }
  },

  async updateBrand(store, resource) {
    try {
      resource = await crud.api.ads.updateBrand(resource);
    } catch (err) {
      throw err;
    }
    if (resource) {
      return true;
    }

    return resource;
  },

  async clearSelection(store) {
    store.state.selection = [];
  },

  async setViewCache(store, filteredBlocks) {
    store.commit("VIEW_CACHE", filteredBlocks);
  },

  /**
   * Deletes a record
   */
  remove: async (store, id) => {
    let condition = false;

    store.commit("SET_REMOVE", id);

    try {
      condition = await crud.api.ads.delete(id);
    } catch (err) {
      console.log(err);
      throw err;
    }
    if (condition) {
      store.commit("REMOVE", id);

      await store.dispatch("message/onAdDelete", null, { root: true });
    }

    return condition;
  },

  async get(store, resources = []) {
    if (resources.length === 0) {
      try {
        resources = await api.ads.getAll();
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
    store.commit("SET", resources);
    return resources;
  },

  async removeFromBoard(store, data) {
    let ad = store.getters["one"](data.adId);

    ad = JSON.parse(JSON.stringify(ad));

    let boards = JSON.parse(JSON.stringify(ad.boards));

    boards = boards.filter((board) => {
      if (board.boardId === data.boardId) {
        return false;
      }
      return true;
    });
    ad.boards = boards;

    // modified version of update
    store.commit("UPDATE", ad);

    let resource = await api.ads.update(resource).catch((err) => {
      throw err;
    });

    return resource;
  },

  async bulkDeleteAds(store, selection) {
    let response = await api.ads.doAction(1, "bulk-delete-ads", selection).catch((err) => {
      throw err;
    });

    let ads = store.state.resources;

    try {
      ads = JSON.parse(JSON.stringify(ads));
    } catch (err) {
      ads = [];
    }

    ads = ads.filter((ad) => {
      if (selection.includes(ad.id)) {
        return false;
      }
      return true;
    });

    store.commit("SET", ads);

    return response;
  },

  async removeFromSelection(store) {
    store.state.removeSelectionTrigger = !store.state.removeSelectionTrigger;
  },

  async bulkRemoveAdsFromBoard(store, payload) {
    let selection = payload.selection;
    let boardId = payload.boardId;

    let response = await api.ads.doAction(1, "bulk-remove-ads-from-board", payload).catch((err) => {
      throw err;
    });

    let ads = store.state.resources;

    try {
      ads = JSON.parse(JSON.stringify(ads));
    } catch (err) {
      ads = [];
    }

    for (let i = 0; i < ads.length; i++) {
      let ad = ads[i];
      if (!selection.includes(ad.id)) {
        continue;
      }

      let boards = ad.boards;

      boards = boards.filter((board) => {
        if (board.boardId === boardId) {
          return false;
        }
        return true;
      });

      ad.boards = boards;

      store.commit("UPDATE", ad);
    }

    return response;
  },

  /**
   *
   * Adds a new resource
   */
  async add(store, resource) {
    const response = await api.ads.add(resource).catch((err) => {
      throw err;
    });
    return response;
    console.log(resource);
    // if (resource) {
    //   store.commit("ADD", resource);

    //   store.dispatch("message/onResourceUpdate", `${this.resourceName} has been created`, { root: true });
    // }

    return resource;
  },

  consumePie: async (store, pie) => {
    store.commit("SET", pie.workspace.ads || []);
  },
};

const mutations = {
  ...crud.mutations,

  VIEW_CACHE: function (state, filteredBlocks) {
    state.viewCache = filteredBlocks;
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default store;
