<template>
  <div class="c-tooltip" v-tooltip="description">i</div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style class="c-tooltip">
.c-tooltip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: var(--color-bg-4);
  border-radius: var(--border-radius-full);
  color: hsl(var(--p), 40%, 50%);
  line-height: 1;
  font-weight: 700;
  font-size: var(--font-size-xs);
  font-family: var(--font-family-monospace);
  text-transform: initial;
  user-select: none;
}
</style>
