function trackAffiliate() {
  // Get the current URL
  const urlParams = new URLSearchParams(window.location.search);

  // Get the value of the 'affiliate' parameter
  const affiliateID = urlParams.get("tk");

  if (!affiliateID) {
    return;
  }

  localStorage.setItem("affiliateID", affiliateID);

  return affiliateID;
}

export default trackAffiliate;
