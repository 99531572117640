function generateRow(ad) {
  let adTitle = ad.block.title;
  let adCopy = ad.block.adCopy;
  let type = ad.block.type;
  let assetType = ad.block.assetType;
  let tags = ad.tags;
  let assets = getAssets(ad.block.assets);

  let row = [adTitle, adCopy, type, assetType, tags, assets];

  return row;
}

function getAssets(assets) {
  let strs = [];

  for (let i = 0; i < assets.length; i++) {
    let asset = assets[i];
    let link = `https://file.swipekit.app/${asset.assetPath}`;
    strs.push(link);
  }

  if (strs.length === 1) {
    return strs[0];
  } else {
    strs = strs.join(",");
    strs = `"${strs}"`;

    return strs;
  }
}

function joinRows(rows) {
  let csvContent = "data:text/csv;charset=utf-8,";

  rows.forEach((rowArray) => {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });
  return csvContent;
}

function generateCsv(ads) {
  let rows = [];

  let header = ["Ad Title", "Ad Copy", "Type", "Asset Type", "Tags", "Assets"];

  rows.push(header);

  for (let i = 0; i < ads.length; i++) {
    let ad = ads[i];

    let row = generateRow(ad);

    rows.push(row);
  }

  let csv = joinRows(rows);

  return csv;
}

export default generateCsv;
