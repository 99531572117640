<template>
  <form class="c-form-user" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Next"
      buttonClass="btn-lg"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  // work_in: {
  //   component: "InputCheckboxGroup",
  //   label: "Where do you work in?",
  //   validations: {
  //     required: {
  //       params: null,
  //       message: "This field is required",
  //     },
  //   },
  //   options: ["Performance Marketing Agency", "B2B Marketing Agency", "Sole/Freelance Marketer", "Other"],
  // },
  find_us: {
    component: "InputRadioGroup",
    // label: "How did you find us?",
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
    },
    options: ["Reddit", "Twitter", "Chrome Webstore", "Google", "Linkedin", "Youtube", "Word of mouth", "Other"],
  },
  // use_swipekit_as: {
  //   component: "InputRadioGroup",
  //   label: "What is your primary Industry",
  //   validations: {
  //     required: {
  //       params: null,
  //       message: "This field is required",
  //     },
  //   },
  //   options: [
  //     "ECommerce(DTC)",
  //     "ECommerce(Other)",
  //     "B2B Marketing",
  //     "Services(Law, Medical, Consulting, etc)",
  //     "Other",
  //   ],
  // },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: schema,
      processing: false,
      form: {},
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    getUser: function () {
      return this.$store.getters["users/user"];
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = {
        marketing: this.form,
      };

      let response = null;
      try {
        response = await this.$store.dispatch("users/update", form);
      } catch (err) {
        console.log(err);
      }
      this.processing = false;
      this.$emit("onSubmit");
    },
  },

  created: function () {},
};
</script>

<style lang="scss">
.c-form-settings {
  margin-bottom: var(--spacer);
}
</style>
