<template>
  <form class="c-form-login" @submit.prevent="onSubmit">
    <Form
      :processing="processing"
      ref="form"
      :schema="schema"
      v-model="form"
      buttonText="Send password reset link"
      @status="saveStatus"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  email: {
    component: "InputEmail",
    label: "Your email",
    description: "We'll send you a password reset link to this email if you have an account with us.",
    placeholder: "email@gmail.com",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "This field is required",
      },
    },
  },
};
export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: schema,
      processing: false,
      form: {
        email: "",
        password: "",
      },
      errors: [],
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      let form = this.form;

      this.processing = true;

      let res = null;
      try {
        res = await this.$store.dispatch("users/resetPassword", form);
      } catch (err) {
        console.log(err);
        if (err.length === 0) {
          err.push({
            message: "User not found",
          });
        }
        this.errors = err;
      }

      this.processing = false;

      this.$emit("onSubmit");
    },
  },
};
</script>

<style></style>
