<template>
  <div class="c-library-list">
    <SearchBar :activeCategories="tags" v-model="params" @search="onSearch" @view="onView"></SearchBar>

    <div v-masonry="containerId" transition-duration="0s" item-selector=".c-grid-item">
      <Item
        v-for="block in computedBlocks"
        :view="view"
        :ad="block"
        :key="block.id"
        @onSubmit="onSubmit"
        @onDropdownClick="onDropdownClick"
        v-masonry-tile
      ></Item>
    </div>
    <div class="c-library-list__empty-state" v-if="end && blocks.length > 0">
      <div class="c-library-list__empty-state__inner">
        <h5>End of results</h5>
      </div>
    </div>
    <div class="c-library-list__empty-state" v-if="blocks.length === 0 && !processing">
      <div class="c-library-list__empty-state__inner">
        <h5>No Ads found.</h5>
        <p>All your saved Ads will show up here.</p>
        <router-link to="/multiplayer">Browse Multiplayer to find Ads.</router-link>
      </div>
    </div>
    <PopupBlockOptions
      @onDelete="onDelete"
      @onClose="onPopupClose"
      :adId="popupAdId"
      :target="popupTarget"
      :active="popupActive"
    ></PopupBlockOptions>
  </div>
</template>

<script>
import SearchBar from "@swipekit/components/utils/search-bar";
import Item from "@swipekit/components/item";

import filter from "@swipekit/lib/filter";
import { getParams, setParams } from "@swipekit/lib/query-param-utils";

import PopupBlockOptions from "@swipekit/components/popup/popup-block-options";

import api from "@swipekit/lib/api";

export default {
  components: {
    SearchBar,
    Item,
    PopupBlockOptions,
  },

  data: function () {
    return {
      containerId: null,
      params: {
        query: "",
        network: "all",
        mediaType: "all",
        activeAds: 0,
        sort: "all",
      },
      blocks: [],
      skip: 0,
      take: 0,

      view: "",

      end: false,

      popupActive: false,
      popupTarget: null,
      popupAdId: null,
    };
  },

  props: {
    adIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  watch: {
    $route: function () {},
    refreshFilters: function () {
      // This will act as a refresh
      this.blocks = [];
      this.skip = 0;
      this.take = 40;
      this.end = false;
      this.filter(null, true);

      api.api.getOptions().then((options) => {
        this.view = options.view || "";
      });
    },
    removeId: function () {
      if (this.removeId) {
        this.blocks = this.blocks.filter((obj) => {
          return obj.id !== this.removeId;
        });
      }
    },
    removeSelectionTrigger: function () {
      const selection = this.$store.getters["ads/selection"] || [];

      if (selection.length === 0) {
        return;
      }

      let blocks = JSON.parse(JSON.stringify(this.blocks));

      blocks = blocks.filter((obj) => !selection.includes(obj.id));

      this.blocks = blocks;
    },
  },

  computed: {
    workspace: function () {
      let workspace = this.$store.getters["workspaces/all"];

      return workspace;
    },
    tags: function () {
      if (!this.workspace) {
        return [];
      }

      let tags = this.workspace.tags || [];

      return tags;
    },
    removeSelectionTrigger: function () {
      return this.$store.getters["ads/removeSelectionTrigger"];
    },
    removeId: function () {
      return this.$store.getters["ads/removeId"];
    },
    refreshFilters: function () {
      return this.$store.getters["app/refreshFilters"];
    },
    computedBlocks: function () {
      let blocks = this.blocks;

      if (!blocks) {
        return [];
      }

      let ids = this.adIds;

      for (let i = 0; i < blocks.length; i++) {
        let isSaved = false;

        if (ids.includes(blocks[i].block.id)) {
          isSaved = true;
        }

        blocks[i].isSaved = isSaved;
      }

      return blocks;
    },
    processing: function () {
      return this.$store.getters["app/processing"];
    },
  },

  methods: {
    onDelete: async function (e) {
      this.onPopupClose();

      let res = await this.$store.dispatch("ads/remove", e).then((err) => {
        console.log(err);
      });
    },
    onPopupClose: function () {
      this.popupTarget = null;
      this.popupActive = false;
      this.popupAdId = null;
    },
    onDropdownClick: function (e) {
      const el = document.querySelector(`.${e.tempClass}`);

      if (!el) {
        console.log("err el not found");
        return;
      }

      this.popupTarget = el;
      this.popupActive = true;
      this.popupAdId = e.id;
    },
    onSubmit: function (e) {
      this.$emit("onSubmit", e);
    },

    applyParams: function (params) {
      if (params.query) {
        this.params.query = params.query;
      }

      if (params.skip) {
        this.skip = params.skip;
      }
      if (params.take) {
        this.take = params.take;
      }

      if (params.mediaType) {
        this.params.mediaType = params.mediaType;
      }

      if (params.activeAds) {
        this.params.activeAds = params.activeAds;
      }

      if (params.network) {
        this.params.network = params.network;
      }

      if (params.sort) {
        this.params.sort = params.sort;
      }
    },
    onEnd: function () {
      if (this.processing) {
        return;
      }
      if (this.end) {
        return true;
      }
      this.skip += this.take;
      this.filter();
    },
    async onSearch(params) {
      this.blocks = [];
      this.skip = 0;
      this.end = false;
      await this.filter(params);
    },
    async onView(view) {
      this.view = view;

      api.api.setOption("view", view);

      this.$nextTick(() => {
        this.$redrawVueMasonry();
      });
    },
    async filter(params, firstTime = false) {
      if (this.processing) {
        return;
      }
      if (this.end) {
        return;
      }
      this.$store.dispatch("app/setProcessing", true);

      if (firstTime) {
        params = getParams();

        // if first time, force 1st page
        params.skip = 0;

        this.applyParams(params);
      }
      if (!params) {
        params = getParams();
      }

      params.skip = this.skip;
      params.take = this.take;

      setParams(params);

      const blocks = await filter(params, "filter-ad");

      if (blocks.length < this.take) {
        this.end = true;
      }

      this.blocks = this.blocks.concat(blocks);

      this.$nextTick(() => {
        this.$redrawVueMasonry();
        this.$store.dispatch("app/setProcessing", false);
      });
    },
  },

  mounted: async function () {
    this.skip = 0;
    this.take = 40;
    this.filter(null, true);

    let options = await api.api.getOptions();

    this.view = options.view || "";

    // setInterval(() => {
    //   this.$store.dispatch("app/refreshFilters");
    // }, 5000);
  },
};
</script>

<style lang="scss">
.c-library-list {
  &__empty-state {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__inner {
      display: inline-block;
      margin: 0 auto;
      border: var(--color-bg-4) solid 1px;
      border-radius: 10px;
      padding: var(--spacer-sm);

      h5 {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
