<template>
  <div class="c-page-setup-extension">
    <Page :i="2">
      <h1>Install the Chrome Extension</h1>
      <article class="article-text">
        <p>
          Our Chrome Extension enables you to save Ads from the Meta Ad Library, Tiktok Top Ads and Linkedin Ads at the
          click of a button!
        </p>
        <ButtonCta @onClick="onOpenTab">
          <span> Install the extension </span>
        </ButtonCta>
        <hr />
        <a href="#" @click="onClick">Skip, I'll install it later.</a>
      </article>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
export default {
  components: {
    Page,
    ButtonCta,
  },

  methods: {
    onOpenTab: function () {
      const url = `https://chromewebstore.google.com/detail/ad-library-downloader/gojmmkhaiojimnnjhhilmhjmhdbdagod`;
      window.open(url, "_blank").focus();
    },
    onClick: function () {
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss">
.c-page-setup-extension {
}
</style>
