<template>
  <div class="c-board" v-if="board">
    <PageHeader byline="Board">
      <template v-slot:title> {{ board.name }} </template>
      <template v-slot:footer>
        <div class="c-board__description" v-if="board.description">
          <p class="article-text">{{ board.description }}</p>
        </div>
        <div class="c-board__buttons">
          <a
            v-if="board.public"
            class="btn btn-icon btn-box"
            target="_blank"
            :href="`https://swipekit.app/boards/${board.slug}`"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.7557 11.5739L18.1421 11.1876C19.6358 9.69387 19.6358 7.27214 18.1421 5.77846C16.6484 4.28478 14.2267 4.28478 12.733 5.77846L9.8316 8.67987C8.66003 9.85144 8.66003 11.7509 9.8316 12.9225L10.8012 13.8921M6.16483 12.3467L5.77846 12.7331C4.28478 14.2267 4.28478 16.6485 5.77846 18.1421C7.27214 19.6358 9.69387 19.6358 11.1876 18.1421L14.089 15.2407C15.2605 14.0692 15.2605 12.1697 14.089 10.9981L13.1194 10.0285"
                stroke="currentColor"
                stroke-linecap="round"
              /></svg
            ><span>Share link</span>
          </a>
          <a class="btn btn-icon btn-box" href="#" @click.prevent="editBoard">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 11L9.08787 20.9121C9.03161 20.9684 8.9553 21 8.87574 21H3.3C3.13432 21 3 20.8657 3 20.7L3 15.1242C3 15.0447 3.03161 14.9684 3.08787 14.9121L13 4.99997M19 11L21.2929 8.70708C21.6834 8.31655 21.6834 7.68339 21.2929 7.29286L16.7071 2.70708C16.3166 2.31655 15.6834 2.31655 15.2929 2.70708L13 4.99997M19 11L13 4.99997"
                stroke="currentColor"
              />
            </svg>
            <span>Update board</span>
          </a>
        </div>
      </template>
    </PageHeader>
    <List :standalone="standalone" v-if="init" ref="list" :board="board" :adIds="adIds" @onSubmit="onSave"></List>
    <Share v-if="board && board.shareable" :boardId="board.id"></Share>
  </div>
</template>

<script>
import PageHeader from "@swipekit/components/utils/page-header";
import List from "./list";
import api from "@swipekit/lib/api";
import Share from "./share";

export default {
  name: "Multiplayer",

  components: {
    PageHeader,
    List,
    Share,
  },

  data: function () {
    return {
      init: false,
      adIds: [],
    };
  },

  props: {
    standalone: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    slug: function () {
      return this.$route.params.slug;
    },
    board: function () {
      if (!this.slug) {
        return;
      }
      return this.$store.getters["boards/boardBySlug"](this.slug);
    },
  },

  methods: {
    editBoard: function () {
      this.$store.dispatch("boards/setEdit", this.board.id);
    },
    onSave: function (e) {
      const blockId = e.blockId;

      this.$store.dispatch("ads/refreshAll");

      this.adIds.push(blockId);
    },
    onEnd: function () {
      this.$refs.list.onEnd();
    },
    async getIds() {
      let ids = await api.ads.getAdIds();

      this.adIds = ids;

      this.init = true;
    },
  },

  mounted: function () {
    this.getIds();
  },
};
</script>

<style lang="scss">
.c-board {
  height: 100vh;
  position: relative;
  overflow-y: auto;

  &__buttons {
    margin-top: var(--spacer-sm);
    display: inline-flex;
    align-items: center;

    a {
      margin: 0 var(--margin);
    }
  }

  &__description {
    text-align: left;
    max-width: 460px;
    margin: 0 auto;
    margin-top: var(--spacer-sm);
    opacity: 0.85;
    white-space: break-spaces;
    padding: var(--spacer-sm);
    border: rgba(0, 0, 0, 0.1) solid 1px;
    border-radius: 10px;

    .article-text {
      margin-top: 0;
      font-size: 16px;
    }
  }
}
</style>
