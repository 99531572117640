<template>
  <div class="r-webpage-selection">
    <div class="r-webpage-selection__single" v-if="single === true">
      <img v-if="block.assets[0]" :src="`${assetUrl}/${block.assets[0].assetPath}`" />
    </div>
    <div z class="r-webpage-selection__carousel" v-else>
      <div v-for="(image, key) in block.assets" :key="key">
        <img v-if="image" :src="`${assetUrl}/${image.assetPath}`" />
      </div>
      <carousel :perPage="1">
        <slide v-for="(image, key) in block.assets" :key="key">
          <img v-if="image" :src="`${assetUrl}/${image.assetPath}`" />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
    single: {},
  },

  computed: {
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
  },

  methods: {},
};
</script>

<style lang="scss">
.r-webpage-selection {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
