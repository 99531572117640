<template>
  <div class="form-group c-input-boolean">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-boolean__inner">
      <!-- <button
        type="button"
        :class="['btn', { 'btn-secondary': value === true }]"
        @click="onChange(true)"
      >
        Yes
      </button>
      <button
        type="button"
        :class="['btn', { 'btn-secondary': value !== true }]"
        @click="onChange(false)"
      >
        No
      </button> -->
      <div class="onoffswitch">
        <input
          type="checkbox"
          name="onoffswitch"
          class="onoffswitch-checkbox"
          :id="`switch-${_uid}`"
          v-model="boolean"
        />
        <label class="onoffswitch-label" :for="`switch-${_uid}`">
          <span class="onoffswitch-inner"></span>
          <span class="onoffswitch-switch">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM10.25 8C10.25 8.41421 10.5858 8.75 11 8.75H14.1893L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L15.25 9.81066V13.0769C15.25 13.4911 15.5858 13.8269 16 13.8269C16.4142 13.8269 16.75 13.4911 16.75 13.0769V8C16.75 7.58579 16.4142 7.25 16 7.25H11C10.5858 7.25 10.25 7.58579 10.25 8Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </label>
      </div>
    </div>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";
export default {
  components: {
    InputError,
    InputDescription,
  },
  data: function () {
    return {
      boolean: false,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.boolean = this.value;
      },
      immediate: true,
    },
    boolean: function () {
      this.onChange(this.boolean);
    },
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    slug: function () {
      return slugify(this.label);
    },
  },
  methods: {
    onChange: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
.c-input-boolean {
  &__inner {
    height: 32px;
    display: inline-flex;
    //background-color: var(--silver);
    border-radius: var(--border-radius-md);
    .btn {
      margin: var(--margin-lg);
    }
  }
  .onoffswitch {
    position: relative;
    width: 100px;
    height: 100%;
    user-select: none;
  }
  .onoffswitch-checkbox {
    display: none;
  }
  .onoffswitch-label {
    height: 100%;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: var(--border-radius-full);
    margin: 0;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 200ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    float: left;
    width: 50%;
    height: 32px;
    padding: 0 var(--spacer-sm);
    color: white;
    font-size: var(--font-size);
    box-sizing: border-box;
  }
  .onoffswitch-inner:before {
    content: "Yes";
    justify-content: flex-start;
    background-color: var(--color-button-primary);
  }
  .onoffswitch-inner:after {
    content: "No";
    padding-right: 14px;
    background-color: var(--color-bg-4);
  }
  .onoffswitch-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 68px;
    border: 4px solid var(--primary);
    border-radius: 20px;
    transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
  }
}
</style>
