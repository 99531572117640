<template>
  <div class="c-popup-categories">
    <Popup :active="active" :target="target" @onClose="onClose">
      <div class="c-popup-categories__header">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5 15.5L20.5 20.5M17.5 10.5C17.5 14.366 14.366 17.5 10.5 17.5C6.63401 17.5 3.5 14.366 3.5 10.5C3.5 6.63401 6.63401 3.5 10.5 3.5C14.366 3.5 17.5 6.63401 17.5 10.5Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <InputText v-model="search" placeholder="Search by niches"></InputText>
        <span class="c-popup-categories__reset" v-if="selectedTags.length > 0" @click="onResetTags">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 5L12 12M19 19L12 12M12 12L19 5L5 19"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <div class="c-popup-categories__body">
        <InputCheckbox v-model="selectedTags" :options="computedTags"></InputCheckbox>
      </div>
    </Popup>
  </div>
</template>

<script>
import Popup from "./index";
import InputCheckbox from "@swipekit/components/form/input-checkbox";
import InputText from "@swipekit/components/form/input-text";

import Fuse from "fuse.js";

export default {
  components: {
    Popup,
    InputCheckbox,
    InputText,
  },

  data: function () {
    return {
      selectedTags: [],
      search: "",
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    target: {},
    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  watch: {
    selectedTags: function () {
      this.$emit("input", this.selectedTags);
    },
  },

  computed: {
    computedTags: function () {
      let tags = this.tags;

      let options = {
        threshold: 0.3,
        keys: ["name"],
      };

      if (this.search) {
        const fuse = new Fuse(tags, options);

        let list = fuse.search(this.search);

        list = list.map((i) => {
          return i.item;
        });

        list = list.map((tag) => {
          let name = tag.name;
          if (tag.emoji) {
            name = `${tag.emoji} ${name}`;
          }
          return {
            rawName: tag.name,
            name,
            slug: tag.name,
          };
        });

        return list;
      } else {
        tags = tags.map((tag) => {
          let name = tag.name;
          if (tag.emoji) {
            name = `${tag.emoji} ${name}`;
          }
          return {
            rawName: tag.name,
            name,
            slug: tag.name,
          };
        });

        tags.sort((a, b) => {
          // Convert names to lowercase for case-insensitive sorting
          const nameA = a.rawName.toLowerCase();
          const nameB = b.rawName.toLowerCase();

          // Comparison logic
          if (nameA < nameB) {
            return -1; // 'a' comes before 'b'
          }
          if (nameA > nameB) {
            return 1; // 'a' comes after 'b'
          }
          return 0; // names are equal
        });

        return tags;
      }
    },
  },

  methods: {
    onClose: function () {
      this.$emit("onClose");
    },
    onResetTags: function () {
      this.selectedTags = [];
      this.$emit("resetTags");
    },
  },
};
</script>

<style lang="scss">
.c-popup-categories {
  .c-popup {
    width: 320px;
    max-height: 410px;
    overflow: hidden;
  }
  .c-input-checkbox {
    padding-bottom: 0;
    .c-input-checkbox__item {
      margin-bottom: 0;

      padding: var(--margin) var(--margin-lg);
    }
  }
  &__header {
    position: relative;
    height: 42px;
    padding: var(--margin-lg);
    padding-bottom: 0;
    .form-group {
      padding-bottom: 0;
    }
    .form-control {
      padding-left: var(--spacer);
      padding-right: 32px;
      background-color: var(--color-bg-1);
    }

    > svg {
      position: absolute;
      top: 14px;
      left: 14px;
      opacity: 0.75;
      pointer-events: none;
    }
  }
  &__body {
    height: 368px;
    overflow-y: scroll;
  }
  &__close {
    position: absolute;
    top: 12px;
    right: var(--margin-lg);
    opacity: 0.75;
    cursor: pointer;

    &:hover,
    &:active {
      opacity: 1;
    }
  }

  &__reset {
    position: absolute;
    top: 14px;
    right: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    cursor: pointer;
    user-select: none;

    svg {
      color: var(--color-danger);
      margin-right: var(--margin-sm);
    }
  }
}
</style>
