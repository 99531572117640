<template>
  <div :class="['c-onboarding2-showcase', size]">
    <img v-if="img" :src="img" />
  </div>
</template>

<script>
export default {
  props: {
    img: {},
    size: {},
  },
};
</script>

<style lang="scss">
.c-onboarding2-showcase {
  width: 140%;
  margin-left: -20%;
  height: auto;
  background-color: var(--color-primary);
  padding: var(--margin);
  border-radius: var(--margin);

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 2px;
  }

  &.small {
    width: 60%;
    margin-left: 20%;
  }
}
</style>
