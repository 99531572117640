<template>
  <div class="form-group c-input-seats">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <input
      :class="[{ 'form-control': noStyle === false }]"
      :id="slug"
      type="number"
      v-model="text"
      :placeholder="placeholder"
      ref="input"
      @change="onChange"
    />
    <small v-if="text > 0"> Buying {{ text }} seat<template v-if="text !== '1'">s</template> </small>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription v-if="description" :message="description"></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";
import InputNumber from "./input-number";

export default {
  components: {
    InputError,
    InputDescription,
    InputNumber,
  },

  data: function () {
    return {
      text: "",
    };
  },

  watch: {},

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  watch: {
    text: function () {
      this.$emit("input", this.text);
    },
    value: function () {
      let currentValue = this.text;

      let value = this.value;

      if (value !== currentValue) {
        this.text = value;
      }
    },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    onChange: function (e) {
      console.log(e);
    },
  },

  mounted: function () {
    if (this.value) {
      this.text = this.value;
    }
    this.$refs.input.onpaste = (e) => e.preventDefault();
  },
  beforeDestroy: function () {
    this.$refs.input.onpaste = null;
  },
};
</script>

<style lang="scss">
.c-input-seats {
  small {
    display: block;
    margin-bottom: var(--margin);
  }
}
</style>
