<template>
  <div :class="['form-group c-input-radio', { grid: isGrid === true }]">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-radio__inner">
      <div
        v-for="option in options"
        :key="option.name"
        :class="['c-input-radio__item', { active: selected === option.slug }]"
        @click="toggleSlug(option.slug)"
      >
        <input type="radio" name="input-tags" v-model="selected" :value="option.slug" />
        <label>
          <span class="dot"></span>
          <img v-if="option.image" :src="`${assetUrl}/${option.image}`" />
          <p>
            {{ option.name }}
          </p>
        </label>
      </div>
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription v-if="description" :message="description"></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      selected: "",
    };
  },

  watch: {
    selected: function () {
      this.onChange({
        target: {
          value: this.selected,
        },
      });
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    options: {},
    isGrid: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    toggleSlug: function (slug) {
      this.selected = slug;
    },
    slugify: function (str) {
      return str;
    },
    onChange: function (e) {
      let value = e.target.value;
      this.$emit("input", value);
    },
  },

  created: function () {
    let value = this.value;

    if (value) {
      this.selected = value;
    }

    // if value is 0
    if (!value && typeof value === "number") {
      this.selected = value;
    }
  },
};
</script>

<style lang="scss">
.c-input-radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    //border: var(--color-input-background) solid 1px;
    //border-radius: var(--border-radius);
  }

  &__item {
    display: flex;
    align-items: center;
    padding: var(--margin-lg);
    border-radius: 10px;
    margin-bottom: 0;
    background-color: transparent;
    transition: all 120ms ease-out;

    cursor: pointer;

    user-select: none;

    input {
      display: none;
    }

    &:hover {
      background-color: var(--color-bg-4);

      svg {
        opacity: 1;
      }
    }

    label {
      margin: 0;
      display: flex;
      align-items: center;
      font-size: var(--font-size);

      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        border-radius: var(--border-radius);
        margin-right: var(--margin);
      }

      p {
        margin: 0;
        font-size: var(--font-size-sm);
      }

      .dot {
        position: relative;
        min-width: 24px;
        width: 24px;
        height: 24px;
        border-radius: var(--border-radius-full);
        background-color: transparent;
        opacity: 0.5;
        margin-right: var(--margin);
        border: currentColor solid 2px;
        transition: all 120ms ease-out;

        &:after {
          content: "";
          position: absolute;
          top: calc(50% - 4px);
          left: calc(50% - 4px);
          width: 8px;
          height: 8px;
          border-radius: var(--border-radius-full);
          background-color: currentColor;
          opacity: 0;
          transition: all 120ms ease-out;
        }
      }
    }

    &.active {
      .dot {
        color: var(--color-primary);
        opacity: 1;

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &.grid {
    .c-input-radio__inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0px 0px;
    }
  }
}
</style>
