<template>
  <div v-tooltip="`This Ad is ${statusText}`" :class="['c-active-status', { active: active }]" v-if="hasStatus"></div>
</template>

<script>
export default {
  props: {
    block: {},
  },

  computed: {
    statusText: function () {
      let status = this.block.status;

      if (status) {
        status = status.toLowerCase();
        status = status.charAt(0).toUpperCase() + status.slice(1);

        return status;
      }

      return "";
    },
    active: function () {
      let b = this.block;

      if (b.status === "ACTIVE") {
        return true;
      }

      return false;
    },
    hasStatus: function () {
      let b = this.block;

      if (b.status) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
.c-active-status {
  position: absolute;
  z-index: 1;
  bottom: -3px;
  left: -3px;
  width: 12px;
  height: 12px;
  border-radius: var(--border-radius-full);
  background: linear-gradient(360deg, rgba(111, 111, 111, 1) 0%, rgba(154, 154, 154, 1) 100%);

  border: var(--color-button-font) solid 1px;

  &.active {
    background: linear-gradient(360deg, rgba(110, 171, 20, 1) 0%, rgba(180, 215, 38, 1) 100%);
  }
}
</style>
