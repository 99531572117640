<template>
  <div class="c-board-share">
    <a
      :href="`${baseUrl}?share-board=${boardId}`"
      class="btn btn-fancy btn-lg"
      title="Save this entire board to your Swipekit account"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 4L17 12L9 20"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span>Save this board </span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    boardId: {},
  },

  computed: {
    baseUrl: function () {
      return `http://localhost:8080`;
    },
  },
};
</script>

<style lang="scss">
.c-board-share {
  z-index: 1;
  position: fixed;
  bottom: var(--spacer);
  left: var(--spacer);

  .btn-fancy {
    --f1: hsl(var(--p), 80%, 59%);
    --f2: hsl(var(--p), 68%, 50%);
    --f3: hsl(var(--p), 80%, 59%);
    padding-left: var(--spacer-sm);
    color: #fff;
    background-image: linear-gradient(to right, var(--f1) 0%, var(--f2) 51%, var(--f3) 100%);

    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: var(--box-shadow);

    > svg {
      margin-right: var(--margin-lg);
    }

    &:hover {
      background-position: right center; /* change the direction of the change here */
      box-shadow: 0 0 0 3px var(--f2) !important;
    }
  }
}
</style>
