<template>
  <div class="c-page-tiktok">
    <Page :i="3">
      <span class="c-byline"> Tutorial #2: Tiktok Creative Center </span>
      <h1>Save Ads from the Tiktok Creative Center</h1>
      <article class="article-text text-center">
        <p>Save winning Ad creatives from the Tiktok Creative center</p>
        <p>This Ad will be available forever inside your Swipekit account, even if the original Ad is removed.</p>
      </article>
      <div class="c-showcase c-showcase--small">
        <img src="/images/onboarding-tiktok-cc.png" />
      </div>
      <ButtonCta :timer="3" @onClick="onClick">
        <span> Got it </span>
      </ButtonCta>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
export default {
  components: {
    Page,
    ButtonCta,
  },

  methods: {
    onClick: function () {
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss">
.c-page-tiktok {
}
</style>
