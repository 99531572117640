<template>
  <div class="c-box">
    <div class="c-box__header">
      <slot name="header"> </slot>
    </div>
    <div class="c-box__body">
      <slot name="body"> </slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.c-box {
  border: var(--color-bg-5) solid 1px;
  border-radius: var(--border-radius);
  overflow: hidden;

  &__header {
    background-color: var(--color-bg-3);
    border-bottom: var(--color-bg-5) solid 1px;
    padding: var(--spacer-sm);

    > h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 0;
    }
  }
  &__body {
    padding: var(--spacer-sm);
  }
}
</style>
