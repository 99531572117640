<template>
  <div class="c-block-view-labels">
    <label
      v-if="showAdActive"
      :class="[`c-block-view-labels__item ${block.status.toLowerCase()}`]"
      v-tooltip="tooltip(`Is this Ad actively running right now`)"
    >
      <span> </span>
      <p>{{ adActive }}</p>
    </label>
    <label v-if="showActiveDays" v-tooltip="tooltip(`How long this Ad was running for`)">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 12H6L9 3L15 21L18 12H22"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>Active for {{ activeDays }} days</p>
    </label>
    <label v-if="showAdSpend" v-tooltip="tooltip(`Estimated Ad Spend(In EU only)`)">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 7.5V6.5M12 16.5V17.5M14.1655 9C13.7333 8.4022 12.9254 8 12 8H11.7222C10.4949 8 9.5 8.79594 9.5 9.77778V9.8541C9.5 10.5564 9.99598 11.1984 10.7812 11.5125L13.2188 12.4875C14.004 12.8016 14.5 13.4436 14.5 14.1459C14.5 15.1699 13.4624 16 12.1824 16H12C11.0746 16 10.2667 15.5978 9.83446 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>${{ format(adSpend) }} Est EU Ad spend</p>
    </label>
    <label v-if="showAdReach" v-tooltip="tooltip(`Estimated Ad Reach(In EU only)`)">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 7H21V12M20.5 7.5L13 15L9 11L3 17"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>{{ format(adReach) }} Est EU reach</p>
    </label>
    <label v-if="stat.views" v-tooltip="tooltip(`How many views this Ad got`)">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 5L9 19M3.00439 11L3 19M21 8L21 19M14.9978 14L14.9978 19"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>
        {{ format(stat.views) }}
      </p>
    </label>
    <label v-if="stat.assetType" v-tooltip="tooltip(`What is this Ad's primary asset format`)">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 3V2C2.44772 2 2 2.44772 2 3H3ZM15 3H16C16 2.44772 15.5523 2 15 2V3ZM14 9V10H16V9H14ZM3 15H2C2 15.5523 2.44772 16 3 16V15ZM9 16H10V14H9V16ZM9 9V8C8.44772 8 8 8.44772 8 9H9ZM21 9H22C22 8.44772 21.5523 8 21 8V9ZM9 21H8C8 21.5523 8.44772 22 9 22V21ZM21 21V22C21.5523 22 22 21.5523 22 21H21ZM2.4453 11.1679C1.98577 11.4743 1.8616 12.0952 2.16795 12.5547C2.4743 13.0142 3.09517 13.1384 3.5547 12.8321L2.4453 11.1679ZM6 10L6.5547 9.16795C6.2188 8.94402 5.7812 8.94402 5.4453 9.16795L6 10ZM8.4453 12.8321C8.90483 13.1384 9.5257 13.0142 9.83205 12.5547C10.1384 12.0952 10.0142 11.4743 9.5547 11.1679L8.4453 12.8321ZM6.25 5.25C5.69772 5.25 5.25 5.69772 5.25 6.25C5.25 6.80228 5.69772 7.25 6.25 7.25V5.25ZM6.26 7.25C6.81228 7.25 7.26 6.80228 7.26 6.25C7.26 5.69772 6.81228 5.25 6.26 5.25V7.25ZM14 13.4L14.5795 12.585C14.2746 12.3682 13.874 12.3397 13.5415 12.5113C13.2089 12.6829 13 13.0258 13 13.4H14ZM14 16.6H13C13 16.9742 13.2089 17.3171 13.5415 17.4887C13.874 17.6603 14.2746 17.6318 14.5795 17.415L14 16.6ZM16.25 15L16.8295 15.815C17.0933 15.6274 17.25 15.3237 17.25 15C17.25 14.6763 17.0933 14.3726 16.8295 14.185L16.25 15ZM3 4H15V2H3V4ZM14 3V9H16V3H14ZM2 3V15H4V3H2ZM3 16H9V14H3V16ZM9 10H21V8H9V10ZM8 9V21H10V9H8ZM9 22H21V20H9V22ZM20 9V21H22V9H20ZM3.5547 12.8321L6.5547 10.8321L5.4453 9.16795L2.4453 11.1679L3.5547 12.8321ZM5.4453 10.8321L8.4453 12.8321L9.5547 11.1679L6.5547 9.16795L5.4453 10.8321ZM5.5 6.25C5.5 5.83579 5.83579 5.5 6.25 5.5V7.5C6.94036 7.5 7.5 6.94036 7.5 6.25H5.5ZM6.25 5.5C6.66421 5.5 7 5.83579 7 6.25H5C5 6.94036 5.55964 7.5 6.25 7.5V5.5ZM7 6.25C7 6.66421 6.66421 7 6.25 7V5C5.55964 5 5 5.55964 5 6.25H7ZM6.25 7C5.83579 7 5.5 6.66421 5.5 6.25H7.5C7.5 5.55964 6.94036 5 6.25 5V7ZM6.25 7.25H6.26V5.25H6.25V7.25ZM13 13.4V16.6H15V13.4H13ZM14.5795 17.415L16.8295 15.815L15.6705 14.185L13.4205 15.785L14.5795 17.415ZM16.8295 14.185L14.5795 12.585L13.4205 14.215L15.6705 15.815L16.8295 14.185Z"
          fill="currentColor"
        />
      </svg>
      <p>
        {{ stat.assetType }}
      </p>
    </label>
  </div>
</template>

<script>
import stats from "@swipekit/lib/stats";

export default {
  props: {
    block: {},
  },

  computed: {
    stat: function () {
      let block = this.block;
      let s = stats(block);

      return s;
    },
    showAdActive: function () {
      if (this.block.type === "facebook") {
        return true;
      }

      return false;
    },
    showActiveDays: function () {
      if (this.block.type === "facebook" && this.block.activeDays && this.block.activeDays > 0) {
        return true;
      }
      if (this.block.type === "tiktok-library" && this.block.activeDays && this.block.activeDays > 0) {
        return true;
      }

      return false;
    },
    showAdSpend: function () {
      if (this.block.type === "facebook" && this.block.meta.isEuAd) {
        return true;
      }

      return false;
    },
    showAdReach: function () {
      if (this.block.type === "facebook" && this.block.meta.isEuAd) {
        return true;
      }

      return false;
    },
    showAssetType: function () {
      return true;
    },

    adActive: function () {
      let s = "";
      if (this.block.status === "ACTIVE") {
        s = "Active";
      }
      if (this.block.status === "INACTIVE") {
        s = "Inactive";
      }

      return `Ad ${s}`;
    },
    activeDays: function () {
      if (this.block.activeDays > 0) {
        return this.block.activeDays;
      }

      return 0;
    },
    adSpend: function () {
      let spend = Math.round(this.block.meta.eu_ad_spend);

      if (spend === 0) {
        spend = 1;
      }

      spend = spend.toLocaleString("en-US");

      return spend;
    },
    adReach: function () {
      let reach = this.block.meta.eu_total_reach;

      reach = reach.toLocaleString("en-US");

      return reach;
    },
  },

  methods: {
    tooltip: function (content) {
      return { content: content, offset: 4, delay: 0 };
    },
    format: function (num) {
      let newNum = parseInt(num);
      if (isNaN(newNum)) {
        return num;
      }

      return num.toLocaleString("en-US");
    },
  },
};
</script>

<style lang="scss">
.c-block-view-labels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacer-sm);

  label {
    display: flex;
    align-items: center;
    padding: var(--margin);
    //border: var(--color-bg-4) solid 1px;
    border-radius: var(--border-radius);
    margin-right: var(--margin);
    background-color: var(--color-bg-3);
    font-weight: 450;
    font-size: var(--font-size-sm);
    font-feature-settings: "case" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "subs" 0, "sups" 0, "tnum", "zero" 0,
      "ss01", "ss02" 0, "ss03" 0, "ss04", "ss05" 0, "ss06" 0, "ss07" 0, "ss08" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0,
      "cv05" 0, "cv06" 0, "cv07" 0, "cv08" 0, "cv09" 0, "cv10" 0, "cv11" 0, "cv12" 0, "cv13" 0, "cpsp" 0, "c2sc" 0,
      "salt" 0, "aalt" 0, "calt", "ccmp", "locl", "kern";

    > svg {
      margin-right: var(--margin);
      opacity: 0.65;
    }

    > span {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: var(--margin);
      background-color: var(--color-bg-6);
      border-radius: var(--border-radius-sm);
    }

    > p {
      line-height: 19px;
      margin-bottom: 0;
    }

    &.active {
      > span {
        background-color: var(--color-success);
      }
    }
  }
}
</style>
