<template>
  <div v-if="isAllowed" :class="['c-item-copy', { expanded: expanded === true }]">
    <strong v-if="adTitle">{{ adTitle }}</strong>
    <article>{{ adCopy }}</article>
    <span v-if="showShowMore" class="click-prevent" @click.prevent="toggleExpansion"> {{ copyText }} </span>
    <div class="c-grid-item__label" v-if="labels">3</div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      expanded: false,
    };
  },
  props: {
    labels: {
      type: Boolean,
      default: false,
    },
    block: {},
  },
  computed: {
    showShowMore: function () {
      let max = 114;
      if (this.adCopy && this.adCopy.length > max) {
        return true;
      }

      return false;
    },
    isAllowed: function () {
      if (this.block.type === "tiktok-top-ads") {
        return false;
      }
      if (this.block.type === "tiktok-ad-library") {
        return false;
      }
      if (this.block.type === "tiktok") {
        return false;
      }
      if (!this.block.adCopy) {
        return false;
      }

      return true;
    },
    copyText: function () {
      if (this.expanded) {
        return "Hide copy";
      }
      return "Show copy";
    },
    adTitle: function () {
      if (this.block.title) {
        return this.block.title;
      }

      return null;
    },
    adCopy: function () {
      let copy = this.block.adCopy;

      return copy;
    },
  },
  methods: {
    toggleExpansion: function () {
      this.expanded = !this.expanded;

      this.$nextTick(() => {
        this.$redrawVueMasonry();
      });
    },
  },
};
</script>

<style lang="scss">
.c-item-copy {
  position: relative;
  padding: var(--margin) var(--margin);

  > strong {
    display: block;
    font-size: var(--font-size-xs);

    white-space: break-spaces;
    position: relative;

    font-size: 12px;

    max-height: 35px;
    overflow: hidden;
    font-feature-settings: "case" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "subs" 0, "sups" 0, "tnum", "zero" 0,
      "ss01", "ss02" 0, "ss03" 0, "ss04", "ss05" 0, "ss06" 0, "ss07" 0, "ss08" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0,
      "cv05" 0, "cv06" 0, "cv07" 0, "cv08" 0, "cv09" 0, "cv10" 0, "cv11" 0, "cv12" 0, "cv13" 0, "cpsp" 0, "c2sc" 0,
      "salt" 0, "aalt" 0, "calt", "ccmp", "locl", "kern";
  }
  > article {
    font-size: var(--font-size-xs);

    white-space: break-spaces;
    position: relative;

    font-size: 12px;

    max-height: 35px;
    overflow: hidden;
    font-feature-settings: "case" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "subs" 0, "sups" 0, "tnum", "zero" 0,
      "ss01", "ss02" 0, "ss03" 0, "ss04", "ss05" 0, "ss06" 0, "ss07" 0, "ss08" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0,
      "cv05" 0, "cv06" 0, "cv07" 0, "cv08" 0, "cv09" 0, "cv10" 0, "cv11" 0, "cv12" 0, "cv13" 0, "cpsp" 0, "c2sc" 0,
      "salt" 0, "aalt" 0, "calt", "ccmp", "locl", "kern";

    // &:after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 2px;
    //   background-color: hsla(40, 90%, 70%, 1);
    //   opacity: 0;
    // }

    // &:hover,
    // &:active {
    //   background-color: hsla(40, 90%, 90%, 1);
    //   color: #000;

    //   &:after {
    //     opacity: 1;
    //   }
    // }
  }

  > span {
    display: block;
    font-size: 12px;
    color: var(--color-primary);
    text-decoration: underline;
  }

  &.expanded {
    > article {
      max-height: initial;
    }
  }
}
</style>
