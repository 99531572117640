<template>
  <div :class="['c-picker-create-board', { active: active === true }]">
    <form @submit.prevent="onSubmit">
      <svg @click="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 18L18 6M6 6L18 18"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <input type="text" v-model="name" placeholder="Enter board name" />
      <span>
        <button type="submit">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 13L9 18L20 6"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </span>
    </form>
  </div>
</template>

<script>
//import createBoard from "@/lib/create-board";

const createBoard = () => {};

export default {
  data: function () {
    return {
      name: "",
      processing: false,
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    baseUrl: {},
  },

  methods: {
    close: function () {
      this.name = "";
      this.$emit("closeCreateBoard");
    },
    onSubmit: async function () {
      this.processing = true;

      let name = this.name;

      if (!name) {
        return;
      }

      if (name.length < 3) {
        return;
      }

      let board = await createBoard(this.baseUrl, name);

      this.processing = false;

      if (board) {
        this.$emit("createBoard", board);
      }
    },
  },
};
</script>

<style></style>
