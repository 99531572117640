<template>
  <form class="c-form-unsubscribe" @submit.prevent="onSubmit">
    <Form
      :processing="processing"
      ref="form"
      :schema="schema"
      v-model="form"
      buttonText="Unsubscribe"
      @status="saveStatus"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  reason: {
    component: "InputCheckboxGroup",
    label: "Reason for unsubscribing",
    options: [
      "I no longer need to save Ads",
      "Technical issue - can't save Ads, or other technical issues",
      "I'm moving jobs, or I'm no longer working on Ads",
      "I'm switching to another solution",
      "I can no longer afford this",
      "Other",
    ],
    validations: {
      required: {
        params: null,
        message: "A valid reason is required.",
      },
    },
  },
};
export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: schema,
      processing: false,
      form: {},
      errors: [],
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      form = JSON.parse(JSON.stringify(form));

      if (form.reason && typeof form.reason === "object") {
        form.reason = form.reason.join(",");
      }

      let res = null;

      const condition = await this.$store
        .dispatch("workspaces/cancelSubscription", {
          description: form.reason,
        })
        .catch((err) => {
          console.log(err);
        });

      if (condition) {
        await this.$store.dispatch("message/onUnsubscribe");
      } else {
        this.errors.push({
          message: "Something went wrong, please try again later",
        });
      }

      this.$emit("onSubmit");

      this.processing = false;

      return;

      try {
        res = await this.$store.dispatch("users/updatePassword", form);
      } catch (err) {
        console.log(err);
        if (err.length === 0) {
          err.push({
            message: "Something went wrong, please send another reset password request.",
          });
        }
        this.errors = err;
        this.processing = false;
        return;
      }

      await this.$store.dispatch("afterLogin");

      await this.$store.dispatch("message/onPasswordReset");

      this.$emit("onSubmit");

      this.processing = false;
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.c-form-unsubscribe {
  .c-input-checkbox-group {
    &__inner {
      margin-bottom: var(--margin);
      grid-template-columns: 1fr;
    }
  }
}
</style>
