<template>
  <div class="c-block-view-section">
    <article>
      <Copy v-if="text" :text="text"></Copy>

      <strong>
        {{ title }}
      </strong>
      <Tooltip v-if="description" :description="description"></Tooltip>
    </article>
    <div class="c-block-view-section__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Copy from "./copy";
import Tooltip from "@swipekit/components/utils/tooltip";

export default {
  components: {
    Tooltip,
    Copy,
  },

  props: {
    title: {},
    text: {
      default: "",
    },
    description: {},
  },
};
</script>

<style lang="scss">
.c-block-view-section {
  display: grid;
  grid-template-columns: 134px 1fr;
  margin-bottom: var(--margin);

  > article {
    display: flex;
    user-select: none;

    > strong {
      display: inline-block;
      opacity: 0.8;
      font-weight: 500;
      font-size: var(--font-size-sm);
      padding-right: var(--margin);
    }
    > span {
      display: inline-block;
      font-size: var(--font-size-xs);
      opacity: 0.85;
      line-height: 1.3;
    }

    .c-block-view-copy {
      margin-right: var(--margin);
    }
  }

  @media screen and (max-width: 600px) {
    display: block;
  }
}
</style>
