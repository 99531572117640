<template>
  <div class="c-onboarding2">
    <transition name="slide-fade" v-on:after-leave="onAfterLeave">
      <!-- 0 -->
      <PageCheck
        :processingActivation="processingActivation"
        v-if="currentFlow === 'check'"
        key="check"
        @onNext="onNext"
      >
      </PageCheck>
      <PageIntro v-if="currentFlow === 'intro'" key="intro" @onNext="onNext"> </PageIntro>
      <PageAttribution v-if="currentFlow === 'attribution'" key="attribution" @onNext="onNext"></PageAttribution>
      <PageSetupExtension v-if="currentFlow === 'setup-extension'" key="setup-extension" @onNext="onNext">
      </PageSetupExtension>
      <PageFb v-if="currentFlow === 'facebook-ad-library'" key="facebook-ad-library" @onNext="onNext"> </PageFb>
      <PageIntegrations v-if="currentFlow === 'integrations'" key="integrations" @onNext="onNext"> </PageIntegrations>
      <PageAdCard :tempAd="tempAd" v-if="currentFlow === 'ad-card'" key="ad-card" @onNext="onNext"> </PageAdCard>
      <PageAdCardCustomize
        :tempAd="tempAd"
        v-if="currentFlow === 'ad-card-customize'"
        key="ad-card-customize"
        @onNext="onNext"
      >
      </PageAdCardCustomize>
      <PageAdLibrary :tempAd="tempAd" v-if="currentFlow === 'ad-library'" key="ad-library" @onNext="onNext">
      </PageAdLibrary>
      <PageTiktok v-if="currentFlow === 'tiktok'" key="tiktok" @onNext="onNext"> </PageTiktok>
      <PageLinkedin v-if="currentFlow === 'linkedin'" key="linkedin" @onNext="onNext"> </PageLinkedin>
      <PageMultiplayer v-if="currentFlow === 'multiplayer'" key="multiplayer" @onNext="onNext"> </PageMultiplayer>
      <PageBoards v-if="currentFlow === 'boards'" key="boards" @onNext="onNext"> </PageBoards>
      <PageTeam v-if="currentFlow === 'team'" key="team" @onNext="onNext"> </PageTeam>
      <PageOutro v-if="currentFlow === 'outro'" key="outro" @onNext="onNext"> </PageOutro>
    </transition>
    <div class="c-onboarding2__breadcrumbs">
      <span
        v-for="(stageee, i) in flow"
        :key="stageee"
        :class="[{ active: stageee === currentFlow }, { activated: i <= flow.indexOf(currentFlow) }]"
      ></span>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import FormUser from "@swipekit/components/form/form-user.vue";
import FormBoardCreateUser from "@swipekit/components/form/form-board-create-user";
import FormWorkspaceCreate from "@swipekit/components/form/form-workspace-create";

import PageIntro from "./page-intro.vue";
import PageCheck from "./page-check.vue";
import PageAttribution from "./page-attribution";
import PageSetupExtension from "./page-setup-extension.vue";
import PageFb from "./page-fb";
import PageIntegrations from "./page-integrations";
import PageAdCard from "./page-ad-card";
import PageAdCardCustomize from "./page-ad-card-customize";
import PageAdLibrary from "./page-ad-library";
import PageLinkedin from "./page-linkedin.vue";
import PageTiktok from "./page-tiktok.vue";
import PageMultiplayer from "./page-multiplayer";
import PageBoards from "./page-boards";
import PageTeam from "./page-team";
import PageOutro from "./page-outro";

export default {
  components: {
    FormUser,
    FormBoardCreateUser,
    FormWorkspaceCreate,
    PageCheck,
    PageIntro,
    PageAttribution,
    PageSetupExtension,
    PageFb,
    PageIntegrations,
    PageAdCard,
    PageAdCardCustomize,
    PageAdLibrary,
    PageLinkedin,
    PageTiktok,
    PageMultiplayer,
    PageBoards,
    PageTeam,
    PageOutro,
  },

  data: function () {
    return {
      tempAd: null,
      stages: [0, 1, 2, 3, 4],
      stage: 0,
      lock: false,
      touched: false,
      workspaceChanged: false,

      processingActivation: false,

      currentFlow: "check",
      flow: [
        "check",
        "setup-extension",
        "facebook-ad-library",
        "ad-card",
        "ad-card-customize",
        "integrations",
        "boards",
        // Here, add reference to board saving
        //"ad-library", // We'll re add this in the near future
        "team",
        "attribution",
        "outro",
      ],
    };
  },

  watch: {
    blocks: function () {
      this.touched = true;
    },
    initialStage: {
      handler: function () {
        this.stage = this.initialStage;
      },
      immediate: true,
    },
    stage: function () {
      if (this.stage === 4) {
        // silently update user's onboarded flag
        let form = {
          onboarded: true,
        };
        let response = null;
        response = this.$store.dispatch("users/update", form).catch((err) => {
          console.log(err);
          // also update user config
          this.$store.dispatch("users/updateMarketing");
        });
      }
    },
    workspace: function (newWorkspace, oldWorkspace) {
      if (
        !this.workspaceChanged &&
        this.workspace &&
        this.workspace.activated &&
        oldWorkspace &&
        !oldWorkspace.activated
      ) {
        this.workspaceChanged = true;
        if (this.extensionState) {
          this.currentFlow = "facebook-ad-library";
        } else {
          this.currentFlow = "setup-extension";
        }
      }
    },
  },

  props: {
    initialStage: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    query: function () {
      let query = this.$route.query;
      return query;
    },
    workspace: function () {
      return this.$store.getters["workspaces/all"];
    },
    blocks: function () {
      return this.$store.getters["blocks/all"];
    },
    boards: function () {
      return this.$store.getters["boards/filtered"];
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    addId: function () {
      return false;
    },
    extensionState: function () {
      return this.$store.getters["extensionState"];
    },
  },

  methods: {
    completeOnboarding: function () {
      let form = {
        onboarded: true,
      };
      let response = null;
      // don't wait for await async
      response = this.$store.dispatch("users/update", form).catch((err) => {
        console.log(err);
        // also update user config
        this.$store.dispatch("users/updateMarketing");
      });
      this.$store.dispatch("app/toggleOnboarding", false);
      setTimeout(() => {
        this.$store.dispatch("app/refreshFilters"); // also refresh filters so that ad they saved shows up.
      }, 500);
    },
    onNext: function (tempAd) {
      if (tempAd) {
        this.tempAd = tempAd;
      }
      let i = this.flow.indexOf(this.currentFlow);
      let newFlow = this.flow[i + 1];
      if (!newFlow) {
        // assume flow to be complete
        this.completeOnboarding();
        return;
      }

      // meaning a user is already added
      if (newFlow === "team" && this.workspace.users.length > 1) {
        newFlow = "outro";
      }

      // If newFlow was 'setup-extension' and extension is already installed, skip to the next step
      if (newFlow === "setup-extension" && this.extensionState) {
        newFlow = this.flow[i + 2];
      }

      this.currentFlow = newFlow;
    },
    onGotoMulti: function () {
      this.$store.dispatch("app/toggleOnboarding", false);

      this.$router.push("/multiplayer");
    },
    onSetupWorkspace() {
      if (this.extensionState) {
        // basically, if the extension is installed, skip the install browser extension phase
        this.stage = 4;
      } else {
        this.stage = 3;
      }
    },
    async onSubmitUser() {
      this.stage = 3;
    },
    async onSubmitBoard() {
      let message = {
        message: "You just created your first board!",
        status: "success",
      };

      this.$store.dispatch("message/sendMessage", message);

      this.stage = 3;
    },
    onAfterLeave: function () {
      let tabId = this.stage;
      //this.onShow(tabId);
    },
    onShow: function (tabId) {
      this.$nextTick(() => {
        let tl = anime.timeline({
          easing: "easeOutExpo",
          duration: 750,
        });

        let selector = `.tab-${tabId} .fade-up`;

        tl.add({
          targets: selector,
          translateY: 0,
          opacity: 1,
          delay: anime.stagger(150, { start: 500 }),
        });
      });
    },
    onClose: function (url) {
      if (url) {
        window.open(url, "_blank");
      }
      this.$store.dispatch("app/toggleOnboarding", false);
    },
    onStageOne: function () {
      let user = this.user;

      if (user && user.marketing && Object.keys(user.marketing).length > 3) {
        this.stage = 3;
      } else {
        this.stage = 1;
      }
    },
    async init() {
      // by default, move users to the check step(activation step)
      this.currentFlow = "check";

      let query = this.$route.query;

      let workspace = this.workspace;

      // Don't do anything if workspace not found. Niche edgecase
      if (!workspace) {
        return;
      }

      // if already activated, show the ad-library(start) of the onboarding
      if (workspace.activated) {
        if (this.extensionState) {
          this.currentFlow = "facebook-ad-library";
        } else {
          // else, ask them to setup the extension
          this.currentFlow = "setup-extension";
        }
        return;
      }

      // if not activated
      if (!workspace.activated) {
        // if query.token(for activation) is present, attempt to activate the user
        if (query && query.token) {
          this.processingActivation = true;
          const res = await this.$store.dispatch("workspaces/verifyToken", query.token);

          if (res) {
            await this.$store.dispatch("message/onEmailVerified");
            // If extension is installed, move the to pagefb section
            if (this.extensionState) {
              this.currentFlow = "facebook-ad-library";
            } else {
              // else, educate the user on the extension
              this.currentFlow = "setup-extension";
            }
          }

          this.processingActivation = false;

          return;
        }

        // finally, if workspace still isn't activated and no token is found, throw them back to the check section
        this.currentFlow = "check";
        return;
      }
    },
  },

  mounted: async function () {
    this.init();
  },
};
</script>

<style lang="scss">
.c-onboarding2 {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  --gap: 20px;

  > div:not(:last-child) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__breadcrumbs {
    display: flex;
    position: absolute;

    bottom: var(--margin-lg);
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-bg-5);

    span {
      margin: var(--spacer) var(--spacer-sm);
      margin-bottom: 0;
      background-color: currentColor;
      opacity: 0.5;
      width: 10px;
      height: 10px;
      border-radius: var(--spacer);

      &.activated {
        opacity: 1;
      }

      &.active {
        background-color: hsl(var(--p), 80%, 75%);
      }
    }
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
    transition: all 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 400ms;
  }

  .slide-fade-leave-active {
    transition: all 600ms ease;
  }
  .slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(100px);
    opacity: 0;
  }

  .slide-fade-leave-to {
    transform: translateY(-100px);
    opacity: 0;
  }
}
</style>
