<template>
  <div class="c-networks" v-if="computedNetworks && computedNetworks.length > 0">
    <div class="c-networks__grid">
      <div class="c-networks__grid__item" v-for="network in computedNetworks" :key="network.slug">
        <img :src="network.logo" />
        <span>
          {{ network.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
  },

  computed: {
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    computedNetworks: function () {
      let networks = this.block.networks || [];

      if (this.block.type !== "facebook") {
        return;
      }

      let newNetworks = [];

      if (networks.length > 10) {
        // something is fishy
        return [];
      }

      for (let i = 0; i < networks.length; i++) {
        let network = networks[i];

        let str = "N/A";
        if (network === "facebook") {
          str = "Facebook";
        }
        if (network === "instagram") {
          str = "Instagram";
        }
        if (network === "audience_network") {
          str = "Audience Network";
        }
        if (network === "messenger") {
          str = "Messenger";
        }

        let obj = {
          title: str,
          slug: network,
          logo: `/brands/${network}.svg`,
        };

        newNetworks.push(obj);
      }

      return newNetworks;
    },
  },
};
</script>

<style lang="scss">
.c-networks {
  padding-top: var(--margin);

  h5 {
    font-size: var(--font-size-xs);
    margin: 0;
    text-transform: initial;
  }

  &__grid {
    font-size: var(--font-size-xs);

    &__item {
      margin-bottom: 2px;
      img {
        width: 12px;
        height: 12px;
        margin-bottom: -2px;
      }
    }
  }
}
</style>
