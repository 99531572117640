<template>
  <div class="c-onboarding2-ad-card">
    <Page :i="2">
      <!-- <span class="c-byline"> Tutorial #5: Boards </span> -->
      <h1>You just saved your first Ad!</h1>
      <article class="article-text">
        <p>Here's the info Swipekit will extract about the Ad:</p>
      </article>
      <div class="c-onboarding2-ad-card__grid">
        <section>
          <Item :hideAdModal="true" :labels="true" view="all" v-if="computedAd" :ad="computedAd"></Item>
        </section>
        <section>
          <ol>
            <li>Ad asset. Hover your mouse over this area and click on the top right button to download this Ad.</li>
            <li>Click here to embed the Ad inside Notion, Clickup, etc or share it with others using a public link.</li>
            <li>Ad title, copy and CTA link all in one accessible place.</li>
            <li>
              Ad metadata section. Use the Ad active days to find how long an Ad ran for. Longer running Ads = generally
              better performing Ads.
            </li>
          </ol>
        </section>
      </div>
      <ButtonCta :timer="2" @onClick="$emit('onNext')">
        <span> Continue </span>
      </ButtonCta>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
import Item from "@swipekit/components/item";

export default {
  components: {
    Page,
    ButtonCta,
    Item,
  },

  props: {
    tempAd: {},
  },

  computed: {
    computedAd: function () {
      if (!this.tempAd) {
        return;
      }
      return this.tempAd;
    },
  },

  methods: {
    onClick: function (e) {
      if (e && !e.target) {
        this.$store.dispatch("message/sendMessage", {
          message: "Board created",
          status: "success",
        });
      }
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss">
.c-onboarding2-ad-card {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .c-grid-item {
      width: 100%;
    }
  }
}
</style>
