<template>
  <div class="c-modal-board-create">
    <Modal v-model="options.active" @input="closeModal" :closable="true">
      <template v-slot:title>
        <h2>Create Board</h2>

        <p class="article-text">
          Use Boards to curate your collection. Create boards for specific hooks, angles, clients, etc.
        </p>
      </template>
      <template>
        <FormBoardCreate @onSubmit="onSubmit"></FormBoardCreate>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "./index.vue";
import FormBoardCreate from "@swipekit/components/form/form-board-create.vue";
export default {
  components: {
    Modal,
    FormBoardCreate,
  },

  data: function () {
    return {
      options: {
        name: "modal-tracker-create",
        active: false,
      },
    };
  },

  watch: {
    "options.active": {
      handler: function () {},
    },
    addId: function () {
      if (this.addId) {
        this.options.active = true;
      } else {
        this.options.active = false;
      }
    },
  },

  computed: {
    addId: function () {
      return this.$store.getters["boards/addId"];
    },
  },

  methods: {
    onSubmit: function () {
      this.$store.dispatch("triggerExtension", { type: "boards:update" });
      this.$store.dispatch("message/onBoardCreate");
      this.options.active = false;
    },
    activate: function () {
      this.options.active = true;
    },
    closeModal: function () {
      this.$store.dispatch("boards/setAdd", null);
    },
  },

  mounted: function () {
    //this.options.active = true;
  },
};
</script>

<style lang="scss"></style>
