<template>
  <form class="c-form-user-add" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Add teammate"
      buttonClass="btn-lg"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  email: {
    component: "InputEmail",
    label: "Your teammates's email",
    placeholder: "name@acme.com",
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      schema: schema,
      form: {
        name: "",
      },
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      form.emails = [form.email];

      delete form.email;

      let res = await this.$store.dispatch("workspaces/invite", form);

      this.processing = false;

      this.$nextTick(() => {
        this.reset();
      });

      this.$emit("onSubmit", true);
    },
    reset: function () {
      this.form = {};
    },
  },
};
</script>

<style></style>
