<template>
  <div class="c-page-outro">
    <Page :i="7">
      <h1>Support & housekeeping</h1>
      <p>You have been onboarded!</p>
      <article class="article-text">
        <p>Remember, all your saved Ads are stored permanently in your Swipekit Library.</p>
        <p>If you have any trouble, we have a helpful documentation section.</p>

        <h4>Documentation</h4>
        <p>We have a comprehensive documentation & help section to help you resolve issues.</p>
        <p>
          If you still run into any snags, ask for support via the in-app chat widget at the bottom right corner of the
          screen.
        </p>

        <h4>Support</h4>
        <p>Support for trial users can take upto 3 business days, but we usually resolve issues within a few hours.</p>
        <p>We aim to resolve support issues for paid users within 1 business day.</p>

        <p>Happy saving!</p>
      </article>
      <ButtonCta :timer="2" @onClick="onClick">
        <span> Take me to my Library </span>
      </ButtonCta>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
export default {
  components: {
    Page,
    ButtonCta,
  },

  methods: {
    onClick: function () {
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss"></style>
