<template>
  <div class="c-block-view-transcript">
    <template v-if="texts && transcript.status === 'DONE'">
      <button v-if="!expanded" type="button" class="btn btn-icon btn-primary btn-box" @click="expand">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15 10H9M12 14H9M3 20H16C18.7614 20 21 17.7614 21 15V9C21 6.23858 18.7614 4 16 4H8C5.23858 4 3 6.23858 3 9V20Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span> View Transcript </span>
      </button>
      <article v-if="expanded">
        <p v-for="(text, i) in texts" :key="i">
          {{ text }}
        </p>
      </article>
    </template>
    <template v-if="transcript.status === 'ACTIVE'">
      <small> Transcript creation in process. Check back in a few minutes. </small>
    </template>
    <template v-if="transcript.status === 'ERROR'">
      <small> Transcript couldn't be created. Most likely because the video doesn't have voice in it. </small>
    </template>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      expanded: false,
    };
  },

  props: {
    transcript: {},
  },

  computed: {
    texts: function () {
      if (!this.transcript) {
        return null;
      }

      if (!this.transcript.text) {
        return null;
      }

      return this.transcript.text;
    },
  },

  methods: {
    expand: function () {
      this.expanded = true;

      let payload = {
        name: `User viewed transcript`,
      };

      this.$emit("event", payload);
    },
  },
};
</script>

<style lang="scss">
.c-block-view-transcript {
  p {
    font-size: var(--font-size-sm);
    font-feature-settings: "case" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "subs" 0, "sups" 0, "tnum", "zero" 0,
      "ss01", "ss02" 0, "ss03" 0, "ss04", "ss05" 0, "ss06" 0, "ss07" 0, "ss08" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0,
      "cv05" 0, "cv06" 0, "cv07" 0, "cv08" 0, "cv09" 0, "cv10" 0, "cv11" 0, "cv12" 0, "cv13" 0, "cpsp" 0, "c2sc" 0,
      "salt" 0, "aalt" 0, "calt", "ccmp", "locl", "kern";
  }
}
</style>
