<template>
  <div class="c-lp-renderer">
    <div class="c-lp-renderer__desktop">
      <img :src="desktop" />
    </div>
    <div class="c-lp-renderer__mobile">
      <img :src="mobile" />
    </div>
  </div>
</template>

<script>
import chroma from "chroma-js";

export default {
  props: {
    block: {},
    single: {
      type: Boolean,
      default: false,
    },
    standalone: {
      type: Boolean,
      default: false,
    },
    multiplayer: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hsl: function () {
      let primaryColor = this.block.screenshotBlock.primaryColor || "#DDD";

      const hsl = chroma(primaryColor).hsl();

      if (isNaN(hsl[0])) {
        hsl[0] = -1;
      }

      if (hsl[0] === -1) {
        hsl[1] = 0;
        hsl[2] = 0.925;
      } else {
        hsl[1] = 0.85;
        hsl[2] = 0.7;
      }

      return hsl;
    },
    style: function () {
      const hsl = this.hsl;

      let hue = hsl[0];

      let saturation = hsl[1];

      let luminance = hsl[2];

      if (luminance < 0.4) {
        saturation = 0.2;
      }

      if (luminance < 0.5) {
        luminance = 0.5;
      }

      let color1 = chroma(hue, saturation, luminance, "hsl").hex();
      let color2 = chroma(hue + 8, saturation, luminance - 0.15, "hsl").hex();

      //console.log(color1, color2);

      const gradient = `linear-gradient(180deg, ${color1} 0%, ${color2}`;

      return {
        background: gradient,
      };
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    desktop: function () {
      let block = this.block;

      const screenshotBlock = block.screenshotBlock;

      if (!screenshotBlock) {
        return;
      }

      let url = ``;

      for (let i = 0; i < screenshotBlock.assets.length; i++) {
        const asset = screenshotBlock.assets[i];
        if (asset.subType === "screenshot-desktop") {
          url = `${this.assetUrl}/${asset.assetPath}`;
          break;
        }
      }

      return url;
    },
    mobile: function () {
      let block = this.block;

      const screenshotBlock = block.screenshotBlock;

      if (!screenshotBlock) {
        return;
      }

      let url = ``;

      for (let i = 0; i < screenshotBlock.assets.length; i++) {
        const asset = screenshotBlock.assets[i];
        if (asset.subType === "screenshot-mobile") {
          url = `${this.assetUrl}/${asset.assetPath}`;
          break;
        }
      }

      return url;
    },
  },
};
</script>

<style lang="scss">
.c-lp-renderer {
  position: relative;
  overflow-y: auto;
  display: grid;
  height: 100%;
  grid-template-columns: 1.25fr 0.75fr;
  grid-column-gap: var(--spacer);
  padding-top: var(--spacer);
  padding-left: var(--spacer);
  padding-right: var(--spacer);
  padding-bottom: var(--spacer);

  > div {
    position: relative;
  }

  &__desktop {
  }

  img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.02), 0 2px 4px 1px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
  }
}
</style>
