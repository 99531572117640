<template>
  <form class="c-form-block-update" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Update"
      :processing="processing"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  boards: {
    component: "InputBoardsDropdown",
    label: "Boards",
  },
  tags: {
    component: "InputTags",
    label: "Tags",
    description: "Add tags to better organize your Ads.",
  },
  // public: {
  //   component: "InputPublicLink",
  //   label: "Make this public?",
  // },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      currentForm: {},
      status: null,
      errors: [],
    };
  },

  props: {
    form: {},
  },

  computed: {
    schema: function () {
      let s = schema;

      //s.public.slug = this.form.slug;

      return s;
    },
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      let response = await this.$store.dispatch("ads/update", form).catch((err) => {
        console.log(err);
        this.$store.dispatch("message/onServerError");
      });

      this.processing = false;

      if (!response) {
        return;
      }

      this.$store.dispatch("message/onAdUpdate");

      this.$emit("onSubmit");
    },
  },

  created: function () {
    this.currentForm = this.form;
  },
};
</script>

<style></style>
