let store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async init(store) {
      const env = process.env.VUE_APP_NODE_ENV || process.env.NODE_ENV || "development";
    },

    onClientError(store, err) {
      console.log("CLIENT ERROR");
      //console.log(err);
    },

    onServerError(store, err) {
      console.log("SERVER ERROR");
      //console.log(err);
    },

    sendEvent: function (store, event) {
      if (typeof posthog === "undefined") {
        console.log("posthog undefined");
        return;
      }
      let eventName = event.name;
      delete event.name;
      window.posthog.capture(eventName, {
        ...event,
      });
    },
  },
};

export default store;
