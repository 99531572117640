<template>
  <div class="c-onboarding2-attribution">
    <Page :i="8">
      <h1>Last bit</h1>
      <span class="c-byline"> How did you find us? </span>
      <FormUser @onSubmit="onSubmit"></FormUser>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
import FormBoardCreateUser from "@swipekit/components/form/form-board-create-user";
import FormUser from "@swipekit/components/form/form-user";

export default {
  components: {
    Page,
    ButtonCta,
    FormBoardCreateUser,
    FormUser,
  },

  methods: {
    onSubmit: function (e) {
      if (e && !e.target) {
        this.$store.dispatch("message/sendMessage", {
          message: "Board created",
          status: "success",
        });
      }
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss">
.c-onboarding2-attribution {
}
</style>
