<template>
  <div ref="list" :class="['c-picker-list', { active: active === true }]">
    <template v-if="list.length > 0">
      <div
        v-for="item in list"
        :key="item.id"
        @click.prevent="toggleSelected(item)"
        :style="item.style"
        :class="[{ active: item.active === true }]"
        :title="item.description || ''"
      >
        <article>
          <p>
            {{ item.name }}
          </p>
          <span> {{ item.createdAt }} </span>
        </article>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 13L9 18L20 6"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>
    <section v-if="list.length === 0" class="c-picker-list__empty-state">
      <p>No boards found. <a href="#" @click="onCreateBoard">Create one</a>.</p>
    </section>
  </div>
</template>

<script>
const { format } = require("date-fns");

export default {
  data: function () {
    return {
      loaded: false,
    };
  },

  props: {
    selected: {},
    baseUrl: {},
    active: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    active: function () {
      if (!this.active) {
        return;
      }

      if (this.loaded) {
        return;
      }
    },
  },

  computed: {
    boards: function () {
      return this.$store.getters["boards/filtered"];
    },
    list: function () {
      let selected = this.selected;

      let items = this.boards || [];

      items = JSON.parse(JSON.stringify(items));

      items = items.map((item) => {
        let i = selected.indexOf(item.id);

        item.active = false;

        item.createdAt = format(new Date(item.createdAt), `do MMM yyyy`);

        if (i === -1) {
        } else {
          item.active = true;
        }

        return item;
      });

      items = items.sort((a, b) => {
        return a.order - b.order;
      });

      return items;
    },
  },

  methods: {
    refresh: function () {
      //this.loadBoards();
    },
    onCreateBoard: function () {
      this.$emit("onCreateBoard");
    },
    toggleSelected: function (item) {
      let i = this.selected.indexOf(item.id);
      let selected = this.selected;
      if (i === -1) {
        selected.push(item.id);
      } else {
        let i = selected.indexOf(item.id);
        selected.splice(i, 1);
      }

      this.$emit("change", selected);
    },
    handleClickOutside(event) {
      if (!this.active) {
        return;
      }

      let condition = event.target.closest(".c-picker");

      if (!condition) {
        this.$emit("clickOutside");
      }

      // if (!condition) {
      //   this.$emit("clickOutside");
      // }
    },
  },

  mounted() {
    document.body.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style></style>
