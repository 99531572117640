<template>
  <div class="c-workspace-seats">
    <div class="c-workspace-seats__item c-workspace-seats__item--header">
      <div class="c-workspace-seats__column">
        <span> User </span>
      </div>
      <div class="c-workspace-seats__column">
        <span> Type </span>
      </div>
      <div class="c-workspace-seats__column">
        <span> Actions </span>
      </div>
    </div>
    <div class="c-workspace-seats__item" v-for="(seat, i) in seats" :key="i">
      <div class="c-workspace-seats__column">
        <img v-if="seat.avatar" :src="`${assetUrl}/${seat.avatar.name}`" />
        <span v-if="seat.name"> {{ seat.name }} </span>
        <span class="email"> {{ seat.email }} </span>
      </div>
      <div class="c-workspace-seats__column">
        <span class="label" v-if="isAdmin(seat)">
          Workspace Admin user

          <Tooltip :description="workspaceAdminDescription"></Tooltip>
        </span>
        <span class="label" v-if="!isAdmin(seat) && seat.type === 'NORMAL'">
          Regular user
          <Tooltip :description="regularUserDescription"></Tooltip>
        </span>
        <span class="label" v-if="!isAdmin(seat) && seat.type === 'INACTIVE'">
          Regular user - invited
          <Tooltip :description="regularUserDescription"></Tooltip>
        </span>
      </div>
      <div class="c-workspace-seats__column" v-if="isWorkspaceAdmin">
        <button class="btn btn-danger" v-if="!isAdmin(seat) && seat.type !== 'N/A'" @click="onRemoveUser(seat.id)">
          Remove User
        </button>
      </div>
      <div class="c-workspace-seats__column" v-else>
        <small> Only Workspace Admin can remove members. </small>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "@swipekit/components/utils/tooltip";

export default {
  components: {
    Tooltip,
  },

  props: {
    workspace: {},
  },

  computed: {
    isWorkspaceAdmin: function () {
      return this.$store.getters["workspaces/isAdmin"];
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    workspaceAdminDescription: function () {
      let str = `The Workspace Admin has all permissions.\n There can be only one Workspace Admin`;
      return str;
    },
    regularUserDescription: function () {
      let str = `A regular user can save Ads, comment on Ads and delete Ads.\n But they can't manage other users in a workspace`;
      return str;
    },
    seats: function () {
      let workspace = this.workspace;

      let seats = [];

      let users = this.workspace.users || [];

      let workspaceLimit = this.workspace.workspaceLimit;

      for (let i = 0; i < workspaceLimit; i++) {
        let user = users[i];
        if (!user) {
          seats.push({
            name: "Unoccupied",
            id: i,
            type: "N/A",
          });
          continue;
        }
        let seat = {
          name: user.name,
          email: user.email,
          avatar: user.avatar,
          id: user.id,
          type: user.type,
        };
        seats.push(seat);
      }

      return seats;
    },
  },

  methods: {
    isAdmin: function (seat) {
      if (seat.id === this.workspace.adminId) {
        return true;
      }
      return false;
    },
    onRemoveUser: function (userId) {
      let form = {
        userId: userId,
      };

      this.$store.dispatch("message/onRemovingUser");

      this.$store.dispatch("workspaces/removeUser", form);
    },
  },
};
</script>

<style lang="scss">
.c-workspace-seats {
  overflow: hidden;
  margin-bottom: var(--spacer-sm);
  border-radius: var(--border-radius);
  border: var(--color-bg-5) solid 1px;

  &__item {
    display: grid;
    grid-template-columns: 1.5fr 0.75fr 0.75fr;
    gap: 0px 0px;
    &:not(:last-child) {
      border-bottom: var(--color-bg-5) solid 1px;
    }

    &--header {
      background-color: var(--color-bg-3);
    }
  }

  &__column {
    display: flex;
    align-items: center;
    padding: var(--margin-lg);

    img {
      width: var(--spacer-lg);
      height: var(--spacer-lg);
      border-radius: var(--border-radius);
      margin-right: var(--margin-lg);
      object-fit: cover;
      object-position: center center;
    }

    .email {
      display: inline-block;
      margin-left: var(--margin);
      opacity: 0.75;
    }

    &:last-child:not(:first-child) {
      justify-content: right;
    }
  }

  .label {
    font-size: var(--font-size-xs);
    text-transform: uppercase;
    padding: var(--margin-sm) var(--margin);
    border-radius: var(--border-radius);
    border: var(--color-bg-4) solid 1px;
  }
}
</style>
