<template>
  <div class="c-library" @scroll="onScroll">
    <PageHeader byline="Library">
      <template v-slot:title> All your Ads, in one place </template>
    </PageHeader>
    <LibraryList v-if="init" ref="list" :adIds="adIds" @onSubmit="onSave"></LibraryList>
  </div>
</template>

<script>
import PageHeader from "@swipekit/components/utils/page-header";
import LibraryList from "./list";
import api from "@swipekit/lib/api";

export default {
  name: "Multiplayer",

  components: {
    PageHeader,
    LibraryList,
  },

  data: function () {
    return {
      init: false,
      adIds: [],
    };
  },

  props: {
    standalone: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let buffer = 100;
      if (scrollTop + clientHeight >= scrollHeight - buffer) {
        this.onEnd();
      } else {
      }
    },
    onSave: function (e) {
      const blockId = e.blockId;

      this.$store.dispatch("ads/refreshAll");

      this.adIds.push(blockId);
    },
    onEnd: function () {
      this.$refs.list.onEnd();
    },
    async getIds() {
      let ids = await api.ads.getAdIds();

      this.adIds = ids;

      this.init = true;
    },
  },

  mounted: function () {
    this.getIds();
  },
};
</script>

<style lang="scss">
.c-library {
  height: 100vh;
  position: relative;
  overflow-y: auto;
}
</style>
