<template>
  <div class="c-message">
    <transition-group name="list" tag="div">
      <Alert v-for="message in stack" :key="message.id" :message="message" @onClose="onClose(message)"></Alert>
    </transition-group>
  </div>
</template>

<script>
import Alert from "./alert";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    Alert,
  },

  data: function () {
    return {
      limit: 6,
      stack: [],
    };
  },

  watch: {
    messageTrigger: function () {
      this.showMessage(this.message);
    },
  },

  computed: {
    message: function () {
      return this.$store.getters["message/message"];
    },
    messageTrigger: function () {
      return this.$store.getters["message/messageTrigger"];
    },
  },

  methods: {
    showMessage(message) {
      message.timer = message.timer || 4000;
      message.status = message.status || ""; //success, warning, ''
      this.addToStack(message);
    },
    onClose: function (message) {
      this.removeFromStack(message.id);
    },
    removeFromStack: function (id) {
      let index = -1;
      let messages = this.stack;

      for (let i = 0; i < messages.length; i++) {
        if (messages[i].id === id) {
          index = i;
          break;
        }
      }

      if (index === -1) {
        return;
      }

      this.stack.splice(index, 1);
    },
    addToStack: function (message) {
      //this.$store.dispatch("sounds/play", "onMessage");
      message = {
        ...message,
        id: uuidv4(),
      };
      this.stack.unshift(message);

      if (this.stack.length === this.limit) {
        this.stack.pop();
      }
    },
  },

  mounted: function () {
    // this.showMessage({
    //   message: "test",
    //   timer: 100000,
    //   status: "failure",
    // });
  },
};
</script>

<style lang="scss">
.c-message {
  position: fixed;
  z-index: 1050;
  bottom: 0;
  left: 0;
  width: 100%;

  pointer-events: none;

  .list-item {
    display: block;
  }
  .list-enter-active {
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .list-leave-active {
    position: absolute;
    transition: all 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }
  .list-move {
    transition: all 300ms;
  }
  .list-enter/* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  .list-leave-to {
    opacity: 0;
    transform: translate(50px, 0);
  }
}
</style>
