<template>
  <div class="form-group c-input-checkbox">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-checkbox__inner">
      <div
        v-for="option in options"
        :key="option.name"
        :class="['c-input-checkbox__item', { active: selected.includes(option.slug) }]"
        @click="toggleSlug(option.slug)"
      >
        <input type="checkbox" name="input-tags" v-model="selected" :value="option.slug" />
        <label>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
              fill="currentColor"
            />
          </svg>

          {{ option.name }}
        </label>
      </div>
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription v-if="description" :message="description"></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      selected: [],
    };
  },

  watch: {
    selected: function () {
      this.onChange({
        target: {
          value: this.selected,
        },
      });
    },
    value: function () {
      let valueArr = this.value || [];

      let currentArr = this.selected || [];

      if (valueArr.length !== currentArr.length) {
        this.selected = this.value;
      }
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    options: {},
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    toggleSlug: function (slug) {
      if (this.selected.includes(slug)) {
        let i = this.selected.indexOf(slug);
        if (i > -1) {
          this.selected.splice(i, 1);
        }
      } else {
        this.selected.push(slug);
      }
    },
    slugify: function (str) {
      return str;
    },
    onChange: function (e) {
      let value = e.target.value;
      this.$emit("input", value);
    },
  },

  created: function () {
    let value = this.value;

    if (value && value.length > 0) {
      // let temp = value.map((board) => {
      //   return board.boardId;
      // });
      this.selected = value; //temp;
    }
  },
};
</script>

<style lang="scss">
.c-input-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    //border: var(--color-input-background) solid 1px;
    //border-radius: var(--border-radius);
  }

  &__item {
    display: flex;
    align-items: center;
    padding: var(--margin-lg);
    border-radius: 10px;
    margin-bottom: var(--margin-lg);
    background-color: transparent;

    cursor: pointer;

    user-select: none;

    input {
      display: none;
    }

    &:hover {
      background-color: var(--color-bg-4);

      svg {
        opacity: 1;
      }
    }

    label {
      margin: 0;
      display: flex;
      align-items: center;
      font-size: var(--font-size);

      cursor: pointer;

      svg {
        min-width: 24px;
        opacity: 0.5;
        margin-right: var(--margin);
      }
    }

    &.active {
      svg {
        color: var(--color-primary);
        opacity: 1;
      }
    }
  }
}
</style>
