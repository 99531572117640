<template>
  <div class="c-input-description">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.c-input-description {
  display: flex;
  padding-bottom: var(--margin-lg);
  margin: 0 0 0;
  font-size: var(--font-size-xs);
  opacity: 0.75;
}
</style>
