<template>
  <div class="c-onboarding2-page">
    <!-- <div class="c-onboarding2-page__number">
      <span>{{ i }}</span>
    </div> -->
    <div class="c-onboarding2-page__inner">
      <div class="c-onboarding2-page__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    i: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
.c-onboarding2-page {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .c-showcase {
    width: 140%;
    margin-left: -20%;
    height: auto;
    background-color: var(--color-primary);
    padding: var(--margin);
    border-radius: var(--margin);

    img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 2px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-1);
    box-shadow: inset 0 1px 0 1px rgba(255, 255, 255, 1), 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  }

  &__number {
    position: absolute;
    z-index: 1;
    top: calc(32px + var(--gap) / 2);
    left: calc(32px + var(--gap) / 2);
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: var(--color-bg-5);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0 1px rgba(255, 255, 255, 1), inset 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    font-weight: 500;
    font-family: var(--font-family-monospace);
  }

  &__inner {
    position: relative;
    z-index: 1;
    height: calc(100% - var(--gap) * 2);
    min-height: 100%;
    overflow-y: auto;

    padding-top: calc(var(--gap) * 4);
    padding-bottom: calc(var(--gap) * 4);

    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }

  &__content {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: var(--font-size-xxl);
    text-align: center;
    line-height: 1.15;
  }

  hr {
    padding: var(--margin-lg) 0;
  }

  .article-text {
    font-size: 17px;
  }

  .text-center {
    text-align: center;
  }

  p {
    font-size: var(--font-size-lg);
  }

  .center {
    text-align: center;
  }

  .small {
    font-size: var(--font-size-sm);
  }

  .c-byline {
    display: block;
    margin-bottom: var(--margin);
    font-size: var(--font-size-sm);
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;
    opacity: 0.75;
  }

  ul.fancy-list {
    list-style: none;
    padding-left: 0;

    li {
      display: grid;
      grid-template-columns: 32px 1fr;
      margin-left: var(--spacer-sm);

      p:last-child {
        margin-bottom: 0;
      }

      svg {
        margin-top: var(--margin);
        color: var(--color-primary);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    --gap: 10px;
  }
}
</style>
