<template>
  <div class="form-group c-input-radio-group">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-radio-group__inner">
      <div
        v-for="option in computedOptions"
        :key="option.key"
        :class="['c-input-radio-group__item', { active: selected === option.key }]"
        @click="toggleSlug(option.key)"
      >
        <input type="radio" name="input-options" v-model="selected" :value="option.value" />
        <label>
          <span class="dot"></span>

          {{ option.value }}
        </label>
      </div>
    </div>
    <div class="c-input-radio-group__other">
      <InputText placeholder="Other" v-model="otherText" v-if="selected === 'Other'"></InputText>
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription v-if="description" :message="description"></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";
import InputText from "./input-text";

export default {
  components: {
    InputError,
    InputDescription,
    InputText,
  },

  data: function () {
    return {
      otherText: "",
      selected: "",
    };
  },

  watch: {
    otherText: function () {
      let str = `Other | ${this.otherText}`;
      this.onChange({
        target: {
          value: str,
        },
      });
    },
    selected: function () {
      if (this.selected === "Other") {
        this.otherText = "";
      }
      this.onChange({
        target: {
          value: this.selected,
        },
      });
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    options: {},
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
    computedOptions: function () {
      let arr = [];
      let options = this.options;
      for (let i = 0; i < options.length; i++) {
        if (typeof options[i] === "string") {
          arr.push({
            key: options[i],
            value: options[i],
          });
        } else {
          arr.push(options[i]);
        }
      }
      return arr;
    },
  },

  methods: {
    toggleSlug: function (key) {
      if (this.selected === key) {
        this.selected = null;
      } else {
        this.selected = key;
      }
    },
    slugify: function (str) {
      return str;
    },
    onChange: function (e) {
      let value = e.target.value;
      this.$emit("input", value);
    },
  },

  created: function () {
    let value = this.value;

    if (value) {
      this.selected = value;
    }
  },
};
</script>

<style lang="scss">
.c-input-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__other {
    margin-top: var(--margin-lg);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    border: var(--color-input-background) solid 1px;
    border-radius: var(--border-radius);
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 0px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: var(--margin-lg);

    cursor: pointer;

    user-select: none;

    input {
      display: none;
    }

    &:hover {
      background-color: var(--color-bg-4);
    }

    label {
      margin: 0;
      display: flex;
      align-items: center;
      font-size: var(--font-size);

      cursor: pointer;

      .dot {
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: var(--border-radius-full);
        background-color: transparent;
        opacity: 0.5;
        margin-right: var(--margin);
        border: currentColor solid 2px;

        &:after {
          content: "";
          position: absolute;
          top: calc(50% - 4px);
          left: calc(50% - 4px);
          width: 8px;
          height: 8px;
          border-radius: var(--border-radius-full);
          background-color: currentColor;
          opacity: 0;
        }
      }
    }

    &.active {
      .dot {
        color: var(--color-primary);
        opacity: 1;

        &:after {
          opacity: 1;
        }
      }
    }
  }
}
</style>
