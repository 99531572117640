<template>
  <div class="c-modal-extension-helper">
    <Modal v-model="options.active" @input="closeModal" :closable="true">
      <template v-slot:title>
        <h2>{{ title }}</h2>
        <p>
          {{ description }}
        </p>
      </template>
      <template>
        <ul class="article-text" v-if="!extensionState">
          <li>
            Head to the
            <a
              target="_blank"
              href="https://chrome.google.com/webstore/detail/swipekit-free-fb-ad-libra/gojmmkhaiojimnnjhhilmhjmhdbdagod"
              >Chrome Webstore</a
            >
          </li>
          <li>Install the extension</li>
          <li>
            That's it. The extension will authenticate itself with the webapp once it's installed.
            <br />
            Go to the Fb ads library after this and start saving ads.
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "./index.vue";
import FormBlockCreate from "@swipekit/components/form/form-block-create.vue";
export default {
  components: {
    Modal,
    FormBlockCreate,
  },

  data: function () {
    return {
      options: {
        active: false,
      },
    };
  },

  watch: {
    "options.active": {
      handler: function () {},
    },
    addId: function () {
      if (this.addId) {
        this.options.active = true;
      } else {
        this.options.active = false;
      }
    },
  },

  computed: {
    addId: function () {
      return this.$store.getters["app/extensionHelperId"];
    },
    extensionState: function () {
      return this.$store.getters["extensionState"];
    },
    title: function () {
      let extensionState = this.extensionState;

      if (extensionState) {
        return `Swipekit extension is connected`;
      }
      return `Swipekit extension isn't connected`;
    },
    description: function () {
      let extensionState = this.extensionState;

      if (extensionState) {
        return `You will be able to save Ads.`;
      }
      return `You won't be able to save Ads. Follow the instructions below to start saving Ads.`;
    },
  },

  methods: {
    onSubmit: function () {
      this.options.active = false;
    },
    activate: function () {
      this.options.active = true;
    },
    closeModal: function () {
      this.$store.dispatch("app/showExtensionHelper", false);
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.c-modal-extension-helper {
  .modal__title {
    padding-right: var(--spacer-lg);
    text-align: left;
  }
  .modal__content {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        padding-left: var(--spacer);
        margin-bottom: var(--margin);

        &:before {
          content: counter(line-number);
          position: absolute;
          top: 4px;
          left: 0;
          width: 24px;
          height: 24px;
          line-height: 1.9;
          font-size: var(--font-size-xs);
          font-family: var(--font-family-heading);
          color: var(--color-button-font);
          border-radius: var(--border-radius-full);
          background-color: var(--color-font);
          text-align: center;
        }
        counter-increment: line-number;
      }
    }
  }
}
</style>
