<template>
  <div class="c-comment-header">
    <img
      v-if="comment.user && comment.user.avatar"
      :title="`${comment.name}'s profile`"
      :src="`${assetUrl}/${comment.user.avatar.name}`"
    />
    <article>
      <span v-if="comment.user"> {{ comment.user.name }} </span>
      <span class="date" v-if="createdAt" :title="createdAtLiteral">{{ createdAt }}</span>
    </article>
  </div>
</template>

<script>
import parseISO from "date-fns/parseISO";
import formatDistance from "date-fns/formatDistance";

export default {
  props: {
    comment: {},
  },

  computed: {
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    createdAt: function () {
      let comment = this.comment;
      let date = comment.createdAt;

      if (!date) {
        return null;
      }

      date = parseISO(date);

      date = formatDistance(date, new Date());

      date = `${date} ago`;

      return date;
    },
    createdAtLiteral: function () {
      let comment = this.comment;
      let date = comment.createdAt;

      if (!date) {
        return null;
      }

      date = parseISO(date);

      return date;
    },
  },
};
</script>

<style lang="scss">
.c-comment-header {
  padding: var(--margin-lg);
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;

  img {
    width: var(--spacer);
    height: var(--spacer);
    border-radius: var(--border-radius-full);
    margin-right: var(--margin-lg);
    object-fit: cover;
    object-position: center center;
  }

  .date {
    opacity: 0.65;
    font-size: var(--font-size-xs);
  }

  article {
    display: flex;
    flex-direction: column;
  }
}
</style>
