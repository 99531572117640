<template>
  <div class="c-landing-pages">
    <SearchBar v-model="params" @search="onSearch" @view="onView"></SearchBar>
    <div class="c-landing-pages__search">
      <Item
        v-for="block in computedBlocks"
        :multiplayer="true"
        :ad="block"
        :key="block.id"
        :view="view"
        @onSubmit="onSubmit"
        v-masonry-tile
      ></Item>
    </div>
    <div class="c-landing-pages__empty-state" v-if="end && blocks.length > 0">
      <div class="c-multplayer__empty-state__inner">
        <h5>End of results</h5>
      </div>
    </div>
    <div class="c-landing-pages__empty-state" v-if="blocks.length === 0 && !processing">
      <div class="c-landing-pages__empty-state__inner">
        <h5>No Ads found.</h5>
        <p>Try using other filters.</p>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from "@swipekit/components/utils/search-bar";
import Item from "./item";

import filter from "@swipekit/lib/filter";
import { getParams, setParams } from "@swipekit/lib/query-param-utils";

export default {
  components: {
    SearchBar,
    Item,
  },

  data: function () {
    return {
      containerId: null,
      params: {
        query: "",
        network: "all",
        mediaType: "all",
        activeAds: 0,
      },
      blocks: [],
      skip: 0,
      take: 0,

      end: false,

      view: [],
    };
  },

  props: {
    adIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  watch: {
    $route: function () {},
    refreshFilters: function () {
      this.filter();
    },
  },

  computed: {
    refreshFilters: function () {
      return this.$store.getters["app/refreshFilters"];
    },
    computedBlocks: function () {
      let blocks = this.blocks;

      if (!blocks) {
        return [];
      }

      let ids = this.adIds;

      for (let i = 0; i < blocks.length; i++) {
        let isSaved = false;

        if (ids.includes(blocks[i].id)) {
          isSaved = true;
        }

        blocks[i].isSaved = isSaved;
      }

      blocks = blocks.map((b) => {
        return {
          block: b,
        };
      });

      return blocks;
    },
    processing: function () {
      return this.$store.getters["app/processing"];
    },
  },

  methods: {
    async onView(view) {
      this.view = view;

      this.$nextTick(() => {
        this.$redrawVueMasonry();
      });
    },
    onSubmit: function (e) {
      this.$emit("onSubmit", e);
    },
    applyParams: function (params) {
      if (params.query) {
        this.params.query = params.query;
      }

      if (params.skip) {
        this.skip = params.skip;
      }
      if (params.take) {
        this.take = params.take;
      }

      if (params.mediaType) {
        this.params.mediaType = params.mediaType;
      }

      if (params.activeAds) {
        this.params.activeAds = params.activeAds;
      }

      if (params.network) {
        this.params.network = params.network;
      }
    },
    onEnd: function () {
      if (this.processing) {
        return;
      }
      if (this.end) {
        return;
      }
      this.skip += this.take;
      this.filter();
    },
    async onSearch(params) {
      this.blocks = [];
      this.skip = 0;
      this.end = false;
      await this.filter(params);
    },
    async filter(params, firstTime = false) {
      if (this.processing) {
        return;
      }
      this.$store.dispatch("app/setProcessing", true);

      if (firstTime) {
        params = getParams();

        // if first time, force 1st page
        params.skip = 0;

        this.applyParams(params);
      }
      if (!params) {
        params = getParams();
      }

      params.skip = this.skip;
      params.take = this.take;

      setParams(params);

      const blocks = await filter(params, "filter-landing-pages");

      if (blocks.length < this.take) {
        this.end = true;
      }
      this.blocks = this.blocks.concat(blocks);

      this.$nextTick(() => {
        this.$redrawVueMasonry();
        this.$store.dispatch("app/setProcessing", false);
      });
    },
  },

  mounted: async function () {
    this.skip = 0;
    this.take = 40;
    this.filter(null, true);
  },
};
</script>

<style lang="scss">
.c-landing-pages {
  &__search {
    max-width: 1400px;
    margin: 0 auto;
  }
  &__empty-state {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__inner {
      display: inline-block;
      margin: 0 auto;
      border: var(--color-bg-4) solid 1px;
      border-radius: 10px;
      padding: var(--spacer-sm);

      p {
        margin-bottom: 0;
      }
    }
  }
  &__empty-state {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__inner {
      display: inline-block;
      margin: 0 auto;
      border: var(--color-bg-4) solid 1px;
      border-radius: 10px;
      padding: var(--spacer-sm);

      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
