<template>
  <div class="c-multiplayer-page" v-if="isAuth" @scroll="onScroll">
    <Multiplayer ref="multi"></Multiplayer>
  </div>
</template>

<script>
import Multiplayer from "@swipekit/components/multiplayer";

export default {
  components: {
    Multiplayer,
  },

  computed: {
    isAuth: function () {
      return this.$store.getters["users/isAuth"];
    },
    standalone: function () {
      return this.$store.getters["standalone"];
    },
  },

  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let buffer = 200;
      if (scrollTop + clientHeight >= scrollHeight - buffer) {
        this.$refs.multi.onEnd();
      } else {
      }
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.c-multiplayer-page {
  height: 100vh;
  position: relative;
  overflow-y: auto;
}
</style>
