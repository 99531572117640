<template>
  <div class="c-item-image-carousel">
    <div class="c-item-image-carousel__inner">
      <carousel :perPage="1">
        <slide v-for="(image, key) in block.meta.images" :key="key">
          <img v-if="image" :src="`${assetUrl}/${image.name}`" />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
  },

  computed: {
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
  },
};
</script>

<style lang="scss">
.c-item-image-carousel {
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .VueCarousel {
    height: 100%;
  }

  .VueCarousel-inner {
    height: 100% !important;
  }

  .VueCarousel-wrapper {
    height: 100%;
  }

  .VueCarousel-pagination {
    padding-bottom: var(--margin);
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .VueCarousel-dot-container {
    margin-top: 0 !important;
  }

  .VueCarousel-dot {
    padding: 4px !important;
    margin: var(--margin) !important;
    background-color: #fff !important;
    box-shadow: var(--box-shadow);

    &--active {
      background-color: var(--color-primary) !important;
    }
  }

  .VueCarousel-slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
}
</style>
