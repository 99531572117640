<template>
  <div class="c-comment">
    <div class="c-comment__grid" v-if="comments.length > 0">
      <CommentItem v-for="comment in comments" :comment="comment" :key="comment.id"></CommentItem>
    </div>
    <div class="c-comment__empty-state" v-else>
      <p>No comments in here.</p>
    </div>
    <CommentEdit :adId="adId"></CommentEdit>
  </div>
</template>

<script>
import CommentItem from "./item.vue";

import CommentEdit from "./edit.vue";

export default {
  components: {
    CommentItem,
    CommentEdit,
  },

  props: {
    adId: {},
  },

  computed: {
    user: function () {
      return this.$store.getters["users/user"];
    },
    comments: function () {
      return this.$store.getters["comments/byAd"](this.adId);
    },
  },

  data: function () {
    return {
      loading: false,
    };
  },

  mounted: function () {
    this.$store.dispatch("comments/getAdComment", this.adId);
  },
};
</script>

<style lang="scss">
.c-comment {
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  //border: var(--color-primary) solid 2px;

  &__grid {
  }

  .c-comment-edit {
    box-shadow: 0 -1px 0 1px var(--color-bg-4);
    //background-color: var(--color-bg-2);
  }

  .c-comment-item {
    border-bottom: var(--color-bg-5) solid 1px;

    &:last-child {
      border-bottom: none;
    }
  }

  &__empty-state {
    padding: var(--spacer-sm);
    text-align: center;
    opacity: 0.75;

    p {
      margin-bottom: var(--margin);
    }
  }
}
</style>
