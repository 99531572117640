<template>
  <div class="c-onboarding">
    <transition name="slide" v-on:after-leave="onAfterLeave">
      <!-- 0 -->
      <div v-if="stage === 0" key="s0" class="tab tab-0">
        <h1>
          Welcome to<br />
          Swipekit, {{ user.name }}
        </h1>

        <ul class="article-text">
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              />
            </svg>

            <p>
              Save Ads from our Ads collection(Multiplayer), the Meta Ad Library, Tiktok Top Ads, and from any Linkedin
              company page
            </p>
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              />
            </svg>

            <p>Spy on your competitor's Ads. Learn their strategy and save performant Ad creatives for inspo.</p>
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              />
            </svg>

            <p>Build a collection of effective Ads. Organize them inside boards and share them with your clients.</p>
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                fill="currentColor"
              />
            </svg>

            <p>Bring your entire team and collect Ads together in one platform.</p>
          </li>
        </ul>
        <button class="btn btn-lg btn-primary btn-press" @click="onStageOne">Let's go</button>
      </div>

      <!-- 1 -->
      <div v-if="stage === 1" key="s1" class="tab tab-1">
        <h1>Let's get you started</h1>
        <FormUser @onSubmit="onSubmitUser"></FormUser>
      </div>

      <!-- 2 -->
      <div v-if="stage === 2" key="s2" class="tab tab-2">
        <h1>Let's setup your workspace</h1>
        <FormWorkspaceCreate :form="workspace" @onSubmit="onSetupWorkspace"></FormWorkspaceCreate>
      </div>

      <!-- 3 -->
      <div v-if="stage === 3" key="s3" class="tab tab-3">
        <h1 class="header-text">Install the Chrome Extension</h1>
        <p class="article-text">
          Our Chrome Extension enables you to save Ads<br />
          from the Meta Ad Library, Tiktok Top Ads and Linkedin Ads<br />
          at the click of a button!
        </p>

        <a
          class="btn btn-lg btn-primary btn-press"
          href="https://chrome.google.com/webstore/detail/swipekit-free-fb-ad-libra/gojmmkhaiojimnnjhhilmhjmhdbdagod"
          target="_blank"
        >
          Install the extension
        </a>
        <a href="#" @click="stage = 4"> Skip — I'll install it later </a>
      </div>

      <!-- 4 -->
      <div v-if="stage === 4" key="s4" class="tab tab-4">
        <article class="article-text">
          <h3>You've been onboarded!</h3>
          <p>Start saving Ads from these sources:</p>
          <div class="buttons">
            <a
              href="#"
              class="btn btn-lg btn-primary btn-press"
              @click.prevent="onClose('https://www.facebook.com/ads/library')"
            >
              Meta Ad library
              <br />
              <span>Previously known as the Facebook Ad Library</span>
            </a>
            <a href="#" class="btn btn-lg btn-primary btn-press" @click.prevent="onClose('https://tiktok.com')">
              Tiktok Creative Center
            </a>
            <a href="#" class="btn btn-lg btn-primary btn-press" @click.prevent="onGotoMulti">
              Swipekit multiplayer<br />
              <span class>Our own Ad Library</span>
            </a>
            <a href="#" class="btn btn-lg btn-press" @click.prevent="onClose()"> Close this window </a>
          </div>
        </article>
      </div>

      <!-- 5 -->
      <div v-if="stage === 5" key="s5" class="tab tab-5">
        <article class="article-text">
          <p>Now drag your mouse around. Click on Save afterwards.</p>
        </article>
        <span @click="stage = 6"> next </span>
      </div>

      <!-- 6 -->
      <div v-if="stage === 6" key="s6" class="tab tab-6">
        <div class="center-align-content">
          <h1>That's all to it</h1>
        </div>
        <p class="article-text">
          Remember, the more ads you save, more ads to reference from when creating your own ads.
        </p>

        <p class="article-text">Now go forth and make your swipe file!</p>

        <div class="center-align-content">
          <button class="btn btn-lg btn-pill btn-primary" @click="onClose()">Done</button>
        </div>
      </div>
    </transition>
    <div class="c-onboarding__breadcrumbs">
      <span
        v-for="stageee in stages"
        :key="stageee"
        :class="[{ active: stageee === stage }, { activated: stageee <= stage }]"
      ></span>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import FormUser from "@swipekit/components/form/form-user.vue";
import FormBoardCreateUser from "@swipekit/components/form/form-board-create-user";
import FormWorkspaceCreate from "@swipekit/components/form/form-workspace-create";

export default {
  components: {
    FormUser,
    FormBoardCreateUser,
    FormWorkspaceCreate,
  },

  data: function () {
    return {
      stages: [0, 1, 2, 3, 4],
      stage: 0,
      lock: false,
      touched: false,
    };
  },

  watch: {
    blocks: function () {
      this.touched = true;
    },
    initialStage: {
      handler: function () {
        this.stage = this.initialStage;
      },
      immediate: true,
    },
    stage: function () {
      if (this.stage === 4) {
        // silently update user's onboarded flag
        let form = {
          onboarded: true,
        };
        let response = null;
        response = this.$store.dispatch("users/update", form).catch((err) => {
          console.log(err);
          // also update user config
          this.$store.dispatch("users/updateMarketing");
        });
      }
    },
  },

  props: {
    initialStage: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    workspace: function () {
      return this.$store.getters["workspaces/all"];
    },
    blocks: function () {
      return this.$store.getters["blocks/all"];
    },
    boards: function () {
      return this.$store.getters["boards/filtered"];
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    addId: function () {
      return false;
    },
    extensionState: function () {
      return this.$store.getters["extensionState"];
    },
  },

  methods: {
    onGotoMulti: function () {
      this.$store.dispatch("app/toggleOnboarding", false);

      this.$router.push("/multiplayer");
    },
    onSetupWorkspace() {
      if (this.extensionState) {
        // basically, if the extension is installed, skip the install browser extension phase
        this.stage = 4;
      } else {
        this.stage = 3;
      }
    },
    async onSubmitUser() {
      this.stage = 3;
    },
    async onSubmitBoard() {
      let message = {
        message: "You just created your first board!",
        status: "success",
      };

      this.$store.dispatch("message/sendMessage", message);

      this.stage = 3;
    },
    onAfterLeave: function () {
      let tabId = this.stage;
      this.onShow(tabId);
    },
    onShow: function (tabId) {
      this.$nextTick(() => {
        let tl = anime.timeline({
          easing: "easeOutExpo",
          duration: 750,
        });

        let selector = `.tab-${tabId} .fade-up`;

        tl.add({
          targets: selector,
          translateY: 0,
          opacity: 1,
          delay: anime.stagger(150, { start: 500 }),
        });
      });
    },
    onClose: function (url) {
      if (url) {
        window.open(url, "_blank");
      }
      this.$store.dispatch("app/toggleOnboarding", false);
    },
    onStageOne: function () {
      let user = this.user;

      if (user && user.marketing && Object.keys(user.marketing).length > 3) {
        this.stage = 3;
      } else {
        this.stage = 1;
      }
    },
  },

  mounted: function () {
    let query = this.$route.query;
    let user = this.user;
    if (query.onboard && user && user.onboarded) {
      let onboard = 0;
      if (query.onboard == "2") {
        onboard = 3;
      }
      this.stage = onboard;
    }
  },
};
</script>

<style lang="scss">
.c-onboarding {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-enter-active {
    transition: all 250ms ease 250ms;
  }
  .slide-leave-active {
    transition: all 250ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-enter, .slide-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: scale(0.5);
    opacity: 0;
  }

  .fade-enter-active {
    transition: all 250ms ease 250ms;
  }
  .fade-leave-active {
    transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .btn-press {
    display: inline-block;
    > span {
      display: block;
      font-size: var(--font-size-sm);
      opacity: 0.85;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .btn {
      margin-right: 0;
      margin-bottom: var(--margin-lg);
    }
  }

  h1 {
    font-family: var(--font-family-fancy);
    font-size: var(--font-size-xxl);
  }

  .tab {
    position: absolute;

    &.tab-1 {
      padding: var(--spacer) 0;
    }
  }

  .c-form {
    .form-group > label {
      margin-top: var(--margin-lg);
      font-size: 20px;
    }

    label svg,
    label .dot {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }

  .article-text {
    margin: 0;
    padding: 0;
    list-style: none;
    margin: var(--spacer) 0;

    li {
      margin: 0;
      padding: 0;
      display: flex;
      margin-bottom: var(--margin);

      svg {
        width: var(--spacer);
        min-width: var(--spacer);
        height: var(--spacer);
        margin-right: var(--margin-lg);
        color: var(--color-button-primary);
      }
    }
  }

  .tab-0 {
    max-width: 540px;
    margin: 0 auto;
    text-align: center;

    .article-text {
      text-align: left;
    }
  }

  .tab-3 {
    text-align: center;

    h2 {
      margin-top: var(--spacer-sm);
    }
    .text-muted {
      margin-top: var(--spacer-sm);
      opacity: 0.75;
    }
  }

  .tab-4 {
    text-align: center;

    code {
      display: inline-block;
      padding: var(--margin) var(--spacer-sm);
      font-weight: 500;
      font-size: var(--font-size-lg);
      background-color: rgba(150, 150, 150, 0.15);
    }

    .article-text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .tab-5 {
    text-align: center;
  }

  .center-align-content {
    margin: var(--spacer-sm) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .widget-frame {
    display: flex;
    justify-content: center;
    align-items: center;

    .c-widget {
      padding-bottom: 0;
      width: 360px;
      height: 240px;
    }
  }

  &__breadcrumbs {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    span {
      margin: var(--spacer-sm);
      background-color: currentColor;
      opacity: 0.5;
      width: var(--spacer);
      height: var(--margin);
      border-radius: var(--spacer);

      &.activated {
        opacity: 1;
      }

      &.active {
        background-color: var(--color-primary);
      }
    }
  }
}
</style>
