<template>
  <div class="c-bulk-edit__container">
    <div :class="['c-bulk-edit', { active: selection.length > 0 }]">
      <svg @click="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967Z"
          fill="currentColor"
        />
      </svg>
      <p>Bulk editing {{ countString }}</p>
      <div class="c-bulk-edit__grid">
        <a class="btn btn-primary" @click="onEditBoards"> Edit boards </a>
        <a class="btn btn-primary" @click="onEditTags"> Edit tags </a>
        <!-- <a class="btn btn-primary btn-icon" @click.prevent="onGenerateCsv">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
          Download CSV
        </a> -->
        <a class="btn btn-primary" v-if="board" @click="onRemoveAdBoard"> Remove Ads from current board </a>
        <InputButtonChoice buttonClass="btn-danger" label="Delete Ads" @input="onDeleteAds"></InputButtonChoice>
      </div>
    </div>
    <ModalEditBoards @onSubmit="closeEditBoardsModal" ref="editBoards" :selection="selection"></ModalEditBoards>
    <ModalEditTags @onSubmit="closeEditTagsModal" ref="editTags" :selection="selection"></ModalEditTags>
  </div>
</template>

<script>
import FormBulkEdit from "@swipekit/components/form/form-bulk-edit";
import InputButtonChoice from "@swipekit/components/form/input-button-choice";
import ModalEditBoards from "@swipekit/components/modal/modal-edit-boards";
import ModalEditTags from "@swipekit/components/modal/modal-edit-tags";

import generateCsv from "@swipekit/lib/generate-csv";

export default {
  components: {
    FormBulkEdit,
    InputButtonChoice,
    ModalEditBoards,
    ModalEditTags,
  },

  watch: {
    $route: function () {
      this.close();
    },
  },

  computed: {
    selection: function () {
      return this.$store.getters["ads/selection"];
    },
    countString: function () {
      let selection = this.selection;

      let str = `${selection.length} ads`;

      if (selection.length === 1) {
        str = `${selection.length} ad`;
      }

      return str;
    },
    slug: function () {
      return this.$route.params.slug;
    },
    board: function () {
      if (!this.slug) {
        return;
      }
      return this.$store.getters["boards/boardBySlug"](this.slug);
    },
  },

  methods: {
    onEditBoards: function () {
      let editBoards = this.$refs.editBoards;
      editBoards.$data.options.active = true;
    },
    onEditTags: function () {
      let editTags = this.$refs.editTags;
      editTags.$data.options.active = true;
    },
    onGenerateCsv: function () {
      let ads = [];

      let selection = this.selection;

      for (let i = 0; i < selection.length; i++) {
        let ad = this.$store.getters["ads/one"](selection[i]);
        ads.push(ad);
      }

      let csv = generateCsv(ads);

      var encodedUri = encodeURI(csv);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "swipekit.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    async onRemoveAdBoard() {
      let adText = "Ads";

      if (this.selection.length === 1) {
        adText = "Ad";
      }

      let message = {
        message: `Removing ${this.selection.length} ${adText} from current board`,
        timer: 1750,
      };
      await this.$store.dispatch("message/sendMessage", message);

      let selection = JSON.parse(JSON.stringify(this.selection));

      this.$store.dispatch("ads/clearSelection");

      let board = this.board;

      let payload = {
        selection: selection,
        boardId: board.id,
      };

      // actually remove ads from board
      await this.$store.dispatch("ads/bulkRemoveAdsFromBoard", payload).catch((err) => {
        console.log(err);
      });

      message = {
        message: `${adText} removed from current board`,
        status: "success",
      };

      this.$store.dispatch("boards/refreshAll");

      await this.$store.dispatch("message/sendMessage", message);
    },
    async onDeleteAds() {
      let adText = "Ads";

      if (this.selection.length === 1) {
        adText = "Ad";
      }

      let message = {
        message: `Deleting ${this.selection.length} ${adText}`,
        timer: 1750,
      };
      await this.$store.dispatch("message/sendMessage", message);

      let selection = JSON.parse(JSON.stringify(this.selection));

      // trigger removeSelection, this will remove all selected ads from currently viewed ads
      this.$store.dispatch("ads/removeFromSelection");

      this.$nextTick(async () => {
        this.$store.dispatch("ads/clearSelection");

        // actually delete ads
        await this.$store.dispatch("ads/bulkDeleteAds", selection).catch((err) => {
          console.log(err);
        });

        message = {
          message: `${adText} deleted`,
          status: "success",
        };

        // then refresh boards
        this.$store.dispatch("boards/refreshAll");

        await this.$store.dispatch("message/sendMessage", message);
      });
    },
    closeEditTagsModal: function () {
      editTags.$data.options.active = false;
      consoel.log("plz work");
      this.$store.dispatch("app/refreshFilters");
      this.$store.dispatch("ads/clearSelection");
    },
    closeEditBoardsModal: function () {
      editBoards.$data.options.active = false;
      consoel.log("plz work");
      this.$store.dispatch("app/refreshFilters");
      this.$store.dispatch("ads/clearSelection");
    },
    close: function () {
      this.$store.dispatch("ads/clearSelection");
    },
  },
};
</script>

<style lang="scss">
.c-bulk-edit {
  --bulk-edit-gap: 32px;
  position: fixed;
  z-index: 1;
  bottom: var(--bulk-edit-gap);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;
  height: 128px;

  border-radius: var(--border-radius-lg);
  background-color: rgba(255, 255, 255, 0.75);
  border-top: var(--color-bg-4) solid 1px;
  backdrop-filter: blur(10px);
  padding: var(--spacer-sm);
  box-shadow: 0px 2.2px 5.3px rgba(0, 0, 0, 0.065), 0px 7.4px 17.9px rgba(0, 0, 0, 0.095),
    0px 33px 80px rgba(0, 0, 0, 0.16);

  transition: opacity var(--transition-time) ease-out;

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  > svg {
    position: absolute;
    top: var(--margin-lg);
    right: var(--margin-lg);
    cursor: pointer;
  }

  &__grid {
    width: 100%;
    max-width: 732px;
    display: flex;

    .btn {
      margin-right: var(--spacer-sm);

      &.btn-icon {
        padding-left: 8px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    height: 140px;
    &__grid {
      flex-wrap: wrap;

      .btn {
        margin-bottom: var(--margin);
      }
    }
  }
}
</style>
