<template>
  <form class="c-form-block-create" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Add Ad"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  url: {
    component: "InputText",
    label: "Ad link",
    placeholder: "https://www.facebook.com/ads/library/?id=421247629844588",
    properties: {
      columnSize: 6,
    },
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      schema: schema,
      form: {
        url: "",
      },
      status: null,
    };
  },

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      let obj = {
        id: 1,
        action: "fb_ad_library",
        form: form,
      };

      let res = await this.$store.dispatch("ads/doAction", obj);
      this.$emit("onSubmit");

      this.processing = false;
    },
  },
};
</script>

<style></style>
