<template>
  <div class="c-item-header">
    <Avatar :block="block" size="small">
      <ActiveStatus v-if="block.type === 'facebook'" :block="block"></ActiveStatus>
    </Avatar>

    <article>
      <p :title="title">{{ computedTitle }}</p>

      <span v-if="createdAgo">Saved {{ createdAgo }}</span>

      <!-- <span v-if="computedTags"
        ><i v-for="tag in computedTags" :key="tag.text">{{ tag.text }}</i></span
      > -->
    </article>

    <div :class="['c-item-header__buttons', { shared: shared === true }]">
      <a class="open-share-ad" href="#" v-if="!multiplayer">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.7557 11.5739L18.1421 11.1876C19.6358 9.69387 19.6358 7.27214 18.1421 5.77846C16.6484 4.28478 14.2267 4.28478 12.733 5.77846L9.8316 8.67987C8.66003 9.85144 8.66003 11.7509 9.8316 12.9225L10.8012 13.8921M6.16483 12.3467L5.77846 12.7331C4.28478 14.2267 4.28478 16.6485 5.77846 18.1421C7.27214 19.6358 9.69387 19.6358 11.1876 18.1421L14.089 15.2407C15.2605 14.0692 15.2605 12.1697 14.089 10.9981L13.1194 10.0285"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1.5px"
          />
        </svg>
      </a>

      <a :class="['open-dropdown', uniqueClass]" href="#" v-if="!shared && !multiplayer">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20Z"
            fill="currentColor"
          />
        </svg>
      </a>

      <span class="c-grid-item__label" v-if="labels"> 2 </span>

      <!-- <div
        v-if="user && user.type === 'ADMIN'"
        :class="['c-item-header__rating', `rating-${rating}`]"
        v-tooltip="tooltip(ratingTooltip)"
      >
        <span></span>
        <span></span>
        <span></span>
      </div> -->
    </div>
  </div>
</template>

<script>
import Avatar from "@swipekit/components/utils/avatar";
import ActiveStatus from "@swipekit/components/utils/active-status";
import { customAlphabet } from "nanoid";
import { parseISO, formatDistanceToNow } from "date-fns";

const nanoid = customAlphabet("1234567890abcdef", 10);

export default {
  components: {
    Avatar,
    ActiveStatus,
  },

  props: {
    labels: {
      type: Boolean,
      default: false,
    },
    block: {},
    ad: {},
    shared: {
      type: Boolean,
      default: false,
    },
    multiplayer: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    user: function () {
      return this.$store.getters["users/user"];
    },
    createdAgo: function () {
      if (!this.ad) {
        return;
      }
      let createdAt = this.ad.createdAt;
      if (!createdAt) {
        return;
      }
      const savedDate = parseISO(createdAt);

      // Humanize the date
      const humanizedDate = formatDistanceToNow(savedDate, { addSuffix: true });

      return humanizedDate;
    },
    rating: function () {
      let rating = this.block.rating;
      if (!rating || rating === 0) {
        return "na";
      }

      if (rating === 1) {
        return 1;
      }

      if (rating >= 2 && rating <= 6) {
        return 2;
      }

      if (rating >= 7) {
        return 3;
      }

      return "na";
    },
    ratingTooltip: function () {
      let rating = this.rating;

      if (rating === "na") {
        return `We don't have enough data about this Ad to give it a rating`;
      }
      if (rating === 1) {
        return `This Ad might not be performant`;
      }
      if (rating === 2) {
        return `This Ad might be performant`;
      }
      if (rating === 3) {
        return `This Ad might be exceptionally performant`;
      }
    },
    uniqueClass: function () {
      return `dropdown-${nanoid()}`;
    },
    title: function () {
      let block = this.block;

      if (block.title) {
        return block.title;
      }

      if (block.brand) {
        return block.brand.name;
      }

      if (block.meta && block.meta.advertiser) {
        return block.meta.advertiser.name;
      }

      if (block.type === "webpageSelection") {
        return "Webpage clip";
      }

      return block.type;
    },
    computedTitle: function () {
      let max = 15;
      let title = this.title;

      if (!title) {
        return;
      }

      if (title.length > max) {
        return title.slice(0, max) + "..";
      } else {
        return title;
      }
    },
    computedTags: function () {
      let tags = this.block.tags || this.block.categories || [];

      let newTags = [];

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].text) {
          newTags.push({
            text: tags[i].text,
          });
        } else {
          newTags.push({
            text: tags[i],
          });
        }
      }

      return newTags;
    },
    text: function () {
      let type = this.block.type;

      if (type === "email") {
        return "Email";
      }
      if (type === "webpage") {
        return "Webpage";
      }
      if (type === "webpageSelection") {
        return "Webpage section";
      }
      if (type === "facebook") {
        return "Facebook Ad library";
      }

      return "";
    },
  },

  methods: {
    tooltip: function (content) {
      return { content: content, offset: 10, delay: 200 };
    },
    onBoardClick: function (e) {
      this.$emit("boardClick", e.target);
    },
  },
};
</script>

<style lang="scss">
.c-item-header {
  position: relative;
  z-index: var(--z-2);
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  max-height: 52px;
  padding: var(--margin-lg);
  padding-right: 64px;

  display: flex;
  align-items: center;

  user-select: none;

  //background-color: rgba(255, 255, 255, 0.75);
  //backdrop-filter: blur(4px);

  > article {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    margin-left: var(--margin-lg);
    margin-right: var(--margin);

    p {
      margin: 0;
      margin-bottom: var(--margin-sm);
      font-size: var(--font-size);
      opacity: 0.8;
      color: var(--color-font);
      line-height: 1;
    }

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      opacity: 0.7;
      font-size: var(--font-size-xs);

      i {
        display: inline-block;
        margin-right: var(--margin-sm);
        line-height: 1;
        font-style: normal;
        opacity: 0.8;
        color: var(--color-font);
        font-size: var(--font-size-xs);

        &:not(:last-child) {
          &:after {
            content: ",";
          }
        }
      }
    }
  }

  &__rating {
    width: 8px;
    height: 32px;
    margin-left: var(--spacer-sm);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 0 2px var(--color-bg-4);

    > span {
      position: relative;
      display: block;
      width: 100%;
      height: 10.5px;
      background-color: var(--color-bg-4);
    }

    &.rating-na {
      > span {
        background-color: var(--color-bg-5);
      }
    }

    &.rating-1 {
      > span {
        &:nth-child(3) {
          background-color: var(--color-danger);
        }
      }
    }
    &.rating-2 {
      > span {
        &:nth-child(2) {
          background-color: var(--color-warning);
        }
        &:nth-child(3) {
          background-color: var(--color-warning);
        }
      }
    }
    &.rating-3 {
      > span {
        &:nth-child(1) {
          background-color: var(--color-success);
        }
        &:nth-child(2) {
          background-color: var(--color-success);
        }
        &:nth-child(3) {
          background-color: var(--color-success);
        }
      }
    }
  }

  &__buttons {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: var(--margin-lg);

    a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--border-radius-full);
      color: var(--color-font);

      > svg {
        display: inline-block;
      }

      &:hover,
      &:active {
        color: var(--color-primary);
      }

      &:focus {
        outline: none;
      }

      &:last-child {
        margin-left: var(--margin);
      }
    }

    &.shared {
      display: none;
    }
  }

  .c-grid-item__label {
    top: 15px;
    left: initial;
    right: 55px;
  }

  @media screen and (min-width: 1800px) {
    max-height: 64px;

    .c-item-avatar {
      &.small {
        width: 43px;
        height: 43px;
        min-width: 43px;
      }
    }

    .c-active-status {
      width: 18px;
      height: 18px;
      border-width: 2px;
    }

    > article {
      p {
        font-size: var(--font-size);
      }
      span i {
        font-size: var(--font-size-sm);
      }
    }
  }

  @media screen and (max-width: 1480px) {
    & > article {
      p {
        font-size: var(--font-size-sm);
      }
    }
  }
}
</style>
