<template>
  <div class="c-utils-stats" v-if="showStats">
    <span>
      <p>
        {{ likes }}
      </p>
      <p>
        {{ comments }}
      </p>
      <p>
        {{ shares }}
      </p>
    </span>
    <span>
      <strong> Likes </strong>
      <strong> Comments </strong>
      <strong> Shares </strong>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
    link: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showStats: function () {
      if (this.block.type === "tiktok" || this.block.type === "tiktok-top-ads") {
        return true;
      }
      return false;
    },
    views: function () {
      return this.block.views || 0;
    },
    likes: function () {
      return this.block.likes || 0;
    },
    comments: function () {
      return this.block.comments || 0;
    },
    shares: function () {
      return this.block.shares || 0;
    },
  },
};
</script>

<style lang="scss">
.c-utils-stats {
  display: grid;
  grid-template-columns: min-content 1fr;
  justify-content: space-between;
  font-size: var(--font-size-xs);
  margin-right: var(--margin-lg);
  padding-top: var(--margin);
  color: var(--color-font);
  border-top: var(--color-bg-3) solid 1px;

  span > strong {
    display: block;
    font-weight: 400;
    opacity: 0.85;
    line-height: 1.5;
    margin-left: var(--margin);
  }

  span > p {
    font-size: var(--font-size-xs);
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
}
</style>
