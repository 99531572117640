<template>
  <div class="c-page-header">
    <div class="c-page-header__bg">
      <svg width="100%" height="100%">
        <pattern
          id="pattern-circles"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
          patternContentUnits="userSpaceOnUse"
        >
          <circle id="pattern-circle" cx="10" cy="10" r="1.6257413380501518" fill="currentColor"></circle>
        </pattern>

        <rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(#pattern-circles)"></rect>
      </svg>
    </div>
    <div class="c-page-header__inner">
      <div class="c-page-header__menu" v-if="showMenu">
        <slot name="menu"> </slot>
      </div>

      <span class="c-byline">
        {{ byline }}
        <!-- <a @click.prevent="toggleModal('multiplayer')" href="#" v-if="byline === 'Multiplayer'">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 11H12V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 7.25C11.5858 7.25 11.25 7.58579 11.25 8C11.25 8.41421 11.5858 8.75 12 8.75C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25Z"
              fill="black"
              stroke="black"
              stroke-width="0.5"
            />
          </svg>
        </a> -->
      </span>

      <h1><slot name="title"></slot></h1>

      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },

  watch: {},

  props: {
    byline: {
      type: String,
      default: "Swipekit",
    },
    title: {
      type: String,
      default: "",
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    name: function () {
      let route = this.$route;

      return this.title || route.name || "?";
    },
  },

  methods: {
    toggleModal: function (type) {
      this.$store.dispatch("app/toggleModal", type);
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.c-page-header {
  width: 100%;
  padding-top: calc(var(--spacer) * 2.5);
  text-align: center;

  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    color: var(--color-bg-5);

    > svg {
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 250px;
      background: linear-gradient(0deg, var(--color-bg-2) 50%, hsla(var(--p), 10%, 98.5%, 0) 100%);
    }
  }

  .c-byline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--margin-lg);
    text-transform: uppercase;
    font-family: var(--font-family-heading);
    font-weight: 550;
    background: linear-gradient(180deg, hsl(var(--p), 10%, 15%) 20%, hsl(var(--p), 20%, 30%));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    > a {
      display: inline-block;
      margin-left: var(--margin);
      max-height: 34px;
      padding: var(--margin);
      border-radius: var(--border-radius);
      transition: background-color 120ms linear;

      &:hover,
      &:active {
        background-color: var(--color-bg-4);
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 1;
    height: 100%;

    margin: 0 auto;
    padding: var(--spacer-sm);

    h1 {
      margin-bottom: 0;
      font-weight: 550;
      font-size: 42px;
      font-family: var(--font-family-body);
      line-height: 1.1;
    }

    .article-text {
      margin-top: var(--spacer-sm);
      margin-bottom: 0;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: var(--border-radius-full);
    }
  }

  &__user-blurb {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacer-sm);

    span {
      margin-left: var(--margin-lg);
    }
  }

  &__menu {
    display: inline-flex;
    margin: 0 auto;
    margin-bottom: var(--spacer);
    padding: var(--margin);
    border-radius: 99px;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 0px 0px 0.4px,
      rgba(0, 0, 0, 0.08) 0px 2px 4px -1px, rgba(0, 0, 0, 0.1) 0px 5px 38px -2px;

    > a {
      min-width: 163px;
      display: inline-block;
      //flex: 1 1 0px;
      padding: var(--margin-lg);
      color: var(--color-font) !important;
      border-radius: 12px;
      text-decoration: none !important;
      user-select: none;

      &:first-child {
        border-top-left-radius: 99px;
        border-bottom-left-radius: 99px;
      }
      &:last-child {
        border-top-right-radius: 99px;
        border-bottom-right-radius: 99px;
      }

      p {
        margin: 0;
        opacity: 0.75;
        font-size: var(--font-size-sm);
      }

      h6 {
        margin-bottom: var(--margin-sm);
        white-space: nowrap;
        line-height: 1;
      }

      &:hover,
      &:active {
        background-color: var(--color-bg-4);
      }

      &.active {
        background-color: hsl(var(--p), 70%, 63%);
        color: #fff !important;
      }

      &:not(:last-child) {
        margin-right: var(--margin);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: var(--spacer-lg);

    &__inner {
      padding-top: var(--spacer);
      h1 {
        font-size: 34px;
      }
    }
  }
}
</style>
