<template>
  <div v-if="isAllowed" :class="['c-item-preview', type]">
    <article>
      <strong v-if="titleText">{{ titleText }}</strong>
      <span v-if="copyText">{{ copyText }}</span>
    </article>
    <a v-if="ctaText && landingPage" :href="landingPage" class="click-prevent" target="_blank">
      <span>{{ ctaText }} </span>
    </a>
    <a v-if="ctaText && !landingPage" target="_blank">
      <span>{{ ctaText }} </span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    block: {},
  },

  computed: {
    landingPage: function () {
      let block = this.block;
      let ctaLink = block.ctaLink;
      if (ctaLink) {
        return ctaLink;
      }

      if (block.meta && block.meta.ctaLink) {
        return block.meta.ctaLink;
      }

      return false;
    },
    isAllowed: function () {
      if (this.block.type === "tiktok-top-ads") {
        return false;
      }
      if (this.block.type === "tiktok-library") {
        return false;
      }
      if (this.block.type === "tiktok") {
        return false;
      }

      return true;
    },
    type: function () {
      return this.block.type;
    },
    ctaText: function () {
      if (this.type === "facebook") {
        let meta = this.block.meta;
        let cta = meta.cta || "";

        if (cta === "MESSAGE_PAGE") {
          return "Send message";
        }

        let convertedStr = cta.split("_").join(" ");
        // Capitalize the first letter and make the rest lowercase
        convertedStr = convertedStr.charAt(0).toUpperCase() + convertedStr.slice(1).toLowerCase();
        return convertedStr;
      }
      if (this.type === "linkedin") {
        return "Learn more";
      }
    },
    titleText: function () {
      let title = this.block.title || "";
      if (this.type === "facebook") {
        return title;
      }

      return null;
    },
    copyText: function () {
      if (this.type === "facebook") {
        let block = this.block;
        let copy = "";
        if (block.categories && block.categories.length) {
          copy = block.categories.join(",");
        }
        return copy;
      }
      if (this.type === "linkedin") {
        let max = 52;
        let copy = this.block.adCopy;

        if (copy.length > max) {
          copy = copy.slice(0, max - 2) + "..";
        }

        return copy;
      }
    },
  },
};
</script>

<style lang="scss">
.c-item-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  padding-top: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif;
  padding: var(--margin);

  --fs-sm: 11px;
  --fs: 12px;
  --fs-lg: 14px;

  // --fs-sm: 9px;
  // --fs: 10px;
  // --fs-lg: 14px;

  &.facebook {
    > article {
      margin-right: 4px;

      > strong {
        position: relative;
        display: block;
        font-size: var(--fs);
        overflow: hidden;
        text-overflow: ellipsis;
        // &:after {
        //   content: "";
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 2px;
        //   background-color: hsla(40, 90%, 70%, 1);
        //   opacity: 0;
        // }

        // &:hover,
        // &:active {
        //   background-color: hsla(40, 90%, 90%, 1);
        //   color: #000;

        //   &:after {
        //     opacity: 1;
        //   }
        // }
      }

      > span {
        position: relative;
        display: block;
        font-size: var(--fs-sm);
        overflow: hidden;
        text-overflow: ellipsis;
        color: #606770;

        // &:after {
        //   content: "";
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 2px;
        //   background-color: hsla(40, 90%, 70%, 1);
        //   opacity: 0;
        // }

        // &:hover,
        // &:active {
        //   background-color: hsla(40, 90%, 90%, 1);
        //   color: #000;

        //   &:after {
        //     opacity: 1;
        //   }
        // }
      }
    }

    > a {
      background-color: rgba(0, 0, 0, 0.1);
      font-size: var(--fs-lg);
      font-weight: 400;
      width: 120px;
      max-width: 120px;
      height: 36px;
      display: flex;
      flex-basis: auto;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: center;
      color: rgba(0, 0, 0, 0.85);
      border-radius: 6px;
      border-style: none;
      border-color: rgba(0, 0, 0, 0.4);
      padding: 8px 16px;
      position: relative;
      text-align: center;
      text-decoration-color: rgba(0, 0, 0, 0.45);
      text-decoration-line: none;
      text-decoration-style: solid;
      text-decoration-thickness: auto;
      touch-action: manipulation;
      unicode-bidi: isolate;
      user-select: none;
      vertical-align: middle;
      z-index: 0;
      -webkit-font-smoothing: antialiased;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      > span {
        line-height: 20px;
        text-overflow: ellipsis;
        overflow-wrap: normal;
        overflow: hidden;
        white-space: pre;
        white-space-collapse: collapse;
      }
    }
  }

  &.linkedin {
    padding: 10px;
    background-color: #edf3f8;
    > article {
      margin-right: var(--margin-sm);
      > span {
        display: block;
        font-size: var(--fs-sm);
        font-weight: 600;
        line-height: 1.25;
      }
    }

    > a {
      white-space: nowrap;
      height: min-content;
      min-height: 32px;
      border-radius: 24px;
      padding: 7px 16px;
      text-align: center;
      line-height: 1.25;
      display: inline-block;
      font-weight: 600;
      font-size: var(--fs-lg);
      overflow-wrap: break-word;
      align-self: center;
      max-width: 150px;
      box-shadow: 0 0 0 1px #0a66c2;
      background-color: rgba(0, 0, 0, 0);
      color: #0a66c2;
    }
  }
}
</style>
