<template>
  <div :class="['c-popup-brands', { processing: processing === true }]">
    <Popup :active="active" :target="target" @onClose="onClose">
      <div class="c-popup-brands__header">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5 15.5L20.5 20.5M17.5 10.5C17.5 14.366 14.366 17.5 10.5 17.5C6.63401 17.5 3.5 14.366 3.5 10.5C3.5 6.63401 6.63401 3.5 10.5 3.5C14.366 3.5 17.5 6.63401 17.5 10.5Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <InputText v-model="search" placeholder="Search by brands"></InputText>
        <!-- <article v-if="selectedBrands.length > 0">
          <span>{{ selectedBrands.length }} {{ brandText }} selected. </span
          ><span @click="onResetBrands">Clear filter</span>
        </article> -->
        <span v-if="processing" class="c-spinner"></span>
        <!-- <span class="c-popup-brands__reset" v-if="selectedBrands.length > 0" @click="onResetTags">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 5L12 12M19 19L12 12M12 12L19 5L5 19"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span> -->
      </div>
      <div class="c-popup-brands__body">
        <InputCheckboxBrand v-model="selectedBrands" :options="computedBrands"></InputCheckboxBrand>
        <article v-if="computedBrands.length === 0 && !initialLoad">
          <p>No Brands found.</p>
        </article>
      </div>
    </Popup>
  </div>
</template>

<script>
import Popup from "./index";
import InputCheckboxBrand from "@swipekit/components/form/input-checkbox-brand";
import InputText from "@swipekit/components/form/input-text";

import filter from "@swipekit/lib/filter";

export default {
  components: {
    Popup,
    InputCheckboxBrand,
    InputText,
  },

  data: function () {
    return {
      selectedBrands: [],
      search: "",
      debounceTimer: null,
      debounceTimeout: 250,
      debouncedSearch: "",
      brands: [],
      params: {
        skip: 0,
        take: 40,
        query: "",
      },
      processing: false,
      initialLoad: true,
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    target: {},
    multiplayer: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    search: function (newSearchValue) {
      clearTimeout(this.debounceTimer); // Clear previous timeout
      this.debounceTimer = setTimeout(() => {
        this.updateDebouncedSearch(newSearchValue);
      }, this.debounceTimeout);
    },
    debouncedSearch: function () {
      this.params.query = this.debouncedSearch || "";
      this.filter();
    },
    selectedBrands: function () {
      let ids = this.selectedBrands.map((b) => {
        return b.id;
      });
      this.$emit("input", ids);
    },
  },

  computed: {
    brandText: function () {
      if (this.selectedBrands.length === 1) {
        return "Brand";
      }

      return "Brands";
    },
    computedBrands: function () {
      let brands = this.brands;

      brands = JSON.parse(JSON.stringify(brands));

      brands = brands.map((b) => {
        return {
          name: b.name,
          id: b.id,
          logo: b.logo,
        };
      });

      if (this.params.query) {
        brands.sort((a, b) => {
          // Use localeCompare for string comparison
          return a.name.localeCompare(b.name);
        });
      }

      return brands;
    },
  },

  methods: {
    updateDebouncedSearch(searchValue) {
      this.debouncedSearch = searchValue;
    },

    onClose: function () {
      this.$emit("onClose");
    },
    onResetBrands: function () {
      this.selectedBrands = [];
      this.$emit("resetTags");
    },
    async filter() {
      this.processing = true;
      let path = "filter-brand-library";
      if (this.multiplayer) {
        path = "filter-brand";
      }
      const brands = await filter(this.params, path);
      // const temp = JSON.parse(JSON.stringify(brands));
      // console.log(temp);
      this.brands = brands || [];
      this.processing = false;
    },
  },

  mounted: function () {
    this.filter().then(() => {
      this.initialLoad = false;
    });
  },
};
</script>

<style lang="scss">
.c-popup-brands {
  .c-popup {
    width: 320px;
    max-height: 410px;
    overflow: hidden;
  }
  .c-input-checkbox {
    padding-bottom: 0;
    .c-input-checkbox__item {
      margin-bottom: 0;

      &:not(:last-child) {
        padding-bottom: var(--margin-sm);
      }
    }
  }
  &__header {
    position: relative;
    height: 50px;
    padding: var(--margin-lg);
    padding-bottom: 0;
    .form-group {
      padding-bottom: 0;
    }
    .form-control {
      padding-right: 32px;
    }

    .c-spinner {
      position: absolute;
      top: 18px;
      right: 18px;
      border-color: var(--color-primary);
      border-bottom-color: transparent;
    }

    > svg {
      position: absolute;
      top: 14px;
      left: 14px;
      opacity: 0.75;
      pointer-events: none;
    }

    .form-control {
      padding-left: var(--spacer);
      background-color: var(--color-bg-1);
    }

    article {
      display: flex;
      align-items: center;
      padding: var(--margin-sm) 0;
      font-size: var(--font-size-xs);

      span:last-child {
        color: var(--color-danger);
        cursor: pointer;
      }
    }
  }

  &__body {
    height: 360px;
    overflow-y: scroll;

    > article {
      text-align: center;
    }
  }
  &__close {
    position: absolute;
    top: 12px;
    right: var(--margin-lg);
    opacity: 0.75;
    cursor: pointer;

    &:hover,
    &:active {
      opacity: 1;
    }
  }

  &__reset {
    position: absolute;
    top: 14px;
    right: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    cursor: pointer;
    user-select: none;

    svg {
      color: var(--color-danger);
      margin-right: var(--margin-sm);
    }
  }

  &.processing {
    .c-popup-brands__body {
      pointer-events: none;
      opacity: 0.75;
    }
  }
}
</style>
