import api from "@swipekit/lib/api.js";

import CrudStore from "@swipekit/lib/crud-store";

let crud = new CrudStore({
  name: "comments",
  singular: "comment",
});

const state = {
  ...crud.state,
};
const getters = {
  ...crud.getters,

  byAd: function (state) {
    let comments = state.resources;
    return function (adId) {
      let adComments = [];

      for (let i = 0; i < comments.length; i++) {
        if (comments[i].adId === adId) {
          adComments.push(comments[i]);
        }
      }

      return adComments;
    };
  },
};
const actions = {
  ...crud.actions,

  async getLocal(store) {
    let resources = [];
    try {
      resources = await crud.api[crud.resourceName].get("local");
    } catch (err) {
      throw err;
    }

    store.commit("SET", resources);
    return resources;
  },

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.api.doAction(id, action);

    return response;
  },

  async fetchAndStore(store) {
    let resources = (await crud.api[crud.resourceName].fetchAndStore()) || [];

    store.commit(resources);
  },

  async getAdComment(store, adId) {
    let comments = (await crud.api[crud.resourceName].getAdComments(adId)) || [];

    store.commit("SET", comments);
  },

  async afterLogin(store, pie) {
    let mode = store.rootState.config.mode;

    if (store.rootGetters["users/isAuth"]) {
      if (mode === "EXTENSION") {
        await store.dispatch("getLocal");
      } else {
        if (!pie) {
          await store.dispatch("get");
        } else {
          await store.dispatch("consumePie", pie);
        }
      }
    }
  },

  consumePie: async (store, pie) => {
    store.commit("SET", pie.workspace.comments || []);
  },
};
const mutations = {
  ...crud.mutations,
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default store;
