<template>
  <form class="c-form-comment-create" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Add comment"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";

const schema = {
  text: {
    component: "InputEditor",
    label: "",
    placeholder: "Write a comment",
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      schema: schema,
      status: null,
    };
  },

  props: {
    form: {
      type: Object,
      default: function () {
        return {
          text: "",
          userId: null,
          adId: null,
        };
      },
    },
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      let res = await this.$store.dispatch("comments/add", form);

      this.reset();

      this.$emit("onSubmit");

      this.processing = false;
    },
  },
};
</script>

<style></style>
