<template>
  <div class="c-page-check">
    <Page :i="0">
      <div v-if="processingActivation">
        <h1>Hang on, activating your account.</h1>
        <div class="c-spinner"></div>
      </div>
      <div v-else>
        <h1>Please check your email inbox</h1>
        <p>An email has been sent at {{ user.email }}.</p>
        <p>Please click the link inside that email to verify your Swipekit account.</p>

        <h5>Good to know</h5>
        <p>Emails can be slow. Please allow for 5-10 minutes to receive the email.</p>
        <p>If you can't find that email, check your spam folder.</p>
        <p>If you still cannot find that email, add shash@swipekit.app to your contacts list.</p>

        <!-- <button class="btn btn-primary" @click="onLogout">Logout</button> -->
      </div>
    </Page>
  </div>
</template>

<script>
import Page from "./page";
import ButtonCta from "./button-cta.vue";
export default {
  components: {
    Page,
    ButtonCta,
  },

  props: {
    processingActivation: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    user: function () {
      return this.$store.getters["users/user"];
    },
  },

  methods: {
    onClick: function () {
      this.$emit("onNext");
    },
    onLogout: function () {
      this.$store.dispatch("users/logout");
    },
  },
};
</script>

<style lang="scss">
.c-page-check {
  .c-spinner {
    display: block;
    margin: 0 auto;
    width: var(--spacer);
    height: var(--spacer);
    border-bottom-color: #000;
    border-width: 4px;
  }
}
</style>
